import React from 'react';

import IconButton from '@mui/material/IconButton';
import { PersonAdd } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import ModeIcon from '../../../../ModeIcon';

const RightIcon = ({
  selectArtisan, classes, message, canSelect
}) => <Box display='flex' alignItems='center' justifyContent='center'>
  {canSelect && (
    <div className={classes.addButtonDiv}>
      <Tooltip disableInteractive
        title={'Selectionner pour l\'intervention'}>
        <IconButton
          onClick={() => selectArtisan(message.toJS())}
          children={<PersonAdd />}
          size="large" />
      </Tooltip>
    </div>
  )}
  <ModeIcon elemMode={message.get('mode')} />
</Box>;

export default RightIcon;
