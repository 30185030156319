import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Map, List } from 'immutable';
import SuppliesRows from './SuppliesRows';
import { TVA_A } from '@lba-dev/package.local-globals/tvaStatus';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { downloadAndSignBonSAV } from '../../actions/savIntervention';
import { connect } from 'react-redux';
import { openCommAchat } from '../../actions/achat';

import { moment } from '@lba-dev/package.local-globals/moment';
import Grid from '@mui/material/Grid';
import CustomLoadingButton from '../Utils/CustomLoadingButton';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonMargin: {
    margin: '1%'
  },
  saveButton: {
    marginRight: 8
  }
});

const path = ['supplies'];

class BodySupplies extends PureComponent {
  add = (data = {}, cb = f => f) => {
    const {
      price = 0,
      suppliesName = `Fourniture${price ? ' auto' : ''}`,
      pro = 'EDISON',
      attachment = '',
      attachmentArtisan
    } = data;
    const { setData, user, value } = this.props;
    const time = moment().format('LT').replace(':', 'h');
    const day = moment().format('L');
    const login = `${user.login} ${time} ${day}`;
    let supplie = new Map({
      suppliesDate: login,
      userId: user._id,
      date: new Date(),
      bl: 0,
      suppliesName: suppliesName,
      qt: 1,
      ut: price || 0,
      montant: price,
      pro: pro,
      validate: false,
      attachment,
      attachmentArtisan
    });
    const newProductState = value.push(supplie);
    setData(path, newProductState, cb);
  }

  popUpBon = () => {
    const { getData, save, downloadAndSignBonSAV } = this.props;
    const inter = {
      _id: getData(['_id']),
      id: getData(['id']),
      login: (getData(['login']) || new Map()).toJS(),
      createdFrom: (getData(['createdFrom']) || new Map()).toJS(),
      dropbox: (getData(['dropbox']) || new List()).toJS(),
    };
    const artisan = (getData(['currentArtisan']) || new Map()).toJS();
    const price = '0';
    downloadAndSignBonSAV({
      price,
      inter,
      artisan,
      add: this.add,
      save,
    });
  };

  setData = (pos, k, v, cb = f => f) => {
    const hasTobeNumber = ['qt', 'ut'];
    const valueToSet = hasTobeNumber.includes(k[0]) ? Number(v) : v;
    const { value, setData } = this.props;
    const res = value.setIn([pos, ...k], valueToSet);
    setData(path, res, cb);
  }

  remove = (id) => {
    const { value, setData } = this.props;
    setData(path, value.delete(id));
  }

  swap = (id) => {
    const { value, setData } = this.props;
    setData(
      path,
      value.withMutations((e) => {
        const tmp = e.get(id);
        return e.set(id, e.get(id + 1)).set(id + 1, tmp);
      })
    );
  }

  openDialogCommentaire = () =>
    openCommAchat(this.props)

  render() {
    const {
      classes, value, user, tva, getData,
      showSaveButton,
      save, merge,
      loadingSaveButton
    } = this.props;

    return (
      <div>
        <form className={classes.container} noValidate>
          <Table className={classes.table}>
            <colgroup key="tableStyle">
              {[2, 15, 10, 38, 5, 10, 10, 10, 10, 2].map((e, i) => (
                <col key={i} style={{ width: `${e}%` }} />
              ))}
            </colgroup>
            <TableHead key="tableHeaderLine">
              <TableRow>
                {[
                  '',
                  'Le',
                  'BL',
                  'Désignation',
                  'Quant',
                  'PrixUnit',
                  `Montant ${tva === TVA_A ? 'HT' : 'TTC'}`,
                  'Fournisseur',
                  'fichiers',
                  'Envoyer au sst',
                  'Modif',
                ].map((e, i) => (
                  <TableCell key={i}>{e}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key="tableBodyLine">
              <SuppliesRows
                interId={getData && getData(['id'])}
                data={value}
                artisan={getData && (
                  getData(['currentArtisan']) || new Map()
                ).toJS()}
                setData={this.setData}
                remove={this.remove}
                swap={this.swap}
              />
            </TableBody>
          </Table>
        </form>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={10}>
            <Button
              variant="contained"
              size="small"
              className={classes.buttonMargin}
              onClick={this.add}
            >
              Ajout FOURNITURE
            </Button>
            {permit(user, { key: 'bonsDeCommande' }) && getData && (
              <Button
                variant="contained"
                size="small"
                className={classes.buttonMargin}
                onClick={this.popUpBon}
              >
                Télécharger et signer
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              disabled={isNaN(merge.get('id'))}
              className={classes.buttonMargin}
              onClick={this.openDialogCommentaire}
            >
              Commentaire Achat
            </Button>
          </Grid>
          {showSaveButton && (
            <Grid item xs={12} sm={2} align="right">
              <CustomLoadingButton
                variant="contained"
                className={classes.saveButton}
                size="small"
                color="secondary"
                children={'Enregistrer'}
                onClick={() => save()}
                loading={loadingSaveButton}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default connect(null, { downloadAndSignBonSAV })(
  withStyles(BodySupplies, styles)
);
