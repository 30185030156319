/* eslint-disable max-lines */
import Immutable from 'immutable';
import {
  D_NOT,
  D_PRE,
  D_MAR,
} from '@lba-dev/package.local-globals/deStatus';

import {
  Assignment,
  Call,
  MailOutline,
  Comment,
  Schedule,
  Person,
  Close,
  Check,
  CheckCircle,
  AttachMoney,
  Payment,
  ReportProblem,
  Email,
  Grade,
  Send,
  NotificationImportant,
  Delete,
  DeleteForever,
  AddCircle,
  CancelPresentation,
  Sms,
  Edit,
  Alarm,
  TouchApp,
  AssignmentInd,
  Search,
  RingVolume,
  AccountBalanceWallet,
  CreditCard,
  BusinessCenter,
  HowToReg,
  Brightness1,
  GroupAdd,
  Assessment,
  AccessTime
} from '@mui/icons-material';

import {
  S_PART,
  S_SATCL,
  S_COMPTA,
  S_DEVIS
} from '@lba-dev/package.local-globals/services';
import {
  I_APR,
  I_ENC,
  I_AVR,
  I_ANN,
  I_REF,
  I_DVG,
} from '@lba-dev/package.local-globals/iStatus';
import {
  SAV_DSAV,
  SAV_APP,
  SAV_DAE,
  SAV_DAREF,
  SAV_DF,
  SAV_NPC,
  SAV_AJOUT,
  SAV_NPC_MAN,
  SAV_DISAV,
  SAV_DFC
} from '@lba-dev/package.local-globals/ListSAVInters';
import {
  HEADCHIEF,
  ADMIN,
} from '@lba-dev/package.local-globals/permissions';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import {
  M_REG,
  M_PAY,
  M_PAYAN,
} from '@lba-dev/package.local-globals/comptaCommentes';
import { VT } from '@lba-dev/package.local-globals/categories';
import {
  checkSupportAjout
} from '@lba-dev/package.local-globals/verifications';
import {
  cancel,
  openVerifDialog,
  send,
  sendOrderCedeo,
  comment,
  appel,
  schedule,
  reactivated,
  signalement,
  openAwaitingBillDialog,
  reglement,
  payment,
  favoris,
  priorityFollow,
  refuser,
  showSMS,
  comptaComments,
  middlewareDialogCompta,
  callPriseDeCoteDialog,
  callReminderDialog,
  callReminderHistoryDialog,
  addToSupportActionList,
  sendUploadSms,
  sendToMarket,
  abPlusCb,
  paiementAnnexes,
  openAttachmentsDialog,
  modifyTransactions,
  addInterToMajSupport,
  changeSupplieProgress,
  priseEnChargeSupport
} from '../../../actions/inter';
import {
  checkAwaitingBillRequirements,
  checkConditionsCFI,
  openDialogCFI,
  displayVerifButtonOnMAJ
} from '../../../actions/verifications';
import { artisanNumbers } from '../Actions';
import {
  DeleteSAV,
  updateStatusSAV,
  priseEnChargeSAV,
  assignNotif,
} from '../../../actions/savIntervention';
import { openPropositionDialog } from '../../../actions/proposition';
import recouvrementActions from '../Recouvrement/recouvrementActions';
import creditAideActions from '../Recouvrement/creditAideActions';
import { openAssignDialog } from '../../../actions/general';
import {
  P_NONE,
} from '@lba-dev/package.local-globals/isValidStatus';
import { sliceNumber } from '../../Calls/FomatText';

export default [
  {
    name: 'Modifier',
    icon: Assignment,
    link: (data) => {
      window.open(`/intervention/${data.id}`);
    },
    action: (props, elem) => {
      props.navigate(`/intervention/${elem.id}`);
    },
  },
  {
    name: 'Suivi prioritaire',
    icon: NotificationImportant,
    action: priorityFollow,
    customVisible: (elem, props) =>
      props.selected &&
      (!props.selected.priorityFollow ||
      !props.selected.priorityFollow.includes(props.userId)) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displayInterActions' }
      ),
  },
  {
    name: 'Supprimer le Suivi prioritaire',
    icon: NotificationImportant,
    action: priorityFollow,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.priorityFollow &&
      props.selected.priorityFollow.includes(props.userId) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displayInterActions' }
      ),
  },
  {
    name: 'SMS',
    icon: Sms,
    sub: [
      {
        name: 'Conversation Client ',
        value: (props, elem) => sliceNumber(elem?.client?.tel1 ),
        action: (props, elem) => {
          showSMS(props, elem._id, 'intervention');
        },
      },
      {
        name: 'Conversation Artisan ',
        customVisible: (props, elem) => elem.selected && elem.selected.artisan,
        value: (props, elem) => sliceNumber(elem?.currentArtisan?.tel1),
        action: (props, elem) => {
          showSMS(props, elem.artisan, 'artisan');
        },
      },
    ]
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [
      ...[1, 2, 3].map((e) => ({
        name: `Téléphone Client ${e}`,
        value: (props, elem) => sliceNumber(elem?.client[`tel${e}`]),
        action: (props, elem) => {
          appel(props, elem.client[`tel${e}`], { client: elem.client });
        }
      })),
      ...[1, 2, 3].map((e) => (
        {
          name: `Payeur tel ${e}`,
          value: (props, elem) => sliceNumber(elem?.billing[`tel${e}`]),
          action: (props, elem) => {
            appel(props, elem.billing[`tel${e}`], {
              client: elem.client,
              billing: elem.billing,
            });
          },
        }
      )),
      ...artisanNumbers,
    ],
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Person,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      props.selected.proposition &&
      props.selected.proposition.isValid === P_NONE,
    action: (props, inter) => openPropositionDialog(
      props.idMenu === 'addInter' ? inter.id : inter),
  },
  {
    name: 'Commenter',
    icon: Comment,
    action: comment,
    customVisible: (elem, props) =>
      props.selected &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
  },
  {
    name: 'Support',
    icon: TouchApp,
    customVisible: (e, p) => checkSupportAjout(p),
    action: (props, cb) => addToSupportActionList(props, cb),
  },
  {
    name: 'Prendre en Charge',
    icon: AccessTime,
    customVisible: (e, p) =>
      p.users &&
      p.users.find((e) => e._id === p.userId).service === S_DEVIS &&
      p.selected &&
      [I_APR, I_ENC, I_AVR].includes(p.selected.status) &&
      !p.selected.login.priseEnChargeSupport,
    action: (props) => priseEnChargeSupport(props),
  },
  {
    name: 'Demande de mise à jour (support)',
    icon: BusinessCenter,
    customVisible: (elem, props) => false && props.selected &&
      [I_ENC, I_AVR].includes(props.selected.status) &&
      !props.selected.login.majSupport,
    action: (props) => addInterToMajSupport(props),
  },
  {
    name: 'Prise de côte',
    icon: Edit,
    action: callPriseDeCoteDialog,
    customVisible: (elem, { users, selected, userId }) =>
      permit(
        users.find((u) => u._id === userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      selected &&
      selected.categorie === VT &&
      !selected.measurePerformedPayment,
  },
  {
    name: 'Favoris',
    icon: Grade,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        props.selected &&
        props.selected.aDemarcher > D_NOT &&
        user &&
        permit(user, { auth: HEADCHIEF })
      );
    },
    action: favoris,
  },
  {
    name: 'Prospection',
    icon: Search,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return props.selected && props.selected.aDemarcher > D_NOT && user;
    },
    link: (elem) => {
      window.open(`/prosp/list?interSearch=${elem.id}`);
    },
    action: (props, elem) => {
      props.navigate(`/prosp/list?interSearch=${elem.id}`);
    },
  },
  {
    name: 'Changer l\'avancement',
    icon: Brightness1,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'achatComments' }) &&
       props.selected &&
        props.selected.supplieProgress
      );
    },
    action: (props, elem) => changeSupplieProgress(elem)
  },
  ...recouvrementActions,
  ...creditAideActions,
  {
    name: 'Réglement Client',
    icon: AttachMoney,
    customVisible: (elem, props) =>
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayCommentaire',
        }
      ),
    sub: [
      {
        name: (props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return permit(user, { key: 'menuReglementClient' })
            ? 'Ajouter/Modifier'
            : 'Voir réglement';
        },
        action: (...arg) => middlewareDialogCompta(...arg, M_REG, reglement),
        customVisible: (elem, props) =>
          props.selected &&
          (props.selected.status !== I_APR || props.selected.artisan),
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_REG),
      },
    ],
  },
  {
    name: 'Prélèvements automatiques',
    icon: CreditCard,
    customVisible: (elem, props) =>
      props?.selected?.transaction?.transactionExists &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'modifyTransactions',
        }
      ),
    action: (...arg) => modifyTransactions(...arg),
  },
  {
    name: 'Paiement SST',
    icon: Payment,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.status !== I_APR &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    sub: [
      {
        name: 'Ajouter/Modifier',
        action: (...arg) => middlewareDialogCompta(...arg, M_PAY, payment),
        customVisible: (elem, props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return (
            Immutable.List.isList(props.users) &&
            permit(user, { key: 'menuReglementClient' }) &&
            props.selected &&
            props.selected.status !== I_APR
          );
        },
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_PAY),
        customVisible: (elem, props) =>
          props.selected && props.selected.status !== I_APR,
      },
    ],
  },
  {
    name: 'Paiements annexes',
    icon: AccountBalanceWallet,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'menuReglementClient' }) &&
        props.selected &&
        props.selected.status !== I_APR
      );
    },
    sub: [
      {
        name: 'Ajouter/Modifier',
        action: (...arg) =>
          middlewareDialogCompta(...arg, M_PAYAN, paiementAnnexes),
        customVisible: (elem, props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return (
            Immutable.List.isList(props.users) &&
            permit(user, { key: 'menuReglementClient' }) &&
            props.selected &&
            props.selected.status !== I_APR
          );
        },
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_PAYAN),
        customVisible: (elem, props) =>
          props.selected && props.selected.status !== I_APR,
      },
    ],
  },
  {
    name: 'Récap. Artisan',
    icon: Person,
    visible: 'artisan',
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'externSAV',
        }
      ),
    link: ({ artisan }) => {
      window.open(`/artisan/${artisan}/recap`);
    },
    action: (props, elem) => {
      props.navigate(`/artisan/${elem.artisan}/recap`);
    },
  },
  {
    name: 'Demande de mise à jour (artisan)',
    icon: AssignmentInd,
    customVisible: (elem, props) =>
      props.selected &&
      [I_AVR, I_ENC].includes(props.selected.status) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    action: sendUploadSms,
  },
  {
    name: 'Je prends!',
    icon: Schedule,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_MAR,
    action: (props, elem) => schedule(elem),
  },
  {
    name: 'Envoyer dans market',
    icon: Send,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_PRE,
    action: sendToMarket,
  },
  {
    name: 'Envoyer',
    icon: MailOutline,
    customVisible: (elem, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return props.selected && props.selected.artisan &&
        (
          user.service !== S_DEVIS ||
          props.selected.login.priseEnChargeSupport
        ) &&
        [I_APR, I_ENC, I_AVR, I_DVG].includes(props.selected.status) &&
        permit(user, { key: 'displayInterActions' })
    },
    action: (props, inter) =>
      openAttachmentsDialog(props.setConnectedDialog).then((attachments) =>
        sendOrderCedeo(props, inter, (newInter) =>
          send({ ...props, attachments }, newInter || inter)
        )
      ),
  },
  {
    name: 'Mettre en relation',
    icon: RingVolume,
    visible: 'artisan',
    customVisible: (elem, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return (
        props.selected &&
        props.selected.artisan &&
        [I_APR, I_ENC, I_AVR, I_DVG].includes(props.selected.status) &&
        permit(user, {
          key: 'displayInterActions',
        }) &&
        permit(user, { key: 'abplusCall' })
      );
    },
    action: (props) => abPlusCb(props, props.selected, 0),
  },
  {
    name: 'Signalement',
    icon: ReportProblem,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) && props.selected,
    action: signalement,
  },
  {
    name: (props) =>
      props.selected &&
        props.selected.statusSAV &&
        props.selected.statusSAV.length &&
        props.selected.statusSAV.includes(SAV_AJOUT)
        ? 'Nouvelle demande SAV / Réclamation'
        : 'Ajouter un SAV / Réclamation',
    icon: AddCircle,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      permit(
        props.users.find((e) => e._id === props.userId),
        { key: 'savPrisEnCharge' }
      ) &&
      props.selected &&
      (!props.selected.statusSAV ||
      !props.selected.statusSAV.length ||
      !props.selected.statusSAV.some(e => SAV_NPC.includes(e))),
    action: (...arg) => updateStatusSAV(...arg, SAV_NPC_MAN),
  },
  {
    name: 'Attribuer le dossier (SAV)',
    icon: GroupAdd,
    customVisible: (inter, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        props.users.find((e) => e._id === props.userId).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.some(e => SAV_NPC.includes(e)) &&
      permit(user, { key: 'savAssign' });
    },
    action: (props, inter) => openAssignDialog({
      data: inter,
      collection: 'interventions',
      field: 'userSav',
      filterUsers: [S_SATCL],
      cb: assignNotif
    })
  },
  {
    name: 'Prise en charge (SAV)',
    icon: Assessment,
    customVisible: (inter, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'savPrisEnCharge' }) &&
        props.selected &&
        props.selected.statusSAV &&
        props.selected.statusSAV.some((e) => SAV_NPC.includes(e))
      );
    },
    action: (props, inter) => priseEnChargeSAV(inter)
  },
  {
    name: 'Supprimer le dossier',
    icon: Delete,
    customVisible: (inter, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'savPrisEnCharge' }) &&
        props.selected &&
        props.selected.statusSAV &&
        props.selected.statusSAV.some((e) =>
          [SAV_APP, SAV_DF, ...SAV_NPC, SAV_DISAV, SAV_DFC].includes(e)
        )
      );
    },
    action: DeleteSAV,
  },
  {
    name: 'Demande de SAV à refuser',
    icon: DeleteForever,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'savPrisEnCharge' }
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DSAV),
    action: (...arg) => updateStatusSAV(...arg, SAV_DAREF, SAV_DSAV),
  },
  {
    name: 'Attestation envoyée',
    icon: Send,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'savPrisEnCharge' }
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_APP),
    action: (...arg) => updateStatusSAV(...arg, SAV_DAE, SAV_APP),
  },
  {
    name: 'Refuser',
    icon: CancelPresentation,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        props.selected.status === I_APR &&
        props.selected.status !== I_REF &&
        user.service === S_PART &&
        props.selected.aDemarcher > D_NOT
      );
    },
    action: refuser,
  },
  {
    name: 'Réactiver',
    icon: CheckCircle,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        ((props.selected.status === I_REF && user.service === S_PART) ||
          (props.selected.status === I_ANN && permit(user, { auth: ADMIN })))
      );
    },
    action: reactivated,
  },
  {
    name: 'Vérifier',
    icon: Check,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      (props.selected.onSitePayment || props.selected.date.envoiFacture) &&
      [I_AVR, I_ENC].includes(props.selected.status) &&
      displayVerifButtonOnMAJ(props) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition ||
        props.selected.proposition.isValid !== P_NONE),
    action: (...args) => openVerifDialog(...args),
  },
  {
    name: 'Vérif + envoi facture en attente',
    icon: Email,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      !props.selected.onSitePayment &&
      (
        [I_AVR, I_ENC].includes(props.selected.status) ||
        props.selected.date.envoiFacture
      ) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition ||
        (!!(props.selected.proposition || {}).isValid &&
          props.selected.proposition.isValid !== P_NONE)),
    action: (props, inter) => {
      const cb = () =>
        openVerifDialog(
          props,
          inter,
          (elem, verifFunc) =>
            checkAwaitingBillRequirements(inter, elem.finalPrice) &&
            openAwaitingBillDialog(inter, verifFunc)
        );
      if (checkConditionsCFI(inter)) {
        return openDialogCFI(() => cb());
      }
      return cb();
    },
  },
  {
    name: 'Relance',
    icon: Alarm,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    sub: [
      {
        name: 'Ajouter/Programmer',
        action: (...arg) => callReminderDialog(...arg),
      },
      {
        name: 'Historique',
        action: (...arg) => callReminderHistoryDialog(...arg),
      },
    ],
  },
  {
    name: 'Annuler',
    customVisible: (elem, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return props.idMenu !== 'addInter' &&
        props.selected &&
        ((!props.selected.demande ||
          props.selected.demande.source !== 'BIEN ETRE ASSISTANCE') ||
          props.selected.sendAcceptationEDI) &&
        props.selected._id &&
        props.selected.status !== I_ANN &&
        (
          user.service !== S_DEVIS ||
          props.selected.login.priseEnChargeSupport
        ) &&
        (!props.selected.proposition ||
          props.selected.proposition.isValid !== P_NONE) &&
        (permit(user) ||
          !props.selected.noPaymentReceived)
    },
    icon: Close,
    action: cancel,
  },
  {
    name: 'Attribuer l\'intervention',
    customVisible: (elem, props) =>
      permit(props.users.find((u) => u._id === props.userId)),
    icon: HowToReg,
    action: (props, inter) => openAssignDialog({
      data: inter,
      collection: 'interventions'
    })
  }
];
