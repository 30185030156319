import { COUNT_FETCH } from '../constants/ActionTypes';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case COUNT_FETCH: {
      return action.payload;
    }
    default:
      return state;
  }
};
