import React from 'react';

import { Box, Typography } from '@mui/material';

import { data } from '@lba-dev/package.local-globals/sStatus';

import { genChip } from '../../utils/chips';
import CollapsedCard from '../Utils/CollapsedCard';
import LineFormat from '../ListInfo/LineFormat';
import { Types } from '../ListInfo/ListInfo';


const ReportBatchTab = ({
  name,
  batch,
  status,
  launchRequest,
}) => (
  <CollapsedCard
    title={<Typography
      variant="h6"
      children={`Ensemble de signalements - ${name}`}
    />}
    action={
      <React.Fragment>
        <Box marginRight={3}>
          <Typography
            variant="subtitle1"
            children={`${batch.length} signalements`}
          />
        </Box>
        {genChip(data.find(d => d._id === status), 0, 0, true)}
      </React.Fragment>
    }
  >
    {batch.map((r, i) => (
      <Box key={i} paddingBottom={1} paddingTop={1}>
        <LineFormat
          key={r._id}
          id={i}
          type={Types.Activities}
          info={r}
          launchRequest={launchRequest}
        />
      </Box>
    ))}
  </CollapsedCard>
);

export default ReportBatchTab;
