import React, { PureComponent } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Fab from '@mui/material/Fab';
import { Delete } from '@mui/icons-material';
import DropboxUpload from './DropboxUpload';
import green from '@mui/material/colors/green';
import { DINTER } from '@lba-dev/package.local-globals/docTypes';
import { data } from '@lba-dev/package.local-globals/attachmentsCategorie';

import api from '../api';
import { withDisplaying } from '../hoc';
import { fromJS, List } from 'immutable';
import notifSystem from '../notifSystem';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatDate } from '@lba-dev/package.local-globals/moment';

const mapStateToProps = ({ users }) => ({ users });
const PATH = ['dropbox'];

class PrintFiles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      checked: [],
      dropbox: [],
    };
    this.deleteCheckTab = this.deleteCheckTab.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  updatState = (dropbox = new List()) => {
    const { type, setData } = this.props;
    const files = fromJS(dropbox)
      .filter(e =>
        Array.isArray(type)
          ? type.includes(e.get('type'))
          : e.get('type') === type
      )
      .toJS();
    if (typeof setData === 'function') {
      setData(PATH, dropbox);
    }
    this.setState({
      files: files || [],
      dropbox: dropbox,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.dropbox &&
      prevProps.dropbox !== this.props.dropbox) {
      this.updatState(this.props.dropbox);
    }
  }

  componentDidMount() {
    if (this.props.dropbox) {
      this.updatState(this.props.dropbox);
    } else {
      this.fetchFile();
    }
  }

  fetchFile = () => {
    const { path } = this.props;
    return api[path]
      .get(this.props.id, {
        field: JSON.stringify({ dropbox: 1 }),
      })
      .then(
        res =>
          res.body().data().dropbox &&
          this.updatState(fromJS(res.body().data().dropbox))
      );
  };

  updateFiles = newFiles => {
    const { path, underscoreId } = this.props;
    if (underscoreId) {
      api[path]
        .patch(underscoreId, {
          dropbox: newFiles,
        })
        .then(res => {
          notifSystem.success('Opération réussie',
            'Le fichier a bien été supprimé');
          this.setState({
            checked: []
          });
          return ((res.body() || {}).data() || {}).dropbox &&
            this.updatState(fromJS(res.body().data().dropbox));
        })
        .catch(e => notifSystem.error('Erreur', e.message || e));
    }
  };

  deleteCheckTab() {
    const { checked, dropbox } = this.state;
    const copy = dropbox
      .filter(e => !checked.includes(e.get('fileName')), new List())
      .toJS();
    this.updateFiles(copy);
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  render() {
    const {
      path,
      id,
      underscoreId,
      type,
      restrictions,
      notDelete,
      headline,
      subtitle,
      users
    } = this.props;
    const { files, checked } = this.state;

    return (
      <Card className="cards">
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxHeight: 400,
            overflow: 'auto',
          }}
        >
          <div>
            <Typography
              variant="h5"
              children={headline || 'Pièces jointes supplémentaires'}
            />
            {subtitle ? <Typography variant="caption"
              children={subtitle} /> : null}
          </div>
          <div style={{ display: 'flex' }}>
            {id && underscoreId && <DropboxUpload
              restrictions={restrictions}
              id={id}
              color={files.length && green[500]}
              variant="contained"
              type={type}
              underscoreId={underscoreId}
              path={path}
              buttonName="Ajouter un fichier"
              getFile={this.fetchFile}
            />}
            {!notDelete && checked.length > 0 &&
              <Fab
                onClick={this.deleteCheckTab}
                size="medium"
                style={{ marginLeft: 12 }}
                color="secondary"
                children={<Delete />}
              />}
          </div>
        </CardContent>
        {files.length > 0 && (
          <CardContent style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxHeight: 400,
            overflow: 'auto',
          }}>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {(notDelete ?
                        ['Nom', 'login', 'date'] :
                        ['Nom',
                          'login',
                          'date',
                          ...(type === DINTER ? ['Catégories'] : [] ),
                          'Supprimer']).map(e =>
                        <TableCell key={e} children={e} />
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, id) => {
                      const user = users.find(e => e._id === file.userId);
                      return (
                        <TableRow key={id}>
                          <TableCell>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={file.link}
                            >
                              {`${file.fileName}`}
                            </a>
                          </TableCell>
                          <TableCell>
                            {user ? user.login : 'Automatique'}
                          </TableCell>
                          <TableCell>
                            {formatDate(file.date)}
                          </TableCell>
                          {type === DINTER && (
                            <TableCell>
                              {(file.categorie &&
                              data.filter(e =>
                                file.categorie.includes(e._id))
                                .map(e => e.name).join(' / ')
                              )
                              || 'Aucune'}
                            </TableCell>
                          )}
                          {!notDelete && (
                            <TableCell>
                              <Checkbox
                                onClick={() => {
                                  this.handleToggle(file.fileName);
                                }}
                                checked={checked.indexOf(file.fileName) !== -1}
                                tabIndex={-1}
                                disableRipple
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    );
  }
}

PrintFiles.displayName = 'PrintFiles';

export default compose(connect(mapStateToProps), withDisplaying())(PrintFiles);
