import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { Box } from '@mui/system';
import { CircularProgress, Slide } from '@mui/material';

import { moment } from '@lba-dev/package.local-globals/moment';

import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import {
  addArtisanFromSMS, getInterFromDevis
} from '../../../../actions/inter';
import { compose } from 'redux';
import { withKeepScrollPosition } from '../../../../hoc';
import { handleLink } from '../../constant';
import WhenElementIsVisible from '../../../Utils/WhenElementIsVisible';

const mapStateToProps = ({ users, userId }) =>
  ({ user: users.find(e => e._id === userId), users });

class ChatContent extends PureComponent {
  bottom = null;
  itemRefs = {};
  state = {
    visible: false,
  }

  scrollDown = () => {
    if (this.bottom) {
      this.bottom.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  }

  componentDidMount() {
    if (!this.props.page) {
      this.scrollDown();
    }
  }

  selectArtisan = (sms) => {
    const { user } = this.props;
    if (sms.artisan && sms.intervention) {
      addArtisanFromSMS(
        sms.intervention, sms.artisan, user);
    }
  }

  redirect = async (id, type) => {
    if (type === 'devis') {
      const inter = await getInterFromDevis(id);
      if (inter) {
        type = 'intervention';
        id = inter.id;
      }
    }
    handleLink(`/${type}/${id}${
      type === 'artisan' ? '/recap' : ''
    }`);
  }

  loadMoreMessage = async () => {
    const { getMoreMessage, canGetMore, loading } = this.props;
    if (canGetMore && !loading) {
      await getMoreMessage();
    }
  };

  render() {
    const {
      data, id, classes, users, loading, canGetMore,
      handleExpand, user, dataObject, containerRef
    } = this.props;
    return (
      <div className={classes.chatDiv}>
        <div className={classes.chatContent}>
          <Slide in={loading}>
            <Box className={classes.loadingBox}>
              <CircularProgress
                size={20}
                disableShrink
              />
            </Box>
          </Slide>
          <WhenElementIsVisible
            threshold={0.5}
            withLoader={false}
            hasMore={canGetMore}
            onLoadMore={this.loadMoreMessage}
            onTop={true}
            classes={classes}
            divProps={{ ref: containerRef }}
          >
            <div className={classes.chatElement}>
              {!data.size ? (
                <div className={classes.centeredDiv}>
                  <span className={classes.span}>Aucun message disponible</span>
                </div>
              ) : <>
                {data.map((e, i) => {
                  const mUser = e.get('sender') && users
                    .find(u => u.login === e.get('transmitter'));
                  const previousDate = (data.get(i - 1) || new Map())
                    .get('date');
                  const sameDay = moment(previousDate)
                    .isSame(e.get('date', new Date()), 'day');
                  return <>
                    <div
                      ref={el => (this.itemRefs[e.get('_id')] = el)}
                      key={e.get('_id')}
                    >
                      <ChatMessage
                        id={id}
                        message={e}
                        type={name}
                        selectArtisan={this.selectArtisan}
                        mUser={mUser}
                        redirect={this.redirect}
                        isSameDay={sameDay}
                        handleExpand={handleExpand}
                        actualUser={user}
                        dataObject={dataObject}
                      />
                    </div>
                  </>;
                })}
                <div ref={el => (this.bottom = el)} />
              </>}
            </div>
          </WhenElementIsVisible>
        </div>
        <div className={classes.chatInputDiv}
          children={<ChatInput {...this.props} scroll={this.scrollDown} />} />
      </div>
    );
  }
}

ChatContent.defaultProps = {
  data: new Map(),
};

export default compose(
  withKeepScrollPosition,
  connect(mapStateToProps),
)(ChatContent);
