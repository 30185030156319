import React from 'react';
import Grid from '@mui/material/Grid';
import notifSystem from '../../notifSystem';
import { Header, Inputs, InputDisable } from '../BankInfo';
import { isValidIBAN, isValidBIC } from 'ibantools';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { DINTERRIB } from '@lba-dev/package.local-globals/docTypes';
import PrintFiles from '../PrintFiles';
import { withDisplaying } from '../../hoc';
import paths from '../InfoIntervention/paths';
import { Map, List } from 'immutable';

class RibClient extends React.PureComponent {
  state = { checked: [] }

  valideRib = () => {
    const {
      validateRib,
      merge,
    } = this.props;
    const newBic = merge.get('newBic', '');
    const newIban = merge.get('newIban', '');

    if (!isValidBIC(newBic.toUpperCase())) {
      return notifSystem.error('Erreur', 'BIC non valide');
    }
    if (!isValidIBAN(newIban.toUpperCase())) {
      return notifSystem.error('Erreur', 'IBAN non valide');
    }
    validateRib();
  };

  checkSetData = (path, value, cb) => {
    const { setData } = this.props;
    setData(path, value, () => setData('verifRIB', false, cb));
  };
  deleteCheckTab = () => {
    const { merge, updateData } = this.props;
    const { checked } = this.state;

    updateData(
      paths.dropbox,
      merge
        .getIn(paths.dropbox)
        .filter((e) => !checked.includes(e.get('fileName')))
    )
      .then(() => this.promisifiedSetState({ checked: [] }))
      .then(this.saveFile)
      .then(() =>
        notifSystem.success('Message', 'Les documents ont bien été supprimés')
      );
  };
  render() {
    const {
      user,
      restrictions,
      merge = new Map(),
      iban = merge.get('iban', ''),
      bic = merge.get('bic', ''),
      newIban = merge.get('newIban', ''),
      newBic = merge.get('newBic', ''),
      verifRib = merge.get('verifRIB', false),
      setData,
    } = this.props;
    const { checked } = this.state;
    return (
      <Grid item xs={12} sm={12}>
        <Header
          isTable
          data={merge}
          user={user}
          permissions={user && permit(user, { key: 'accessRibClient' })}
          checked={checked}
          deleteCheckTab={this.deleteCheckTab}
          verifRIB={verifRib}
          title="RIB Client"
        />
        <Inputs
          permissions={user && permit(user, { key: 'accessRibClient' })}
          newIban={newIban}
          paths={paths}
          newBic={newBic}
          isValidIBAN={isValidIBAN}
          isValidBIC={isValidBIC}
          setData={this.checkSetData}
          valideRib={this.valideRib}
          user={user}
        />
        {bic && iban && <InputDisable iban={iban} bic={bic} />}
        <PrintFiles
          restrictions={restrictions}
          path={isNaN(merge.get('id'))
            ? 'savInterventions'
            : 'interventions'}
          headline="RIB client"
          type={DINTERRIB}
          dropbox={merge.getIn(paths.dropbox, new List())}
          underscoreId={merge.get('_id')}
          id={merge.get('id')}
          setData={setData}
        />
      </Grid>
    );
  }
}

RibClient.displayName = 'RibClient';


export default withDisplaying()(RibClient);
