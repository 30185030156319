import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../hoc/withUserTab/functions';
import { withDisplaying, withMediaQuery } from '../../../hoc';

import { Divider, Typography, Fade } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { DragHandle } from '@mui/icons-material';
import {
  getInternationalNumber
} from '@lba-dev/package.local-globals/verifyNumber';

import ChatContainer from './ChatContainer';
import ConversationHeader from './ConversationHeader';
import { smsTypes, getContactTels, genSmsName } from '../constant';
import {
  getSelectedData, updateUserChatConfig
} from '../../../actions/conversations';
import { checkPhoneNumber } from '../../../utils/function';
import TabEmail from '../../../views/RecapArtisan/TabEmail';

let isMobile = false;
let widthForMobile = window.innerWidth;

const useStyles = makeStyles()((theme, { containerWidth, isDash }) => ({
  container: {
    width: isDash &&
    `${(containerWidth || (isMobile ? widthForMobile : 750))}px`,
    minWidth: isMobile ? 0 : 540,
    ...(isMobile ? { maxWidth: widthForMobile - 50 } : {}),
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  },
  draggable: {
    display: 'flex',
    cursor: 'col-resize',
    position: 'absolute',
    left: -8,
    bottom: 0,
    top: 0,
    width: 8,
    alignItems: 'center'
  },
  dragIcon: {
    transform: 'rotate(90deg)',
    color: grey['400']
  },
}));

const setConvConfig = (user, update) =>
  updateUserChatConfig(user._id, {
    conversation: update
  });

let containerWidth = isMobile ? widthForMobile : 750;
const ConversationContainer = ({
  selected = {},
  setSelected,
  smsType,
  setLastMessages,
  isDash,
  user,
  isDown
}) => {
  const { classes } = useStyles({ containerWidth, isDash });
  let conversationConfig = user?.chatConfig?.conversation || {};
  const typeData = smsTypes.find(e => e.type === smsType);
  const [selectedData, setSelectedData] = useState(selected.dataObject || {});
  const [initialPos, setInitialPos] = React.useState(null);
  const [initialSize, setInitialSize] = React.useState(null);
  const elemId = smsType === 'ticket' ? selected?.dataObject?.id : selected._id;
  const getData = () => {
    getSelectedData(elemId, selected.type)
      .then((elem = {}) => {
        const data = {
          ...(selected.dataObject || {}),
          ...elem
        };
        data.companyName = selected.companyName ||
          genSmsName(elem, selected.type)
        data.tels = getContactTels(data, selected.type === 'artisan');
        setSelectedData(data);
      });
  };

  useEffect(() => {
    if (isDown.md){
      isMobile = true
    }
    containerWidth =
    conversationConfig?.width
    || (isMobile
      ? widthForMobile
      : 750);
  }, []);

  useEffect(() => {
    if (selected._id !== selectedData._id) {
      getData();
    }
  }, [selected]);

  const resizeDiv = (newWidth) => {
    let resizableElem = document.getElementById('resizConv');
    resizableElem.style.width = `${newWidth}px`;
  };

  const initial = (e) => {
    let resizable = document.getElementById('resizConv');
    setInitialPos(e.clientX);
    setInitialSize(resizable.offsetWidth);
  };

  const resize = (e) => {
    let clientX = -1 * e.clientX;
    let newPos = parseInt(clientX + initialPos, 10);
    const size = parseInt(initialSize, 10);
    const newWidth = `${size + newPos}`;
    if (clientX) {
      containerWidth = newWidth;
      resizeDiv(newWidth);
    }
  };

  const dragFinish = () => {
    let resizableElem = document.getElementById('resizConv');
    const elemWidth = (resizableElem.style.width || '').replace('px', '');
    setConvConfig(user, {
      width: +elemWidth < 540 ? '540' : elemWidth
    });
  };
  return (
    <Box className={classes.container} id='resizConv'>
      {
        selected._id ? <Box height="100%">
          <ConversationHeader
            selected={{
              ...selected,
              dataObject: selectedData
            }}
            setSelected={setSelected}
            typeData={typeData}
            isDash={isDash}
          />
          <Divider />
          {smsType === 'mailReceived' ?
            <TabEmail
              artisanId={selected._id}
            /> :
            <ChatContainer
              smsType={smsType}
              name={typeData && selected.type === typeData.collection ?
                typeData.type : selected.type}
              id={selected._id}
              filters={selected.freshContact
                  && checkPhoneNumber(selected._id) ? {
                  to: {
                    $in: [
                      selected._id, getInternationalNumber(selected._id)
                    ].filter(Boolean)
                  },
                  sender: true,
                  $or: [
                    { artisan: { $exists: true } },
                    { intervention: { $exists: true } },
                    { devis: { $exists: true } }
                  ],
                } : {
                  _id: selected._id,
                }}
              color={selected.color}
              setLastMessages={setLastMessages}
              freshContact={selected.freshContact}
              newContact={selected.newContact}
              hasWhatsapp={selected.hasWhatsapp}
              dataObject={selectedData}
              selectedNumber={selected.selectedNumber}
            />}
        </Box> : (!isDash && <Box
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Typography
            fontWeight="bold"
            variant="subtitle2"
            children="Sélectionnez une conversation"
          />
        </Box>) || ''
      }
      {isDash ? <Fade in={selected._id} timeout={{
        enter: 1000, exit: 200
      }}>
        <Box className={classes.draggable}
          draggable={isDash}
          onDragStart={initial}
          onDragEnd={dragFinish}
          onDrag={resize}
          children={<DragHandle className={classes.dragIcon} />}
        />
      </Fade> : ''}
    </Box>
  );
};

const areEquals = (prevP, nextP) => prevP.selected === nextP.selected;

export default compose(
  connect(mapStateToProps),
  withMediaQuery(),
  withDisplaying()
)(React.memo(ConversationContainer, areEquals));
