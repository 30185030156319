import api from '../api';
import { typeFromCollection } from '../components/Conversations/constant';
import store from '../store';
import { setDialog } from './dialog';
import { searchElastic } from './general';
import {
  DINTER,
  DDEV
} from '@lba-dev/package.local-globals/docTypes';
import {
  getInternationalNumber
} from '@lba-dev/package.local-globals/verifyNumber';

import notifSystem from '../notifSystem';
import { openChatPanel } from './chatPanel';
import collections from '../constants/collections';
import { DOCINT } from '@lba-dev/package.local-globals/docFrom';
import { SMSFILE } from '@lba-dev/package.local-globals/attachmentsCategorie';

export const searchConversations = (input, collection, limit = 10) =>
  searchElastic({ collection, text: input, limit })
    .then(res => res.body().map(e => {
      const data = e.data()._source;
      const type = typeFromCollection[data.type] || data.type;
      let companyName = (
        data.companyName || data.clientSociety || '').toUpperCase();
      companyName += data.name ?
        `${(companyName && ' - ') || ''}${data.name}` : '';
      companyName += data.firstname ? ` ${data.firstname}` : '';
      return ({
        ...data,
        search: true,
        dataObject: {
          tels: [data.tel1, data.tel2, data.tel3].filter(Boolean),
          ...data
        },
        companyName,
        type: type,
        collection: ['devis', 'demandes']
          .includes(collection) ? 'interventions' : collection,
        _id: e.data()._id
      });
    }));

export const loadConversations = (type, pinnedIds, page) =>
{
  if (type === 'mailReceived') {
    return api.mailReceived.get('getMailsByUser', {
      page,
      withOutCache: true
    })
      .then(r => r.body().map(e => e.data()));
  }
  return api[type === 'ticket' ? collections.tickets : collections.sms]
    .get('conversationSMS', {
      type, pinnedIds, page, withOutCache: true
    })
    .then(r => r.body().map(e => e.data()));
}

export const updateUser = (userId, data) =>
  api.users.patch(userId, data).then(r => r.body().data());

export const updateUserChatConfig = (userId, chatConfig) =>
  updateUser(userId, {
    chatConfig
  });

export const checkWP = (number) => api.sms
  .custom('checkIfWhatsappUser')
  .post({ number })
  .then(res => res.body().data())
  .catch(() => false);

export const getOldConv = (number) =>
  api.sms.getAll({
    query: JSON.stringify({
      to: {
        $in: [number, getInternationalNumber(number)].filter(Boolean)
      },
      sender: true,
      $or: [
        { artisan: { $exists: true } },
        { intervention: { $exists: true } },
        { devis: { $exists: true } }
      ],
    }),
    limit: 1,
    sort: { date: -1 },
  })
    .then(e => e.body().map(e => e.data())[0]);

const addFileInDropbox = ({ collection, docId, fileLink, dropbox, user }) => {
  const fileName = [...fileLink.match(/^.*\/([a-z1A-Z0-9-].+)(?=\?)/)][1];
  const type = collection === collections.interventions ? DINTER : DDEV;
  const nDropbox = [
    ...dropbox || [], {
      type,
      userId: user._id,
      date: new Date(),
      link: fileLink,
      path: '',
      fileName: fileName,
      service: user.service,
      addedBy: DOCINT,
      categorie: [SMSFILE]
    }];
  return api[collection].patch(docId, {
    dropbox: nDropbox
  })
};

export const artisanInterListDialog = ({ fileLink, user, artisan }) => {
  store.dispatch(
    setDialog({
      name: 'ListInterArtisanDialog',
      open: true,
      dialogProps: {
        title: 'Ajouter a un os de l\'artisan',
        maxWidth: 'lg'
      },
      contentProps: { artisan, fileLink },
      actions: [{
        children: 'Ajouter',
        disabled: (r) => !r.inter,
        onClick: (r, close) => {
          const inter = r.inter;
          if (inter && inter._id) {
            r.setLoading(true);
            addFileInDropbox({
              collection: 'interventions',
              docId: inter._id,
              fileLink,
              dropbox: inter.dropbox,
              user
            }).then(() => {
              close();
            }).catch(() => {
              notifSystem.error('Erreur',
                'Il y a eu une erreur lors de l\'ajout.');
            }).finally(() => {
              r.setLoading(false);
            });
          }
        }
      }]
    })
  );
};

export const addPhotoToInterOrDevis = ({ dropbox, ...props }) => {
  if (dropbox && dropbox.find(e => e.link === props.fileLink)) {
    return new Promise(res => {
      notifSystem.warning('Attention',
        'Le fichier a déjà été ajouté.');
      res(true)
    });
  }

  return addFileInDropbox(props)
    .then(() => {
      notifSystem.success('Succès',
        'Le fichier a bien été ajouté.')
      return true
    })
    .catch(() => {
      notifSystem.error('Erreur',
        'Il y a eu une erreur lors de l\'ajout.');
      return false
    });

};

export const getSelectedData = (selectedId, type) => {
  let elem = {};
  const collection = Object.keys(typeFromCollection)
    .find(key => typeFromCollection[key] === type ||
      (type === 'ticket' && key === 'interventions'));
  if (!selectedId || !api[collection]) {
    return new Promise(res => res(elem));
  }
  return api[collection].get(selectedId)
    .then(e => e.body().data())
    .catch(() => elem);
};

export const getConversationWithTel = (number) =>
  api.sms.getAll({
    query: JSON.stringify({
      $or: [
        { to: number }, { to: getInternationalNumber(number) },
        { from: number }, { from: getInternationalNumber(number) }
      ]
    }),
    field: JSON.stringify({ intervention: 1, devis: 1 }),
    sort: { '_id': -1 },
    limit: 1,
  })
    .then(e => {
      const sms = (e.body().map(e => e.data()) || [])[0];
      const elem = sms.intervention || sms.devis;
      if (elem) {
        store.dispatch(openChatPanel(elem, 'intervention'));
      }
    });
