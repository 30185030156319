import {
  List,
  Business,
  Assignment,
} from '@mui/icons-material';
import { todayToString } from '../../../utils/date';
import { CHIEF } from '@lba-dev/package.local-globals/permissions';
import { permit } from '@lba-dev/package.local-globals/restrictions';

export default [
  {
    name: 'Liste Interventions',
    icon: List,
    link: '/intervention/list',
  },
  {
    name: 'Liste Devis',
    icon: List,
    link: (user) => permit(user, { key: 'interDevisListAccess' }) ?
      '/devis/list' :
      `/devis/list/userDevis/login-ajout=${user.login}=${user._id}`
  },
  {
    name: 'Market',
    icon: List,
    link: () => '/intervention/support/marketSupport',
  },
  {
    name: 'Interventions',
    icon: Business,
    childs: [
      {
        name: 'Mise à jour du partenaire',
        icon: Assignment,
        childs: [
          {
            name: 'En attente',
            icon: List,
            link: () => '/intervention/support/propositionAvSupp',
          },
        ],
      },
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        link: () => '/intervention/support/interventionsAjdSupp',
      },
      {
        name: 'A programmer',
        icon: List,
        count: true,
        link: () => '/intervention/support/interventionsAProgSupp',
      },
      {
        name: 'En cours',
        icon: List,
        count: true,
        link: () => '/intervention/support/interventionsEnCoursSupp',
      },
      {
        name: 'A vérifier',
        icon: List,
        count: true,
        link: () => '/intervention/support/interventionsAVerfSupp'
      },
      {
        name: 'Annulées',
        icon: List,
        count: true,
        link: () => '/intervention/support/interventionsAnnSupp'
      },
    ]
  },
  {
    name: 'Devis',
    icon: Business,
    childs: [
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        link: () => '/devis/support/devisSupportAjdTransfer',
      },
      {
        name: 'Devis à faire',
        icon: List,
        link: (e) => `/demandes/support/${e.permission <= CHIEF ?
          'devisAFaireChief' : 'devisAFaireUser'}`,
      },
      {
        name: 'A Envoyer',
        icon: List,
        link: () => '/devis/support/devisSupportEnv',
      },
      {
        name: 'En attente',
        icon: List,
        link: () => '/devis/support/devisSupportEAtt',
      },
      {
        name: 'Refusés',
        icon: List,
        link: () => '/devis/support/devisSupportRef',
      },
    ]
  },
  {
    name: 'Demandes',
    icon: Business,
    childs: [
      {
        name: 'Tous les Devis',
        icon: List,
        link: (user) => permit(user, { key: 'interDevisListAccess' }) ?
          '/devis/list' :
          `/devis/list/userDevis/login-ajout=${user.login}=${user._id}`
      },
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        link: () => '/intervention/support/interventionsAjdSuppGC',
      },
      {
        name: 'Non traitées',
        icon: List,
        link: () => '/demandes/support/nonTraite',
      },
      {
        name: 'Traitées',
        icon: List,
        link: () => '/demandes/support/traitees',
      },
    ]
  },
  {
    name: 'Émargements',
    icon: List,
    link: '/config/users/attendences',
  },
];
