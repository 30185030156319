import React from 'react';

import Grid from '@mui/material/Grid';

const withGrid = params => WrappedComponent => ({ gridProps, ...props }) => (
  <Grid item {...params} {...gridProps}>
    <WrappedComponent {...props} />
  </Grid>
);

export default withGrid;
