/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import Money from '../../../utils/Money';
import CommentsToExpandList from '../../Comments/CommentsToExpandList';
import { Chip } from '@mui/material';
import { data as signalStatus } from '@lba-dev/package.local-globals/sStatus';
import { grey } from '@mui/material/colors';
import store from '../../../store';
import { setDialog } from '../../../actions/dialog';
import AudioPlayer from '../../Calls/AudioPlayer';

const styles = {
  line: {
    lineHeight: 'normal',
  },
  a: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '-webkit-link',
  },
  timelineItem: {
    position: 'inherit'
  },
  chip: {
    marginTop: 10,
    marginBottom: 10,
  },
  TimelineOp: {
    maxWidth: '30%'
  }
};

const filters = {
  sStatus: (status) => ({
    ...signalStatus.find(e => e._id === status)
  })
};

const openSIgnalement = (_id, setSignalements) => {
  store.dispatch(
    setDialog({
      name: 'SingleSignalDialog',
      open: true,
      contentProps: {
        signalId: _id,
        setSignalements
      },
      dialogProps: {
        title: 'Signalement',
        middle: true,
        middleAll: true,
        maxWidth: 'lg'
      }
    })
  );
};

const InterMenuInfo = ({
  _id,
  type,
  status,
  filter,
  date,
  user,
  obj,
  message,
  text,
  classes,
  getDevis,
  comments,
  showComments,
  setSignalements
}) => {
  const filteredData = status && filter && filters[filter](status);
  return !!date && <>
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent className={classes.TimelineOp}>
        <Typography color="textSecondary">{date}</Typography>
        <Typography color="textSecondary">{user}</Typography>
        {obj && obj.Icon}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {filteredData && filteredData.shortName && _id ?
          <Chip
            label={filteredData.shortName}
            className={classes.chip}
            onClick={() => openSIgnalement(_id, setSignalements)}
            style={{
              backgroundColor: filteredData.color ?
                filteredData.color[500] : grey[500]
            }}/> :
          <TimelineDot />
        }
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          variant="body1"
          style={obj && obj.style ? obj.style : {}}
          className={classes.lineHeight}
        >
          {(type === 'Intervention' && obj && (
            <React.Fragment>
              {type} par{' '}
              <Link to={`/artisan/${obj.artisanId}/recap`}>
                {obj.artisanName}
              </Link>
            </React.Fragment>
          )) ||
              (type === 'Devis' && obj && (
                <React.Fragment>
                  {type}{' '}
                  <a className={classes.a} onClick={() => getDevis(obj.id)}>
                    {obj.id}
                  </a>
                </React.Fragment>
              )) ||
              (type === 'Devis artisan' && obj && (
                <React.Fragment>
                  Un devis a été créé par l'artisan
                </React.Fragment>
              )) ||
              (type === 'Envoyée' && obj && (
                <React.Fragment>
                  Envoyée&nbsp;
                  <Link to={`/artisan/${obj.artisanId}/recap`}>
                    {obj.artisanName}
                  </Link>
                  {!!obj.price && ` à ${Money.toString(obj.price || 0, true)}`}
                </React.Fragment>
              )) ||
              (type === 'Devis supplémentaire réalisé' && obj && (
                <React.Fragment>
                  Devis supplémentaire réalisé -&nbsp;
                  <Link to={`/devis/${obj.devisId}`}>
                    Devis n°{obj.devisId}
                  </Link>
                </React.Fragment>
              )) ||
            `${type || ''}${message || text ? `: ${message || text}` : ''}`}
          {(type === 'Appel' && obj && (
            <AudioPlayer
              noPadding
              showTranscription={false}
              value={obj.url}
              transcription={obj.transcription}
              processingTextChatgpt={obj.processingTextChatgpt}
              transcriptorEngine={obj.transcriptorEngine}
              processingTextEngine={obj.processingTextEngine}
              processingOperation={obj.processingOperation}
              processingTextChatgptQuality={obj.processingTextChatgptQuality}
              processingTextEngineQuality={obj.processingTextEngineQuality}
              url={obj.url}
              duration={obj.duration}
            />
          ))}
          {comments && comments.length && showComments ?
            <CommentsToExpandList
              comments={comments}
            /> : null}

        </Typography>
      </TimelineContent>
    </TimelineItem>

  </>;
};

export default withStyles(InterMenuInfo, styles);
