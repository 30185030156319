import React from 'react';

import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBack, Close } from '@mui/icons-material';

import { genConversationInfo, handleLink } from '../constant';
import HeaderIcons from './HeaderIcons';
import { grey } from '@mui/material/colors';
import remunerationText from
  '../ContactContainer/ContactList/ContactItem/ItemDetails/RemunerationText';

const ConversationHeader = ({
  selected = {}, setSelected, typeData, isDash
}) => {
  const data = selected?.dataObject;
  const name = data.companyName || '';
  const info = selected.newContact ? {} :
    (data && genConversationInfo(selected, typeData)) || {};
  const isArtisan = selected.type === 'artisan';
  const isTicket = selected.type === 'ticket';
  const remuneration = data.remuneration ? data.remuneration : null
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ padding: 1, backgroundColor: 'common.white' }}
      alignItems="center"
    >
      <Box display="flex" alignItems="center"
        style={{ width: 'calc(100% - 160px)' }}>
        <IconButton onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setSelected({});
        }}>
          {isDash ? <Close /> : <ArrowBack />}
        </IconButton>
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Box display="flex">
            <Typography
              fontWeight="bold"
              fontSize={14}
              children={name.split(' ').map(e => e.capitalize()).join(' ')}
              sx={{ pr: 1, color: grey[800], overflow: 'hidden',
                whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            />
            {info.statusChip || ''}
            {info.subStatusChip || ''}
          </Box>
          {info && (
            <>
              <Typography
                fontWeight="bold"
                fontSize={13}
                sx={{ color: grey[800], overflow: 'hidden',
                  whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                onClick={() =>
                  selected.type !== 'artisan' &&
                    handleLink(isTicket ? `/intervention/${data.id}` :
                      `/${selected.type}/${selected._id}`)
                }
                children={<>{info.text}{info.ticketClient ?
                  <> - {info.ticketClient}</> : ''}</>}
              />
              {info.catButtons || ''}
              {info.finalPrice || ''}
            </>
          )}
          {remuneration && <Typography
            component="span"
            fontWeight="bold"
            fontSize={13}
            children={remunerationText(remuneration)}
          />}
        </Box>
      </Box>
      <HeaderIcons
        selected={selected}
        isArtisan={isArtisan}
        isTicket={isTicket}
      />
    </Box>
  );
};

export default ConversationHeader;
