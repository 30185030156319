import React from 'react';

import {
  ListItemText, Tooltip, Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import grey from '@mui/material/colors/grey';
import { formatTextToHTML } from '../../../../constant';
import DOMPurify from 'dompurify';
import remunerationText from './RemunerationText';

const useStyles = makeStyles()(
  (_theme, { isSearch, notSeenAndNoSender, selected }) => ({
    listItemText: {
      fontSize: 13
    },
    elemName: {
      maxWidth: `${isSearch ? 85 : 75}%`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      color: notSeenAndNoSender ? '#000' : grey['A700']
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 20px)',
      color: ((notSeenAndNoSender) || selected) ? '#000' : grey['A700'],
      fontWeight: notSeenAndNoSender ? '700' : 'normal',
      height: 20
    },
  }));

const ItemText = ({
  elem, selected, isSearch, info
}) => {
  const notSeenAndNoSender = !isSearch && !!elem.unSeenCount;
  const remuneration = elem.dataObject && elem.dataObject.remuneration;
  const { classes } = useStyles({
    isSearch,
    notSeenAndNoSender,
    selected: selected._id === elem._id
  });
  const name = elem.companyName || '';
  return <ListItemText
    className={classes.listItemText}
    primaryTypographyProps={{
      sx: { whiteSpace: 'nowrap', overflow: 'hidden' }
    }}
    primary={<Box display='flex' alignItems="center">
      <Tooltip
        placement='top'
        leaveTouchDelay={500}
        title={name}>
        <Typography
          className={classes.elemName}
          children={name}
        />
      </Tooltip>
    </Box>}
    secondaryTypographyProps={{
      component: Box
    }}
    secondary={<Typography
      fontSize={12}
    >
      {!isSearch ? <Typography
        variant="caption"
        fontSize={12}
        component="p"
        className={classes.text}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            formatTextToHTML(elem.message || '&nbsp;')
          )
        }}
      /> : ''}{' '}
      {info.statusChip || ''}
      {info.subStatusChip || ''}
      {remunerationText(remuneration)}
      {isSearch && info.catButtons ? info.catButtons : ''}
    </Typography>
    }
  />;
};

export default ItemText;
