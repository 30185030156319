import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog, closeDialog } from './dialog';
import { openPDF } from '../utils/function';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import { moment } from '@lba-dev/package.local-globals/moment';
import { openChatPanel } from './chatPanel';
import { data as categories } from '@lba-dev/package.local-globals/categories';
import { checkWP } from './conversations';
import {
  checkIfMobileNumber
} from '@lba-dev/package.local-globals/verifyNumber';

export const count = (query, collection) =>
  api[collection]
    .getAll({
      call: 'countDocuments',
      query: JSON.stringify(query),
    })
    .then((res) => res.body().data())
    .catch(() => {
      notifSystem.error('Erreur', 'Impossible de récupérer le compteur');
      return 0;
    });

export function newVersionDialog() {
  return store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: '⚠️  Information  ⚠️',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: {
        message:
          'Une nouvelle version est disponible\n' +
          'Redémarrage automatique, veuillez patienter',
      },
    })
  );
}

export function informNewVersion() {
  const { auth } = store.getState() || {};
  if (auth && auth.isRemotely) {
    newVersionDialog();
    return setTimeout(() => window.location.reload(true), 2000);
  }
  return setTimeout(() => {
    localStorage.setItem(
      'newVersion',
      JSON.stringify({
        status: true,
        forceUpdate: moment().add(1, 'h').toDate(),
      })
    );
    window.dispatchEvent(new Event('storage'));
  }, 1000 * 5);
}

export function checkMessagesToAnswer({ size }) {
  const state = store.getState();
  if (size > 0 && !state.dialog?.open) {
    store.dispatch(
      setDialog({
        name: 'VerficationProp',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Messages à répondre',
          middle: true,
          fullScreenOn: false,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
        },
        contentProps: {
          message: 'Veuillez répondre aux messages reçus.',
        },
      })
    );
    return setTimeout(() => {
      store.dispatch(openChatPanel(null, 'artisan'));
      store.dispatch(setDialog({}));
    }, 1000);
  }
}

export const openPreview = (obj, title, withLoading = true) => {
  if (withLoading) {
    store.dispatch(
      setDialog({
        name: 'LoadingDialog',
        open: true,
        dialogProps: { title: 'Prévisualisation en cours' },
      })
    );
  }
  return api
    .all('pdfhtmlform')
    .post(obj)
    .then((res) =>
      openPDF(`data:application/pdf;base64,${res.body().data()}`, title)
    )
    .catch(() => {
      if (!obj?.data?.devis?.id && obj?.data?.devis?.isCaldeo) {
        notifSystem.error(
          'Erreur caldeo',
          'Veuillez enregistrer le devis d\'abord'
        );
      } else {
        notifSystem.error(
          'Erreur system',
          'Le fichier n\'a pas pu être généré.'
        );
      }
    })
    .finally(() => withLoading && store.dispatch(closeDialog()));
};

export const openAssignDialog = ({
  data,
  collection,
  cb,
  field = 'ajout',
  filterUsers = false,
}) =>
  store.dispatch(
    setDialog({
      name: 'AssignDialog',
      open: true,
      contentProps: {
        field: (data.login || {})[field],
        filterUsers,
      },
      dialogProps: {
        title: 'Séléctionner un utilisateur',
        middle: true,
        middleAll: true,
      },
      actions: [
        {
          children: 'Assigner',
          onClick: (r, close) => {
            if (r.userSelected) {
              if (r.userSelected._id === (data.login || {})[field]) {
                close();
                return notifSystem.success(
                  'Opération réussie',
                  'Informations sauvegardées'
                );
              }
              return api[collection]
                .patch(data._id, { login: { [field]: r.userSelected._id } })
                .then(() => {
                  if (cb) {
                    cb(data, r.userSelected);
                  }
                  return notifSystem.success(
                    'Opération réussie',
                    'Informations sauvegardées'
                  );
                })
                .catch((e) =>
                  notifSystem.error(e.name, e.response ? e.response.data : e)
                )
                .finally(() => close());
            }
            return notifSystem.error(
              'Erreur',
              'Veuillez séléctionner un utilisateur valide'
            );
          },
        },
      ],
    })
  );

export function dialogIphone({ props, message, demandeId, tel }) {
  return props.setDialog(
    IphoneDialog,
    true,
    {
      text: message,
    },
    async (newMessage) => {
      props.setDialog(null, false, '', null);
      if (!newMessage || typeof newMessage !== 'string') {
        return;
      }
      return api
        .one('sms', tel)
        .custom('send')
        .post({
          message: newMessage,
          collection: 'intervention',
          filters: JSON.stringify({
            to: { $in: [tel, `+33${+tel}`] },
            sender: true,
            $or: [
              { artisan: { $exists: true } },
              { intervention: { $exists: true } },
              { devis: { $exists: true } },
            ],
          }),
          newContact: true,
          hasWhatsapp: await checkWP(tel),
        })
        .then(() => {
          notifSystem.success('Opération réussie', 'Le SMS a été envoyé');
          api.history
            .post({
              date: new Date(),
              text: 'Une demande de photo a été envoyée',
              demandeId,
              userId: props.userId,
            });
        })
        .catch((e) =>
          notifSystem.error(
            e.name,
            e.response ? e.response.data : 'Le SMS n\'a pas pu être envoyé'
          )
        );
    }
  );
}

export const openCSVDialog = ({ uploadData, ...props }) =>
  store.dispatch(
    setDialog({
      name: 'DialogUploadCSV',
      open: true,
      contentProps: props,
      dialogProps: {
        title: 'Importation CSV',
        maxWidth: 'md',
      },
      actions: [
        {
          children: 'Importer',
          onClick: ({ data, month }, close) => {
            if (month && data && data.length) {
              uploadData(data, close);
            } else if (!month) {
              notifSystem.error(
                'Aucun mois sélectionné',
                'Veuillez sélectionner un mois'
              );
            } else {
              notifSystem.error(
                'Aucune donnée importer',
                'Veuillez ajouter un fichier CSV valid'
              );
            }
          },
        },
      ],
    })
  );

export function customConfirmDialog({
  title = 'Etes-vous sûr de faire cette action ?',
  dialogProps = {},
  contentProps = {},
  cb = (e) => e,
}) {
  return store.dispatch(
    setDialog({
      name: 'ConfirmDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title,
        ...dialogProps,
      },
      contentProps,
      actions: [
        {
          children: 'annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (data, close) => {
            cb();
            close();
          },
        },
      ],
    })
  );
}

export const searchElastic = ({ limit = 10, display = 0, collection, text }) =>
  api.elasticsearch.getAll({
    limit,
    collection,
    display,
    value: text,
  });

export function demandePhoto(
  props,
  title,
  { tel = '', categorie = '', zipCode = '', demandeId } = {}
) {
  return setDialog({
    name: 'DemandePhoto',
    open: true,
    hideClose: true,
    dialogProps: {
      title: title,
      middle: true,
      fullScreenOn: false,
      disableEscapeKeyDown: true,
    },
    contentProps: {
      tel: tel || '',
      categorie: categorie
        ? categories.find((e) => e._id === categorie).name
        : '',
      zipCode: zipCode || '',
    },
    actions: [
      {
        children: 'Annuler',
        color: 'secondary',
        onClick: (r, close) => close(),
      },
      {
        children: 'Envoyer le SMS',
        onClick: (data, close) => {
          const { categorie, zipCode, tel } = data;
          const zipCodeRegex = /^[0-9]{5}$/;
          if (tel && categorie && zipCode) {
            if (!zipCodeRegex.test(zipCode) || !checkIfMobileNumber(tel)) {
              return notifSystem.error(
                'Erreur',
                'Le code postal ou le numéro de téléphone n\'est pas valide'
              );
            }
            close();
            const user = store
              .getState()
              .users.find((e) => e._id === store.getState().userId);
            const message = `${user.alias} ${categorie} ${zipCode}`;
            return dialogIphone({ props, message, data, demandeId, tel });
          }
          return notifSystem.error(
            'Erreur',
            'Veuillez remplir tous les champs'
          );
        },
      },
    ],
  });
}
