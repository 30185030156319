import company from '@lba-dev/package.local-globals/company';

const env = process.env.NODE_ENV || 'development';

export const site = {
  development: 'http://localhost:8080',
  production: company.projectLink,
  stage: company.projectLink_stage,
}[env];
export default {
  site
};
