import React, { useState } from 'react';
import { Map } from 'immutable';
import { useNavigate } from 'react-router';

import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import {
  AddIcCall, Assessment, CalendarMonth, PostAdd, ReportProblem, ZoomIn
} from '@mui/icons-material';

import TelPopover from './TelPopover';
import {
  appel as appelArtisan, openArtisanSignalements
} from '../../../actions/artisan';
import { appel as appelInter } from '../../../actions/inter';
import { handleLink } from '../constant';
import AddPopover from './addPopover';
import store from '../../../store';
import { setDialog } from '../../../actions/dialog';

const HeaderIcons = ({ selected = {}, isArtisan, isTicket }) => {
  const [anchorTels, setAnchorTels] = useState(null);
  const [anchorAdd, setAnchorAdd] = useState(null);
  const data = selected?.dataObject || {};
  const artisanData = isTicket ?
    {
      ...(selected?.artisanObject || {}),
      _id: selected?.artisan
    } : data;
  const tels = data.tels;
  const navigate = useNavigate();

  const handleAbsenceDialog = () => {
    const callback = () => {
      store.dispatch(setDialog({}));
    }
    store.dispatch(setDialog(
      {
        name: 'AbsencesDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          maxWidth: 'md',
          title: `Absences de l'artisan
            ${data.companyName ||
              `${data.firstname || ''}
            ${data.name || ''}`}`
        },
        contentProps: {
          callback,
          artisan: data
        },
        actions: [
          {
            children: 'fermer',
            color: 'secondary',
            onClick: (_, close) => close(),
          }
        ]
      }
    ))
  };
  const openSignalements = (e) => {
    e.stopPropagation();
    e.preventDefault();
    openArtisanSignalements(isTicket ? artisanData : selected);
  };

  const openAdd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorAdd(e.target);
  };

  const handleTels = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let appelFunc = appelInter;
    let params = {
      selected: { id: data.id }
    };
    if (isArtisan || isTicket) {
      appelFunc = appelArtisan;
      params = {
        artisan: new Map({
          _id: artisanData._id,
          companyName: artisanData.companyName ||
            `${artisanData.name || ''} ${artisanData.firstname || ''}`
        }),
      };
    }
    if (data.tels && data.tels.length > 1) {
      setAnchorTels(e.target);
    } else if (data.tels && data.tels.length) {
      appelFunc(
        params,
        data.tels[0].tel,
        {
          client: data.client,
          ...(!data.onSitePayment ? { billing: data.billing } : {})
        }
      );
    }
  };

  if (selected.newContact) {
    return !selected.freshContact ? <>
      <Box display='flex'>
        {!isArtisan ?
          <Tooltip title={'Ajouter'}>
            <IconButton
              color='inherit'
              aria-describedby={'addElem'}
              onClick={openAdd}
              sx={{ px: 1 }}>
              <PostAdd />
            </IconButton>
          </Tooltip> : ''}
        <Tooltip title={'Appeler'}>
          <IconButton
            color='inherit'
            aria-describedby={'artisanTels'}
            onClick={handleTels}
            sx={{ px: 1 }}>
            <AddIcCall />
          </IconButton>
        </Tooltip>
      </Box>
      {(data?.tels || []).length ? <TelPopover
        anchorEl={anchorTels}
        setAnchorEl={setAnchorTels}
        tels={data.tels}
        selected={selected}
      /> : ''}
      <AddPopover
        anchorEl={anchorAdd}
        setAnchorEl={setAnchorAdd}
        tels={data.tels}
        navigate={navigate}
      />
    </> : '';
  }

  return <>
    <Box display='flex'>
      {isTicket || isArtisan ? <>
        <Tooltip title='Absences'>
          <IconButton color='inherit' onClick={handleAbsenceDialog}>
            <CalendarMonth />
          </IconButton>
        </Tooltip>
        <Tooltip title='Signalements'>
          <IconButton color='inherit' onClick={openSignalements}>
            <ReportProblem />
          </IconButton>
        </Tooltip>
      </> : ''}
      {isTicket ? <Tooltip title={'Recap'}>
        <IconButton color='inherit' onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleLink(
            `/artisan/${artisanData._id}/recap`);
        }}>
          <Assessment />
        </IconButton>
      </Tooltip> : ''}
      <Tooltip title={isArtisan ? 'Recap' :
        `Ouvrir la fiche ${isTicket ? '(OS)' : ''}`}>
        <IconButton color={isTicket ? 'secondary' : 'inherit'} onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleLink(
            isTicket ?
              `/intervention/${data.id}` :
              `/${selected.type}/${selected._id}${isArtisan ? '/recap' : ''}`);
        }}>
          {isArtisan ?
            <Assessment /> :
            <ZoomIn />}
        </IconButton>
      </Tooltip>
      <Tooltip title={`Appeler${isTicket ? ' l\'artisan' : ''}`}>
        <IconButton
          color='inherit'
          aria-describedby={'artisanTels'}
          onClick={handleTels}
          sx={{ px: 1 }}>
          <AddIcCall />
        </IconButton>
      </Tooltip>
    </Box>
    {data?.tels ? <TelPopover
      anchorEl={anchorTels}
      setAnchorEl={setAnchorTels}
      tels={tels}
      isArtisan={isArtisan}
      selected={selected}
    /> : ''}
  </>;
};

export default HeaderIcons;
