import React from 'react';
import { moment } from '@lba-dev/package.local-globals/moment';
import { getAlias, getAliasFromPhone } from '../../utils/function';
import { CallIcon } from '../../utils/IconsDisplay';

const DATE_AND_LOGIN_REGEX = /(?: le)? le \d.+/;

const civility = {
  Madame: 'Mme.',
  Monsieur: 'M.',
  Société: 'Soc.',
};

export function formatHistory({
  users,
  elem,
  interSavComments = [],
  recouvrement,
  signalements = [],
  histories = [],
  appels = [],
  records = []
}) {
  return {
    users,
    interSavComments,
    recouvrement,
    signalements: signalements ? signalements.map((report) => ({
      _id: report._id,
      type: report.signal.name,
      date: moment(report.date.creation).format('L [à] HH:mm'),
      user: getAlias(report.login.creation),
      message: `${report.description}`,
      comments: report.comments,
      obj: { style: { color: report.quarantaine ? 'red' : 'inherit' } },
      filter: 'sStatus',
      status: report.status
    })) : [],
    findUserLogin: getAlias,
    histories: elem
      ? [
        {
          type: 'Devis',
          key: 'devis',
          obj: { id: elem.id },
        },
        elem.login.artisan && {
          type: 'Devis artisan',
          key: 'devis',
          user: null,
          obj: {
            artisanId: elem.artisan,
            artisanName:
                elem.currentArtisan && elem.currentArtisan.companyName,
          },
        },
        {
          type: 'Intervention',
          key: 'intervention',
          obj: {
            artisanId: elem.artisan,
            artisanName:
                elem.currentArtisan && elem.currentArtisan.companyName,
          },
        },
        ...appels.map((e) => ({
          type: 'Appel',
          user: e.userId,
          date: e.date,
          message: `vers l'artisan ${e.companyName
          } ${e.comment ? `- ${e.comment}` : ''}`,
          obj: {
            url: e.url,
          }
        })),
        ...records.map((e) => ({
          type: 'Appel',
          user: e.record.userId || getAliasFromPhone(e.record.agent),
          date: e.record.callStart,
          message: `${e.record.direction === 'outbound' ?
            'sortant' : 'entrant'} - ${e.record.callerIdNumber
          } - ${e.service}`,
          obj: {
            url: e.record.fileUrl,
            duration: e.record.duration,
            transcription: e.record.transcription,
            processingTextChatgpt: e.record.processingTextChatgpt,
          }
        })),
        ...(elem.communcations || []).map((e) => ({
          type: 'Appel',
          user: 'Automatique',
          date: e.date,
          message: `${!e.callTo ? `${e.companyName
          } appelle ${civility[elem.client.civility]} ${elem.client.name}` :
            `${civility[elem.client.civility]} ${
              elem.client.name} rappel ${e.companyName}`}`,
          obj: {
            url: e.urlEnregistrement,
            duration: e.duration,
            transcription: e.transcription,
            processingTextChatgpt: e.processingTextChatgpt,
            Icon: <div style={{ float: 'right' }}>
              <CallIcon
                classes={{}}
                elem={{
                  ...elem,
                  communcations: [e]
                }}
              />
            </div>
          }
        })),
        ...(elem.comments || []).map((e) => ({
          type: 'Commentaire',
          user: e.userId,
          date: e.date,
          message: e.commentValue,
        })),
        ...histories.map((e) => ({
          type: e.text.replace(DATE_AND_LOGIN_REGEX, ''),
          user: e.userId || e.artisanId,
          date: e.date,
          obj: e.data,
        })),
        ...((elem.histories || {}).envoiFacture || []).map((e) => ({
          type: 'Facture envoyée',
          user: e.login,
          date: e.date,
        })),
      ]
        .filteredMap(
          ({
            type,
            key,
            date = elem.date[key],
            user = elem.login[key],
            sortingDate = date,
            obj = {},
            message,
          }) => ({
            type,
            sortingDate: new Date(sortingDate),
            date: moment(date).format('L [à] HH:mm'),
            user: getAlias(user),
            obj,
            message,
          }),
          (e) =>
            e && (e.date || elem.date[e.key]) && (e.user || elem.login[e.key])
        )
        .sortBy('sortingDate')
      : [],
  };
}
