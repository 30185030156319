/* eslint-disable max-lines */
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  MusicNote,
  PhoneCallback,
  Call,
} from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { green, red } from '@mui/material/colors';
import { moment } from '@lba-dev/package.local-globals/moment';
import { getAlias } from '../../../../utils/function';
import Tooltip from '@mui/material/Tooltip';
import PriceChip from '../PriceChip';
import { data as categories } from '@lba-dev/package.local-globals/categories';
import { formatTextToJSON } from '@lba-dev/package.local-globals/utils';

export const genDate = (date) => {
  let format = 'HH[h]mm';
  if (
    moment(date).isBefore(moment(), 'day')
  ) {
    format = 'HH[h]mm - DD/MM';
  }
  return moment(date).format(format);
};

export const ShowMenu = ({ array, isEmpty, classes }) => {
  let getItems =
    <Typography className={classes.historyText} variant="body1">
      {isEmpty}
    </Typography>;
  if (array.length) {
    getItems = array.reverse().map((item, i) =>
      typeof item.action !== 'object' &&
      <Typography key={i} className={classes.historyText} variant="body1">
        {`${moment(item.date).format('L[ à ]HH[h]mm')} - 
        ${getAlias(item.userId)} : ${item.action}`}
      </Typography>
    );
  }
  return (
    <div className={classes.contentHistory}>
      {getItems}
    </div>
  );
};

export const ShowReason = ({ array, isEmpty, classes }) => {
  let getReason =
    <Typography className={classes.historyText} variant="body1">
      {isEmpty}
    </Typography>;
  if (array.length) {
    getReason = array.reverse().map((reason, i) =>
      (typeof reason.action === 'object') ?
        <Typography key={i} className={classes.historyText} variant="body1">
          {`${isEmpty}`}
        </Typography> :
        <Typography key={i} className={classes.historyText} variant="body1">
          {`${reason.action}`}
        </Typography>
    )[0];
  }
  return (
    <div>
      {getReason}
    </div>
  );
};

export const returnSection = (call, kind) =>
  call.history && call.history.length
    ? call.history.filter(item => item.categorie === kind)
    : [];

export const historyTypes = [
  'Ecoute de l\'audio',
  'Appel client'
];

export const getCategorie = (call) => {
  if (typeof call.processingTextChatgpt === 'string') {
    const data = formatTextToJSON(call.processingTextChatgpt);
    const findTags = data.find((s) => (/Cat[e|é]gorie?/i).test(s.label) || (/tags?/i).test(s.label));
    if (findTags) {
      const cateogire = categories.find((l) =>
        new RegExp(l.match).test(findTags.value)
      );
      if (cateogire) {
        return (
          <Button
            style={{
              backgroundColor:
                cateogire.color[cateogire.name === 'neutre' ? '600' : '500'],
              color: 'white',
              fontSize: '11px',
              margin: '0.6%',
              minWidth: 40,
            }}
            children={cateogire.name}
          />
        );
      }
    }
  }
  return 'N/A';
};


export const findTags = (call, name) => {
  if (typeof call.processingTextChatgpt === 'string') {
    const data = formatTextToJSON(call.processingTextChatgpt);
    const findTags = data.find((s) => new RegExp(name, 'i').test(s.label));
    if (findTags) {
      return findTags.value;
    }
  }
  return 'N/A';
};

export const getIcons = call => {
  const price = findTags(call, 'Panier moyen');
  return (
    <Grid container spacing={1}>
      {call.onlyMusic ? (
        <Grid item>
          <Tooltip
            disableInteractive
            title="appel mis en attente"
            children={<MusicNote style={{ color: red[400] }} />}
          />
        </Grid>
      ) : ''}
      {call.agentCallBack ? (
        <Grid item>
          <Tooltip
            disableInteractive
            title="l'agent a rappelé le client"
            children={<Call style={{ color: green[400] }} />}
          />
        </Grid>
      ) : ''}
      {call.clientCallBack ? (
        <Grid item>
          <Tooltip
            disableInteractive
            title="le client a rappelé"
            children={<PhoneCallback style={{ color: green[400] }} />}
          />
        </Grid>
      ) : ''}
      {price && price !== 'N/A' ? (
        <Grid item>
          <PriceChip
            price={price}
          />
        </Grid>
      ) : ''}
    </Grid>
  );
};

export const formatTextToHTML = (text = '') => {
  const data = formatTextToJSON(text);
  const formattedText = [];
  data.forEach(({ label, value }) => {
    value = value.toString();
    formattedText.push(
      <>
        {label && (
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {label} :
          </Typography>
        )}
        {value && (
          <Typography variant="subtitle2">
            {value.includes('\n') || value.includes('?')
              ? value.split(value.includes('?') ? '?' : '\n').map(
                (e) =>
                  e && (
                    <Typography key={e}>
                      {e}
                      {value.includes('?') ? ' ?' : ''}
                    </Typography>
                  )
              )
              : value}
          </Typography>
        )}
      </>
    );
  });
  return formattedText;
};
