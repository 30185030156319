/* eslint-disable max-lines */
import aSubStatus from '@lba-dev/package.local-globals/aSubStatus';
import categories from '@lba-dev/package.local-globals/categories';
import {
  data as progressData
} from '@lba-dev/package.local-globals/progressStatusSupInvoice';
import status from '@lba-dev/package.local-globals/iStatus';
import { data as paidStatus } from '@lba-dev/package.local-globals/paidStatus';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { R_DATAS } from '@lba-dev/package.local-globals/rStatus';
import { dataC, dataM } from '@lba-dev/package.local-globals/caldeoType';
import constants from '../../constants/collections';

import { Types } from './Types';
import { addColumnsByService } from './columnsByService';
import sourceLogoArtisans from '../sourceLogoArtisans';

export const List = ({ user, users, service }) => {
  const artisanKeys = permit(user, { key: 'displayArtisanCompanyName' })
    ? ['currentArtisan.companyName', '']
    : ['currentArtisan.name', 'currentArtisan.firstname'];
  const listColumns = addColumnsByService(service || user.service, {
    users,
    artisanKeys,
    permission: user.permission
  });

  const list = {
    default: {
      dataFormat: listColumns,
      listen: constants.interventions,
      components: [],
    },
    avrnor: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Prochaine relance': Types.Text,
          'Cat.': Types.Rectangle,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prix: Types.Money,
          iStatus: Types.Chip,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: 'reduce' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
        ],
        keys: [
          'id',
          'login.ajout',
          'date.reminder',
          'categorie',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'announcedPrice',
          'status',
        ],
        checkType: [
          'number',
          'users',
          'date',
          'categorie',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'prix',
          'iStatus',
        ],
      },
      listen: constants.interventions,
    },
    vrfnor: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Vérification: Types.Text,
          'Prochaine relance': Types.Text,
          'Cat.': Types.Rectangle,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prix: Types.Money,
          iStatus: Types.Chip,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: 'reduce' },
          { class: 'reduce' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
        ],
        keys: [
          'id',
          'login.ajout',
          'date.verif',
          'date.reminder',
          'categorie',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'announcedPrice',
          'status',
        ],
        checkType: [
          'number',
          'users',
          'date',
          'date',
          'categorie',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'prix',
          'iStatus',
        ],
      },
      listen: constants.interventions,
    },
    caldeo: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Caldeo: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          cType: Types.Chip,
          mType: Types.Chip,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '', list: dataC },
          { class: '', list: dataM },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.caldeoPrisEnCharge',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'caldeoType',
          'mprType',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'number',
        ],
      },
      listen: constants.interventions,
    },
    recouvrement: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          IStatus: Types.Chip,
          RStatus: Types.Chip,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '', list: R_DATAS },
          {
            class: '',
            list: [],
            name: 'firstPaid',
            key: 'firstPaid',
            status: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'recovery.recStatus',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'number',
          'appel',
        ],
      },
      listen: constants.recouvrements,
      components: [],
    },
    quality: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Vérification: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Notation: Types.Stars,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          'date.verif',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          [
            'currentArtisan.companyName',
            'currentArtisan.firstname',
            'currentArtisan.name',
          ],
          'date.intervention',
          'announcedPrice',
          'status',
          'notation.client.note',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['currentArtisan.companyName'],
          'date',
          'prix',
          'iStatus',
          'number',
          'appel',
        ],
      },
      listen: constants.interventions,
      components: [],
    },
    btob: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          rSST: Types.Chip,
          'Grand Compte': Types.nomGramdCompte,
          'Envoi facture': Types.Text,
          'Marge €': Types.margeBtobPrice,
          'Marge %': Types.margeBtobPercent,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          {
            class: 'th',
            name: 'grandCompte',
            list: [],
            key: 'billing.grandCompte',
          },
          { class: 'reduce' },
          { class: '' },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          [
            'currentArtisan.companyName',
            'currentArtisan.firstname',
            'currentArtisan.name',
          ],
          'date.intervention',
          'announcedPrice',
          'firstPaid',
          'billing.companyName',
          'date.envoiFacture',
          'text',
          'text',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['currentArtisan.companyName'],
          'date',
          'prix',
          'number',
          'billing.grandCompte',
          'date',
          'text',
          'text',
        ],
      },
      listen: constants.interventions,
      components: [],
    },
    recouvrementBTOB: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          IStatus: Types.Chip,
          RStatus: Types.Chip,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '', list: R_DATAS },
          {
            class: '',
            list: [],
            name: 'firstPaid',
            key: 'firstPaid',
            status: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'recovery.recStatus',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date',
          'prix',
          'interStatus',
          'iStatus',
          'number',
          'appel',
        ],
      },
      listen: constants.recouvrements,
      components: [],
    },
    recouvrementNPris: {
      dataFormat: {
        table: {
          Temps: Types.Icon,
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          IStatus: Types.Chip,
          RStatus: Types.Chip,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: '' },
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '', list: R_DATAS },
          {
            class: '',
            list: [],
            name: 'firstPaid',
            key: 'firstPaid',
            status: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'chronoLitigesListInter',
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'recovery.recStatus',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'chrono',
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'number',
          'appel',
        ],
      },
      listen: constants.recouvrements,
      components: [],
    },
    fournCanceled: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Avancement: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: '' },
          { class: '' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: '' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: 'align', list: progressData },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'supplieProgress',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
        ],
      },
      listen: constants.interventions,
    },
    vrf: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Verif: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.verif',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'appel',
        ],
      },
      listen: constants.interventions,
    },
    decote: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          'Prix à 20h': Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          'Qualité support': Types.SupportPoints,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'savedFinalPrice',
          'status',
          'points',
          'qualite',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'date',
          'prix',
          'prix',
          'iStatus',
          'points',
          'qualite',
          'number',
          'appel',
        ],
      },
      listen: constants.interventions,
    },
    decoteAprog: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          'Prix à 20h': Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          'Qualité support': Types.SupportPoints,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'savedFinalPriceAprog',
          'status',
          'points',
          'qualite',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'date',
          'prix',
          'prix',
          'iStatus',
          'points',
          'qualite',
          'number',
          'appel',
        ],
      },
      listen: constants.interventions,
    },
    support: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Commercial: Types.Text,
          'Prise en charge par': Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Description: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          'Qualité support': Types.SupportPoints,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.priseEnChargeSupport',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'points',
          'qualite',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'points',
          'qualite',
          'number',
          'appel',
        ],
      },
    },
    supportAnnule: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Commercial: Types.Text,
          'Prise en charge par': Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Description: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          'Motif': Types.Text,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.priseEnChargeSupport',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'cancelMsg',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'text'
        ],
      },
    },
    delaisEncaissementList: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Description: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Appel: Types.Icon,
          Points: Types.Points,
          rSST: Types.Chip,
          'Delais encaissment': Types.DelaisEncaissement
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'metiersDevis.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'appel',
          'points',
          'firstPaid',
          'date.verif'
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'metiersDevis.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'appel',
          'points',
          'number',
          'date'
        ],
      },
      listen: constants.interventions,
      components: [],
    }
  };

  return list;
};
