import React from 'react';
import { Avatar, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import loadImages from '../../utils/loadImages'
import {
  data as smsMode, WHATSUP, WHATSAPPBUSINESS, RINGOVER, RESPISMS, SMS
} from '@lba-dev/package.local-globals/smsMode';

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 16,
    paddingBottom: 0
  },
  imageAvatar: {
    width: 18,
    height: 18,
    boxShadow: theme.shadows[1]
  },
  smsIcon: {
    border: '1px solid #000',
    color: '#000',
    borderRadius: 4,
    fontSize: 8,
  },
}));

const ModeIcon = ({ elemMode, isMailReceived = false }) => {
  const { classes } = useStyles();
  const mode = elemMode === 'ticket' ? 'APP' :
    smsMode.find(e => e._id === +(elemMode || SMS));
  const path = mode.image && loadImages(mode.image);

  return (mode && (![WHATSUP, WHATSAPPBUSINESS, RINGOVER, RESPISMS]
    .includes(mode._id) ?
    <Typography
      children={isMailReceived ? 'Mail' : 'SMS'}
      variant='caption'
      className={classes.smsIcon}
      fontWeight={500}
    /> :
    (path && <Avatar
      className={classes.imageAvatar}
      src={path}
      children={
        (mode.icon ? <mode.icon className={classes.icon} /> : '')
      }
    />))) || '';
};

export default ModeIcon;
