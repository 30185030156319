// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Users
export const USERS_FETCH = 'USERS_FETCH';
export const USER_ID_FETCH = 'USER_ID_FETCH';
export const USER_CONNECT = 'USER_CONNECT';
export const USER_UPDATE = 'USER_UPDATE';

// Fournisseurs
export const FOURNISSEURS_FETCH = 'FOURNISSEURS_FETCH';
export const FOURNISSEURS_UPDATE = 'FOURNISSEURS_UPDATE';

// Combos
export const COMBOS_FETCH = 'COMBOS_FETCH';
export const COMBOS_UPDATE = 'COMBOS_UPDATE';

// Combinations
export const COMBINATIONS_FETCH = 'COMBINATIONS_FETCH';
export const COMBINATIONS_UPDATE = 'COMBINATIONS_UPDATE';

// btobs
export const BTOBS_FETCH = 'BTOBS_FETCH';
export const BTOBS_UPDATE = 'BTOBS_UPDATE';

// Products
export const PRODUCTS_FETCH = 'PRODUCTS_FETCH';
export const PRODUCTS_UPDATE = 'PRODUCTS_UPDATE';

// MetiersSteps
export const METIERSSTEPS_FETCH = 'METIERSSTEPS_FETCH';
export const METIERSSTEPS_UPDATE = 'METIERSSTEPS_UPDATE';

// MetiersDevis
export const METIERSDEVIS_FETCH = 'METIERSDEVIS_FETCH';
export const METIERSDEVIS_UPDATE = 'METIERSDEVIS_UPDATE';

// Questions
export const QUESTIONS_FETCH = 'QUESTIONS_FETCH';
export const QUESTIONS_UPDATE = 'QUESTIONS_UPDATE';

// Counter
export const COUNTER_FETCH = 'COUNTER_FETCH';

// Equipes
export const EQUIPES_FETCH = 'EQUIPES_FETCH';

// Notifications
export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';

// Annulations
export const ANNULATIONS_FETCH = 'ANNULATIONS_FETCH';
export const ANNULATIONS_UPDATE = 'ANNULATIONS_UPDATE';

// Points
export const POINTS_FETCH = 'POINTS_FETCH';

// grandCompte
export const GRAND_COMPTE_FETCH = 'GRAND_COMPTE_FETCH';
export const GRAND_COMPTE_UPDATE = 'GRAND_COMPTE_UPDATE';

// signals
export const SIGNALS_FETCH = 'SIGNALS_FETCH';
export const SIGNALS_UPDATE = 'SIGNALS_UPDATE';

// confCand
export const CONFCAND_FETCH = 'CONFCAND_FETCH';
export const CONFCAND_UPDATE = 'CONFCAND_UPDATE';

// confProsp
export const CONFPROSP_FETCH = 'CONFPROSP_FETCH';

// candidat refus

export const CONFIG_FETCH = 'CONFIG_FETCH';
export const CONFIG_UPDATE = 'CONFIG_UPDATE';

// Dialog
export const SET_DIALOG = 'SET_DIALOG';
export const SET_DIALOG_CONTENT_PROP = 'SET_DIALOG_CONTENT_PROP';
export const RESET_DIALOG_CONTENT_PROP = 'RESET_DIALOG_CONTENT_PROP';

// raisonsArchiveSst
export const RAISONSARCHIVESST_FETCH = 'RAISONSARCHIVESST_FETCH';
export const RAISONSARCHIVESST_UPDATE = 'RAISONSARCHIVESST_UPDATE';

// Loading
export const LOADING = 'LOADING';

//CHATPANEL
export const OPEN_CHATPANEL = 'OPEN_CHATPANEL';

// Questions
export const COUNT_FETCH = 'COUNT_FETCH';
export const COUNT_UPDATE = 'COUNT_UPDATE';
