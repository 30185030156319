import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@mui/material/Grid';
import api from '../../api';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';
import { setDialogContentProp } from '../../actions/dialog';
import { data as categories } from '@lba-dev/package.local-globals/categories';

const setChecked = setDialogContentProp('checked');
const mapDispatchToProps = { setChecked };


const compare = (key) => ({
  name: {
    key: 'Nom',
    component: ({ value }) => <Typography children={value} />,
  },
  firstname: {
    key: 'Prénom',
    component: ({ value }) => <Typography children={value} />,
  },
  email1: {
    key: 'E-mail',
    component: ({ value }) => <Typography children={value} />,
  },
  tel1: {
    key: 'Numéro de téléphone mobile',
    component: ({ value }) => <Typography children={value} />,
  },
  tel2: {
    key: 'numéro de téléphone supplémentaire',
    component: ({ value }) => <Typography children={value} />,
  },
  categories: {
    key: 'Métier',
    component: ({ value = [] }) => {
      const cats = categories
        .filter(e => value.includes(e._id))
        .map(e => e.name)
        .join(', ');
      return <Typography children={cats} />;
    },
  },
  billingAddress: {
    key: 'Adresse',
    component: ({ value }) => [
      <Typography key={0} children={value.number} />,
      <Typography key={1} children={value.road} />,
      <Typography key={2} children={value.city} />,
      <Typography key={3} children={value.zipcode} />,
    ],
  },
  disponibility: {
    key: 'Horaires spéciaux',
    component: ({ value = {} }) => {
      const text = Object.entries(value)
        .reduce((acc, [key, v]) =>
          v.enable ? acc.concat(key.toFirstUpperCase()) : acc
        , []).join(' / ');
      return <Typography children={text} />;
    },
  },
  legalForm: {
    key: 'Type de société',
    component: ({ value }) => <Typography children={value} />,
  },
  companyName: {
    key: 'Raison Sociale',
    component: ({ value }) => <Typography children={value} />,
  },
  siret: {
    key: 'Siret',
    component: ({ value }) => <Typography children={value} />,
  },
  photo: {
    key: 'Photo',
    component: ({ value, dropbox, artisanObj }) => {
      const img = dropbox
        ? artisanObj.dropbox.findLast(e => e.type === 13).link
        : value;
      return (
        <img src={img} alt="icon" style={{ width: 100, height: 'auto' }} />
      );
    }
  }
})[key] || { key, component: () => '' };

const listKeys = [
  'name',
  'firstname',
  'email1',
  'tel1',
  'tel2',
  'billingAddress',
  'categories',
  'legalForm',
  'disponibility',
  'companyName',
  'siret',
  'iban',
  'photo',
  'dropbox'
];

const formatArtisan = (artisan) =>
  listKeys.reduce((acc, curr) => ({ ...acc, [curr]: artisan[curr] }), {});
const keysToKeep = ['number', 'road', 'zipcode', 'city', 'location'];

const comparison = (original, update, key) => ({
  billingAddress: JSON.stringify(
    keysToKeep.reduce((acc, k) => ({ ...acc, [k]: original[key][k] }), {})
  ) !== JSON.stringify(
    keysToKeep.reduce((acc, k) => ({ ...acc, [k]: update[key][k] }), {})
  ),
  categories: JSON.stringify(original[key]) !== JSON.stringify(update[key]),
  disponibility: Object.keys(original[key])
    .some(k => original[key][k]?.enable !== update[key][k]?.enable),
})[key];

const getDifferentKeys = (obj1, obj2) =>
  Object.keys({ ...obj1, ...obj2 })
    .reduce((acc, curr) => {
      if (obj2[curr]) {
        if (
          (Array.isArray(obj1[curr]) && Array.isArray(obj2[curr])) ||
          (typeof obj1[curr] === 'object' && typeof obj2[curr] === 'object')
        ) {
          if (comparison(obj1, obj2, curr)) {
            acc.push(curr);
          }
          return acc;
        }
        if (obj1[curr] !== obj2[curr]) {
          acc.push(curr);
        }
      }
      return acc;
    }, []);

const mapStateToProps = ({
  dialog: { contentProps: { artisan, checked } },
  users,
  userId,
}) => ({
  artisan,
  checked,
  user: users.find(u => u._id === userId)
});

const ArtisanUpdateDialog = ({ artisan, checked, setChecked }) => {
  const [artisanUpdate, setArtisanUpdate] = useState();
  const artisanObj = formatArtisan(artisan);
  const affected = (
    artisanObj && artisanUpdate && getDifferentKeys(artisanObj, artisanUpdate)
  ) || [];

  useEffect(() => {
    api.artisanUpdate
      .get(artisan._id)
      .then(res => {
        const obj = res.body().data();
        setArtisanUpdate(obj);
        setChecked(
          getDifferentKeys(artisanObj, obj)
            .map(e => ({ key: e, checked: false, value: obj[e] }))
        );
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Key</TableCell>
            <TableCell align="right">Valeur actuelle</TableCell>
            <TableCell align="right">Nouvelle valeur</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {affected
            .filter(key => listKeys.includes(key))
            .map(key => {
              const oldValue = artisanObj[key];
              const newValue = artisanUpdate[key];
              return <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ width: '5%' }}>
                  <CustomCheckbox
                    checked={checked.find(e => e.key === key)?.checked}
                    path={key}
                    setData={(p, v) => {
                      if (checked.find(e => e.key === key)) {
                        const index = checked.findIndex(e => e.key === key);
                        const newData = [
                          ...checked.slice(0, index),
                          { key: p, checked: v, value: newValue },
                          ...checked.slice(index + 1),
                        ];
                        return setChecked(newData);
                      }
                      return setChecked(
                        checked.concat({ key: p, checked: v, value: newValue })
                      );
                    }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  children={compare(key).key}
                />
                <TableCell
                  align="right"
                  children={compare(key).component({
                    value: oldValue,
                    artisanObj,
                    dropbox: true,
                  })}
                />
                <TableCell
                  align="right"
                  children={compare(key).component({ value: newValue })}
                />
              </TableRow>;
            })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ArtisanUpdateDialog);
