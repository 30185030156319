import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog } from './dialog';

/**
 * Send a campaign via Ringover
 * @param {Object} elem
 * @param {Object} selected
 * @returns {Promise}
 */

const sendCampaignVoice = (campaigns, selected) =>
{
  api.ringover.custom('sendCampaignVocal').post({
    selected,
    campaigns
  })
    .then(() =>
      notifSystem.success('Campagne envoyée', 'La campagne a bien été envoyée'))
    .catch(() =>
      notifSystem.error('Erreur', 'La campagne n\'a pas pû être envoyée'));
}

const sendCampaignCall = (campaigns, telephoneIds) => {
  api.ringover.custom('sendCampaignCall').post({
    telephoneIds,
    campaigns
  })
    .then(() =>
      notifSystem.success('Campagne envoyée', 'La campagne a bien été envoyée'))
    .catch(() =>
      notifSystem.error('Erreur', 'La campagne n\'a pas pû être envoyée'));
}

/**
 * Display Sounds From Ringover
 * @param {Array} campaigns
 * @param {Array} users
 * @param {Number} tab
 * @param {Object} currentUser
 * @returns {Promise}
 */

export async function
displaySoundsFromRingover(campaigns, users, tab, currentUser) {
  try {
    const response = await api.ringover.custom('sounds').get();
    const data = response.body().map(e => e.data());

    store.dispatch(
      setDialog({
        name: 'DialogRingoverCampaign',
        open: true,
        dialogProps: {
          maxWidth: 'md',
        },
        contentProps: {
          callsRingover: data,
          users,
          tab,
          selectedUsers: [currentUser]
        },
        actions: [
          {
            children: 'Envoyer la campagne',
            color: 'primary',
            onClick: (props, close) => {
              if (props.tab === 0) {
                const { selected } = props;
                if (!selected || Object.keys(selected).length === 0) {
                  return notifSystem
                    .error('Erreur', 'Aucun audio sélectionné');
                }
                else if (selected.isRecorded && !selected.name.length) {
                  return notifSystem
                    .error('Erreur', 'Nom de l\'audio manquant');
                }
                sendCampaignVoice(campaigns, selected);
              }
              else {
                const telephoneIds = props.selectedUsers
                  .map(e => parseInt(e.telephoneId.replace(/USER/g, ''), 10));

                if (!telephoneIds.length) {
                  return notifSystem
                    .error('Erreur', 'Aucun utilisateur sélectionné');
                }

                sendCampaignCall(campaigns, telephoneIds);

              }
              close();
            },
          }
        ]
      })
    );
    return data;
  }
  catch (error) {
    return notifSystem.error('Erreur', 'Une erreur est survenue');
  }
}
