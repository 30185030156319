import React from 'react';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import './utils/prototypes';

import company from '@lba-dev/package.local-globals/company';
import { ARTISAN } from '@lba-dev/package.local-globals/permissions';

import { logout } from './actions/auth';
import initCache from './cache';
import ws from './ws';
import theme from './theme';
import store from './store';
import api from './api';
import { initServices } from './services';
import { addUserSession } from './services/datadog';
import initEvents from './services/Events';
import './index.css';
import Dashboard from './views/Dashboard';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './utils/errorBoundary';

let token;
let timeout;

const onTokenChange = () => {
  const state = store.getState();
  if (state.auth.token !== token) {
    token = state.auth.token;
    api.header('Authorization', `Bearer ${token}`);
    if (token === undefined) {
      ws.logout();
      return;
    }
    const payload = jwt.decode(token);
    if (payload && payload.exp && payload.exp > Date.now() / 1000) {
      clearTimeout(timeout);
      ws.login({ token, payload });
      window.localStorage.setItem('token', token);
      timeout = setTimeout(() => store.dispatch(logout(ws)),
        (payload.exp * 1000) - Date.now()
      );
      if (payload.auth !== ARTISAN)
      {
        initCache(store, payload._id, ws, window.name === 'chat');
        addUserSession(payload);
        initEvents(payload);
      }
    } else {
      store.dispatch(logout(ws, payload));
    }
  }
};

store.subscribe(onTokenChange);
onTokenChange();
initServices();

document.title = company.name;

const App = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary>
            <Dashboard />
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default App;
