import api from '../api';
import notifSystem from '../notifSystem';
import { setDialog } from './dialog';
import Money from '../utils/Money';
import { checkInfosFacturation } from './verifications';
import { custom, objectDialog } from '../utils/DialogsCheck';
import store from '../store';
import { updateInter } from './inter';
import {
  P_ACCEPTED,
  P_REJECTED
} from '@lba-dev/package.local-globals/isValidStatus';
import { P_TER } from '@lba-dev/package.local-globals/propositionStatus';
import {
  FACTENV
} from '@lba-dev/package.local-globals/paymentMethods';

const updateProposition = (valid, interId, cb, motifCancel) => api
  .one('propositions', interId)
  .custom('updateProposition')
  .post({ valid, ...(motifCancel ? { motifCancel } : {}) })
  .then(() => {
    if (cb) {
      cb();
    }
    notifSystem.success('Message', 'La proposition a bien été mise à jour');
  })
  .catch(e =>
    notifSystem.error(
      e.name,
      e.message === 'Not Acceptable'
        ? 'Coordonnées de facturation incomplètes'
        : e.message
    )
  );

export async function checkProposition(
  valid,
  {
    announcedPrice = 0,
    announcedPriceMax = 0,
    proposition = {},
    id,
    flexPrice,
    ...inter
  },
  type,
  cb
) {
  const defaultText = objectDialog[custom]({
    hasTextField: true,
    warningMessage: 'VU PAR L\'ARTISAN',
    setDialog: setDialog,
    cb: ({ motifCancel }, close) => {
      updateProposition(valid, id, cb, motifCancel);
      close();
    },
  });
  if (proposition && valid !== proposition.isValid) {
    if (proposition.status === P_TER && type !== 'Refus') {
      if (valid && flexPrice &&
        !(proposition.finalPrice >= announcedPrice &&
        proposition.finalPrice <= announcedPriceMax)) {
        return notifSystem.error(
          'Veuillez modifier le prix',
          `Le prix proposé (${
            Money.toString(proposition.finalPrice, true)}) n'est pas ` +
            `dans la fourchette de prix (${
              Money.toString(announcedPrice, true)} - ${
              Money.toString(announcedPriceMax, true)})`
        );
      }
      if (
        valid && !flexPrice && announcedPrice &&
        proposition.finalPrice !== announcedPrice
      ) {
        return notifSystem.error(
          'Veuillez modifier le prix',
          `Le prix proposé (${
            Money.toString(proposition.finalPrice, true)}) est ` +
          `différent du prix final (${
            Money.toString(announcedPrice, true)})`
        );
      }
      if (
        (!inter.onSitePayment || proposition.paymentMethod === FACTENV) &&
        checkInfosFacturation(inter.billing).length
      ) {
        const errors = checkInfosFacturation(inter.billing);
        return notifSystem.error(
          'Coordonnées de facturation',
          `${errors.join(', ')} incomplets`
        );
      }
    }
    if (valid === P_REJECTED) {
      return store.dispatch(defaultText.callBack());
    } else if (proposition && proposition.status === P_TER) {
      await updateInter(inter._id, { proposition });
    }
    return updateProposition(valid, id, cb);
  }
}

export async function openPropositionDialog(inter, cb) {
  if (typeof inter === 'number') {
    inter = await api.interventions.get(inter)
      .then(res => res.body().data());
  }
  return store.dispatch(
    setDialog({
      name: 'DialogProposition',
      open: true,
      dialogProps: {
        title: 'Mise à jour du partenaire',
        maxWidth: 'lg',
      },
      contentProps: { inter },
      actions: [
        {
          children: 'Valider',
          onClick: (data, close) => {
            close();
            checkProposition(
              P_ACCEPTED,
              { ...inter, proposition: data.inter.proposition },
              'Accept',
              cb
            );
          },
          color: 'primary',
        },
        {
          children: 'Refuser',
          onClick: (p, close) => {
            close();
            checkProposition(P_REJECTED, inter, 'Refus', cb);
          },
          color: 'secondary',
        },
      ],
    })
  );
}
