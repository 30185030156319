import {
  AttachMoney,
  Build,
  Assessment,
  SmsRounded,
  List,
  Call,
  Quiz,
  Handyman,
  Hardware,
  AssistantPhotoRounded,
} from '@mui/icons-material';

import SmartToyIcon from '@mui/icons-material/SmartToy';

export default [
  {
    name: 'Statistique',
    link: '/statistiques/',
    icon: Assessment,
  },
  {
    name: 'Combos',
    icon: Handyman,
    childs: [
      {
        name: 'Produits',
        icon: Build,
        link: '/config/table/products',
      },
      {
        name: 'Combos',
        icon: Build,
        link: '/config/table/combos',
      },
      {
        name: 'Combos devis',
        icon: Build,
        link: '/config/combinations',
      },
      {
        name: 'Combos métiers (OLD)',
        icon: Build,
        link: '/config/configMetiersSteps',
      },
      {
        name: 'Combos métiers',
        icon: Build,
        link: '/config/configMetiersDevis/0',
      },
      {
        name: 'Combos métiers devis',
        icon: Build,
        link: '/config/configMetiersDevis/1',
      },
      {
        name: 'Questions / Réponses',
        icon: Quiz,
        link: '/config/table/questions',
      },
      {
        name: 'Matériel à vendre',
        icon: Hardware,
        link: '/config/table/materiels',
      },
      {
        name: 'Suggestions Réponses',
        icon: AssistantPhotoRounded,
        link: '/config/table/suggestionsReponse',
      },
    ],
  },
  {
    name: 'Config',
    icon: Build,
    link: '/config/general',
  },
  {
    name: 'Import Fournisseurs',
    icon: Build,
    link: '/config/fournisseursCSV',
  },
  {
    name: 'Gestion fournisseurs',
    icon: Build,
    link: '/config/fournisseursManager',
  },
  {
    name: 'Grand Comptes',
    icon: Build,
    childs: [
      {
        name: 'Liste',
        icon: List,
        link: '/config/table/grandcompte',
      },
      {
        name: 'Gestion',
        icon: List,
        link: '/config/grandCompte',
      },
      {
        name: 'Liste d\'interventions',
        icon: List,
        link: () => '/intervention/btob/btob',
      },
      {
        name: 'Produits GC',
        icon: Build,
        link: '/config/table/gcProducts',
      },
    ],
  },
  {
    name: 'Signalements',
    icon: Build,
    link: '/config/table/signalements',
  },
  {
    name: 'Raisons Archive SST',
    icon: Build,
    link: '/config/table/raisonsArchiveSST',
  },
  {
    name: 'Utilisateurs',
    icon: Build,
    link: '/config/users',
  },
  {
    name: 'Suggestions',
    icon: Build,
    link: '/config/table/suggestions',
  },
  {
    name: 'Annulations',
    icon: Build,
    link: '/config/table/annulations',
  },
  {
    name: 'Commissions',
    icon: AttachMoney,
    link: '/config/commissions',
  },
  {
    name: 'Gestion d\'Ip',
    icon: Build,
    link: '/config/ip',
  },
  {
    name: 'Raisons appels',
    icon: Build,
    link: '/config/callNotTransform',
  },
  {
    name: 'Suggestions SMS & MAIL',
    icon: Build,
    link: '/config/table/suggestionsSMS',
  },
  {
    name: 'Config liens',
    icon: Build,
    link: '/config/table/configLink',
  },
  {
    name: 'Sources blacklistés',
    icon: Build,
    link: '/config/blackListSource',
  },
  {
    name: 'Configuration de lignes',
    icon: Call,
    link: '/config/updateLignes',
  },
  {
    name: 'SMS',
    icon: SmsRounded,
    childs: [
      {
        name: 'Dashbord',
        icon: SmsRounded,
        link: '/config/sms',
      },
      {
        name: 'Mms reçus',
        icon: SmsRounded,
        link: '/mms',
      },
      {
        name: 'Sms Config',
        icon: Build,
        link: '/sms/edit',
      },
    ],
  },
  {
    name: 'Instructions ChatGPT',
    icon: SmartToyIcon,
    link: '/prompt',
  },
];
