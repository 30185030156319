import { AttachFile, InsertEmoticon, ManageSearch } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import TelSelect from './TelSelect';

const InputActions = ({
  name, classes, showEmojiPicker,
  selectedNumber, setSelectedNumber,
  dataObject, hasWhatsapp, handleFile,
  displaySuggestion, audioFile
}) => <Box display='flex' justifyContent='flex-end'>
  {name !== 'ticket' ? <TelSelect
    classes={classes}
    selectedNumber={selectedNumber}
    setSelectedNumber={setSelectedNumber}
    dataObject={dataObject}
  /> : ''}
  <IconButton
    color="primary"
    onClick={showEmojiPicker}
    size="small"
    children={<InsertEmoticon fontSize='small' />}
  />
  {name === 'ticket' || hasWhatsapp ? <>
    <input
      accept=".png, .jpg, .jpeg, .pdf"
      className={classes.uploadInput}
      id="upload-input"
      type="file"
      disabled={!!audioFile}
      onChange={handleFile}
    />
    <label htmlFor="upload-input">
      <IconButton
        size='small'
        component="span"
        disabled={!!audioFile}
        children={<AttachFile fontSize='small' />}
      />
    </label>
  </> : ''}
  {name === 'artisan' ? <IconButton
    size='small'
    onClick={displaySuggestion}
    children={<ManageSearch fontSize='small' />}
  /> : ''}
</Box>;

export default InputActions;
