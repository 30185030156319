/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialog, setDialogContentProp } from '../../../actions/dialog';
import CustomMaterialTable from '../../CustomMaterialTable';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import VoiceRecorder from '../../Utils/VoiceRecorder';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import AudioPlayer from '../../Calls/AudioPlayer';
import LoadingComponentWithButton from '../LoadingComponent';
import store from '../../../store';

const setCallsRingover = setDialogContentProp('callsRingover');
const setSelected = setDialogContentProp('selected');
const setAudio = setDialogContentProp('audio');
const setTab = setDialogContentProp('tab');
const setSelectedUsers = setDialogContentProp('selectedUsers');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      callsRingover,
      selected = {},
      audio = null,
      tab = 0,
      users = [],
      selectedUsers = [],
    } }
}) => ({
  callsRingover,
  selected,
  audio,
  tab,
  users,
  selectedUsers,
});

const mapDispatchToProps = {
  setCallsRingover,
  setSelected,
  setAudio,
  setTab,
  setSelectedUsers
};

const DialogRingoverCampaign = ({
  callsRingover = [],
  setSelected,
  selected = {},
  audio = null,
  setAudio,
  setTab,
  tab = 0,
  users = [],
  selectedUsers = [],
  setSelectedUsers,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [audioName, setAudioName] = useState('');
  const [idListener, setIdListener] = useState(null);
  const [audioToListen, setAudioToListen] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRowClick = (_, rowData) => {
    setSelected(selected.id === rowData.id ? {} : rowData);
  };

  const handleUsersRowClick = (_, rowData) =>
    setSelectedUsers(selectedUsers.some(e => e._id === rowData._id) ?
      selectedUsers.filter(e => e._id !== rowData._id) :
      [...selectedUsers, rowData]
    );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const onSelectedAudio = () => {
    setSelected(selected.isRecorded ? {} : {
      name: audioName,
      audio: audio,
      isRecorded: true,
    });
  };

  const addAudio = (base64, blob = null) => {
    setAudio(base64);
    setSelected({});
    const url = URL.createObjectURL(blob);
    let audio = document.getElementById('audioPlayerVoiceCampaign');

    if (!audio) {
      audio = document.createElement('audio');
      audio.id = 'audioPlayerVoiceCampaign';
      audio.controls = true;

      const recordingButton = document.querySelector('.audio-recorder');
      const parent = recordingButton.parentNode;
      parent.insertAdjacentElement('afterend', audio);
    }

    audio.src = url;

  };

  const listenAudio = (path, id) => {
    setLoading(true);
    setIdListener(id);
    setAudioToListen(null);
    api.ringover.custom('audio').get({ url: path })
      .then((response) => {
        const data = response.body().data();
        setAudioToListen(data);
      })
      .catch(() => {
        notifSystem
          .error('Erreur', 'Erreur lors de la récupération de l\'audio');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const deleteAudio = (id) => store.dispatch(
    setDialog({
      name: 'ConfirmDialog',
      open: true,
      dialogProps: {
        title: 'Etes-vous sûr de vouloir supprimer cet audio ?',
      },
      contentProps: {
        defaultText: {
          contentText: 'Cette action est irréversible !',
        }
      },
      hideClose: true,
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (_, close) => close()
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (_, close) => {
            api.ringover.custom('audio').delete({ id })
              .then(() => {
                notifSystem.success('Succès', 'Audio supprimé');
                close();
              })
              .catch(() => {
                notifSystem.error('Erreur', 'Erreur lors de la suppression');
                close();
              });
            close();
          }
        },
      ]
    })
  )

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={handleChangeTab}
      >
        <Tab
          title='Sons Ringover'
          label='Campagne Vocal'
          value={0}
        />
        <Tab
          title='Sons Personnels'
          label='Campagne Appel'
          value={1}
        >
        </Tab>
      </Tabs>
      {tab === 0 && (
        <Grid
          padding={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item>

                <VoiceRecorder
                  addAudio={(base64, blob) => addAudio(base64, blob)} />
              </Grid>
              <Grid item>
                <TextField
                  label="Nom de l'audio"
                  variant="outlined"
                  value={audioName || ''}
                  onChange={(e) => setAudioName(e.target.value)}
                />
              </Grid>
              {
                audio &&
        <>
          <Button onClick={onSelectedAudio}
            variant="contained"
            color="secondary"
            style={{ marginLeft: 15, height: 40 }}
          >
            {selected.isRecorded ?
              'Désélectionner cet audio' :
              'Sélectionner cet audio'
            }
          </Button>
        </>
              }
            </Grid>
          </Grid>
          <CustomMaterialTable
            columns={[
              { title: 'Nom',
                field: 'name',
                render: ({ name }) => <Typography>{name}</Typography>
              },
              {
                title: 'Audio',
                field: 'path',
                render: rowData => (
                  <>
                    <LoadingComponentWithButton
                      onClick={() => listenAudio(rowData.path, rowData.id)}
                      style={{ marginLeft: 15, height: 40, width: 150 }}
                      variant="contained"
                      color="primary"
                      loading={loading && rowData.id === idListener}
                      textButton={'Ecouter'}
                      componentToDisplay={
                        rowData.id === idListener && audioToListen ? (
                          <AudioPlayer
                            autoPlay={true}
                            url={`data:audio/mp3;base64,${audioToListen}`}
                          />
                        ) : null
                      }
                    />
                    <Button
                      onClick={() => deleteAudio(rowData.id)}
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: 15, height: 40 }}
                    >
              Supprimer
                    </Button>
                  </>
                )
              }
            ]}
            data={callsRingover}
            title="Sons Ringover"
            options={{
              search: false,
              paging: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 25, 50, 100],
              selection: true,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              selectionProps: rowData => ({
                checked: selected.id === rowData.id,
              }),
            }}
            onChangeRowsPerPage={(pageSize) => setRowsPerPage(pageSize)}
            onSelectionChange={(_, rowData) => {
              handleRowClick(_, rowData);
            }}
          />
        </Grid>
      )}
      {tab === 1 && (
        <Grid
          padding={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <CustomMaterialTable
            columns={[
              { title: 'Login',
                field: 'login',
                render: ({ login }) => <Typography>{login}</Typography>
              },
              { title: 'TelephoneId',
                field: 'telephoneId',
                render: ({ telephoneId }) =>
                  <Typography>{telephoneId}</Typography>
              }
            ]}
            data={users}
            title="Utilisateurs"
            options={{
              search: true,
              paging: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 25, 50, 100],
              selection: true,
              showSelectAllCheckbox: true,
              selectionProps: rowData => ({
                checked: selectedUsers.some(e => e._id === rowData._id),
              }),
            }}
            onChangeRowsPerPage={(pageSize) => setRowsPerPage(pageSize)}
            onSelectionChange={(_, rowData) => {
              handleUsersRowClick(_, rowData);
            }}
          />

        </Grid>
      )}
    </>
  );
};

export default connect(mapStateToProps,
  mapDispatchToProps)(DialogRingoverCampaign);
