import restful, { fetchBackend } from 'restful.js';
import collections from './constants/collections';
import store from './store';
import { logout } from './actions/auth';
import ws from './ws';

const api = restful(`${window.location.origin}/api`, fetchBackend(fetch));
api.artisans = api.all(collections.artisans);
api.prosp = api.all(collections.prosp);
api.login = api.custom(collections.login);
api.combos = api.all(collections.combos);
api.companies = api.all(collections.companies);
api.devis = api.all(collections.devis);
api.interventions = api.all(collections.interventions);
api.mail = api.all(collections.mail);
api.signals = api.all(collections.signals);
api.confCand = api.all(collections.confCand);
api.confProsp = api.all(collections.confProsp);
api.tryAddInters = api.all(collections.tryAddInters);
api.siret = api.all(collections.siret);
api.signalements = api.all(collections.signalements);
api.sms = api.all(collections.sms);
api.counter = api.all(collections.counter);
api.users = api.all(collections.users);
api.cities = api.all(collections.cities);
api.products = api.all(collections.products);
api.attendance = api.all(collections.attendance);
api.history = api.all(collections.history);
api.arcep = api.all(collections.arcep);
api.dropbox = api.all(collections.dropbox);
api.absences = api.all(collections.absences);
api.btobs = api.all(collections.btobs);
api.appels = api.all(collections.appels);
api.recouvrements = api.all(collections.recouvrements);
api.paiements = api.all(collections.paiements);
api.stats = api.all(collections.stats);
api.equipes = api.all(collections.equipes);
api.reglements = api.all(collections.reglements);
api.encaissements = api.all(collections.encaissements);
api.suggestions = api.all(collections.suggestions);
api.contrat = api.all(collections.contrat);
api.notifications = api.all(collections.notifications);
api.records = api.all(collections.records);
api.transformCall = api.all(collections.transformCall);
api.services = api.all(collections.services);
api.billings = api.all(collections.billings);
api.configFournisseurs = api.all(collections.configFournisseurs);
api.combinations = api.all(collections.combinations);
api.savInterventions = api.all(collections.savInterventions);
api.annulations = api.all(collections.annulations);
api.fournisseures = api.all(collections.fournisseures);
api.points = api.all(collections.points);
api.grandCompte = api.all(collections.grandCompte);
api.candidats = api.all(collections.candidats);
api.config = api.all(collections.config);
api.authorizedIps = api.all(collections.authorizedIps);
api.publicites = api.all(collections.publicites);
api.map = api.all(collections.map);
api.invoices = api.all(collections.invoices);
api.artisanConnection = api.all(collections.artisanConnection);
api.demandes = api.all(collections.demandes);
api.campagnes = api.all(collections.campagnes);
api.raisonsArchiveSst = api.all(collections.raisonsArchiveSst);
api.usersConnexions = api.all(collections.usersConnexions);
api.paymentsAnnexe = api.all(collections.paymentsAnnexe);
api.transactions = api.all(collections.transactions);
api.emails = api.all(collections.emails);
api.achats = api.all(collections.achats);
api.elasticsearch = api.all(collections.elasticsearch);
api.callLog = api.all(collections.callLog);
api.mailReceived = api.all(collections.mailReceived);
api.suppliesInvoices = api.all(collections.suppliesInvoices);
api.metiersSteps = api.all(collections.metiersSteps);
api.propositions = api.all(collections.propositions);
api.metiersDevis = api.all(collections.metiersDevis);
api.questions = api.all(collections.questions);
api.materiels = api.all(collections.materiels);
api.suggestionsReponses = api.all(collections.suggestionsReponses);
api.count = api.all(collections.count);
api.artisanUpdate = api.all(collections.artisanUpdate);
api.tickets = api.all(collections.tickets);
api.promptConfig = api.all(collections.promptConfig);
api.blackListSource = api.all(collections.blackListSource);
api.ringover = api.all(collections.ringover);


api.on('response', (t) => {
  if (t?.response?.statusCode === 401) {
    store.dispatch(logout(ws, undefined, false));
  }
});

api.on('error', (t) => {
  if (t?.response?.statusCode === 401) {
    store.dispatch(logout(ws, undefined, false));
  }
  // responsesArr.map(e => clearTimeout(e.timeoutId));
  // responsesArr.pop();
  // store.dispatch(setLoading({ open: false }));
});

api.subscribe = (store) => {
  store.subscribe(() => {
    const state = store.getState();
    api.header('Authorization', state.token);
  });
};

export default api;
