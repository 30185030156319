import React, { PureComponent } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MoreVert, Delete, AddCircle, FindInPage } from '@mui/icons-material';
import green from '@mui/material/colors/green';

import Money from '../../../utils/Money';
import MoneyInput from '../../CustomInputs/MoneyInput';
import CustomTextField from '../../CustomInputs/CustomTextField';

import ProductQt from '../../Products/ProductRow/ProductQt';
import Fournisseurs from './Fournisseurs';

import ToogleDesc from '../../Products/ProductRow/ToogleDesc';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { withStyles } from 'tss-react/mui';
import { fromJS, List } from 'immutable';
import { data, A_DEM } from '@lba-dev/package.local-globals/statusAchat';
import { connect } from 'react-redux';
import Attachments from '../../Utils/Attachments';
import { addFournitureFile, sendSMSFourniture } from '../../../actions/inter';
import SmsIcon from '@mui/icons-material/Sms';

import { permit } from '@lba-dev/package.local-globals/restrictions';
import notifSystem from '../../../notifSystem';

const mapStateToProps = ({
  users, userId
}) => ({
  users,
  userId,
  user: users.find(e => e._id === userId)
});

const styles = {
  table: {
    border: '1px solid #e0e0e0',
    backgroundColor: 'rgb(245, 245, 245)',
  },
};

const paths = {
  qt: ['qt'],
  ut: ['ut'],
  bl: ['bl'],
  suppliesName: ['suppliesName'],
  suppliesDate: ['suppliesDate'],
  pro: ['pro'],
  montant: ['montant'],
  warranty: ['warranty'],
  validate: ['validate'],
  attachment: ['attachment'],
  attachmentArtisan: ['attachmentArtisan']
};

class SupplieRow extends PureComponent {
  constructor() {
    super();
    this.state = { open: false, menuOpen: false, menuAnchor: null };
    this.toogle = this.toogle.bind(this);
    this.setData = this.setData.bind(this);
    this.remove = this.remove.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.validate = this.validate.bind(this);
  }

  toogle() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  openMenu(e) {
    this.setState({
      menuOpen: true,
      menuAnchor: e.target
    });
  }

  validate() {
    const { setData, elemPos, supplie } = this.props;
    setData(elemPos, paths.validate, !supplie.getIn(paths.validate));
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  moveUp() {
    this.props.swap(this.props.elemPos - 1);
    this.closeMenu();
  }

  moveDown() {
    this.props.swap(this.props.elemPos);
    this.closeMenu();
  }

  remove() {
    this.props.remove(this.props.elemPos);
  }

  setData(path, v) {
    const { setData, elemPos } = this.props;
    setData(elemPos, path, v);
  }

  checkMontant(supplie) {
    return Money.toString(
      supplie.get('montant', supplie.get('ut', 0) * supplie.get('qt')),
      true
    );
  }

  delWarranty(index) {
    const { supplie } = this.props;
    this.setData(
      paths.warranty,
      supplie.get('warranty').filter((_, i) => i !== index)
    );
  }

  addWarranty() {
    const { supplie } = this.props;
    let tmp = supplie.get('warranty', new List()).toJS();
    tmp.push({ status: A_DEM, price: 0, billNumber: '' });
    this.setData(
      paths.warranty,
      supplie.get('warranty', new List()).push(
        fromJS({ status: A_DEM, price: 0, billNumber: '' })
      )
    );
  }

  updateWarranty(value, index, field) {
    const { supplie } = this.props;
    this.setData(
      paths.warranty,
      supplie.setIn(['warranty', index, field], value).get('warranty')
    );
  }

  openAttachament = () => {
    const supplie = this.props.supplie;
    window.open(supplie.get('attachment'));
  }

  addAttchment = (file) => {
    const interId = this.props.interId;
    if (interId) {
      addFournitureFile(file, interId)
        .then((e = {}) => {
          this.setData(paths.attachment, e.link);
          this.setData(paths.attachmentArtisan, e.attachmentArtisan);
        });
    }
  }

  sendSMSBonArtisan = (supplie) => {
    const interId = this.props.interId;
    const artisan = this.props.artisan;
    if (supplie && artisan) {
      sendSMSFourniture(supplie, artisan.id, interId);
    }
  }

  removeFile = () => {
    this.setData(paths.attachment, '');
    this.closeMenu();
    notifSystem.success('Fichier supprimé',
      'Veuillez enregistrer l\'inter avant de quitter');
  }

  setMontant = (path, v) => {
    const { supplie, elemPos, setData } = this.props;
    setData(elemPos, path, v, () => {
      if (!isNaN(v)) {
        setData(
          elemPos,
          paths.montant,
          (path[0] === 'ut' ? v : supplie.get('ut')) *
          (path[0] === 'qt' ? v : supplie.get('qt', 0))
        );
      }
    });
  }

  render() {
    const {
      supplie, elemPos, last, classes, user, interId
    } = this.props;
    const { menuOpen, menuAnchor, open } = this.state;
    const login = `${supplie.get('suppliesDate')}`;
    const canDeleteFile = user && permit(user, { key: 'suppliesInvoiceEdit' });
    return (
      <React.Fragment>
        <TableRow
          className={'supplies'}
          style={{
            backgroundColor: supplie.getIn(paths.validate) ? green[500] : '',
          }}
        >
          <ToogleDesc open={this.state.open} toogle={this.toogle} />
          <TableCell padding="checkbox" align={'right'}>
            <CustomTextField
              fullWidth
              disabled
              value={login}
              path={[]}
              setData={this.setData}
            />
          </TableCell>
          <TableCell padding="checkbox" align={'right'}>
            <CustomTextField
              type="number"
              fullWidth
              value={supplie.get('bl')}
              path={paths.bl}
              setData={this.setData}
              name={'bl'}
            />
          </TableCell>
          <TableCell padding="checkbox" align={'right'}>
            <CustomTextField
              fullWidth
              value={supplie.get('suppliesName', 'Fourniture')}
              path={paths.suppliesName}
              setData={this.setData}
              name={'suppliesName'}
            />
          </TableCell>
          <ProductQt
            qt={supplie.get('qt')}
            path={paths.qt}
            setData={this.setMontant}
          />
          <TableCell padding="checkbox" align={'right'}>
            <MoneyInput
              canBeNegative={true}
              defaultValue={supplie.get('ut')}
              path={paths.ut}
              setData={this.setMontant}
              name={'ut'}
            />
          </TableCell>
          <TableCell align={'right'}>
            <b>{this.checkMontant(supplie)}</b>
          </TableCell>
          <TableCell>
            <Fournisseurs
              pro={supplie.get('pro', 'EDISON')}
              setData={this.setData}
            />
          </TableCell>
          <TableCell padding="checkbox" align={'right'}>
            {interId && !supplie.get('attachment') ? (
              <Attachments
                hideChips={true}
                onAdd={this.addAttchment}
                inputProps={{
                  accept: 'application/pdf',
                }}
                name={`fourniture-${elemPos}`}
              />
            ) : (
              <IconButton
                onClick={this.openAttachament}
                disabled={!supplie.get('attachment')}
                size="large"
              >
                <FindInPage />
              </IconButton>
            )}
          </TableCell>
          <TableCell padding="checkbox" align={'right'}>
            <IconButton
              aria-label="sms"
              disabled={!supplie.get('attachmentArtisan')}
              onClick={() => this.sendSMSBonArtisan(supplie)}
            >
              <SmsIcon />
            </IconButton>
          </TableCell>
          <TableCell padding="checkbox" align={'right'}>
            <IconButton onClick={this.openMenu} size="large">
              <MoreVert />
            </IconButton>
            <Menu
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              anchorEl={menuAnchor}
              open={menuOpen}
              onClose={this.closeMenu}
            >
              <MenuItem onClick={this.moveUp} disabled={elemPos === 0}>
                Monter
              </MenuItem>
              <MenuItem disabled={last} onClick={this.moveDown}>
                Descendre
              </MenuItem>
              {canDeleteFile && supplie.get('attachment') ? (
                <MenuItem onClick={this.removeFile}>
                  Supprimer le fichier
                </MenuItem>
              ) : null}
              <MenuItem onClick={this.remove}>Supprimer</MenuItem>
              <MenuItem onClick={this.validate}>
                {supplie.get('validate', false) ? 'Dévalider' : 'Valider'}
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        {open && (
          <TableRow className={'supplies'}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {[
                        'Fournisseurs',
                        'N° Facture',
                        'Montant avoir attendu',
                        'Status',
                        'Action',
                      ].map((title) => (
                        <TableCell
                          key={title}
                          align="center"
                          children={title}
                        />
                      ))}
                      <TableCell key={'add-line'} align="center">
                        <IconButton
                          children={<AddCircle />}
                          variant="outlined"
                          onClick={() => this.addWarranty()}
                          size="large"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {supplie.get('warranty', new List()).size
                      ? supplie.get('warranty').map((e, i) => (
                        <TableRow key={i}>
                          <TableCell
                            align="center"
                            children={supplie.get('pro', 'EDISON')}
                          />
                          <TableCell align="center">
                            <CustomTextField
                              value={e.get('billNumber', '')}
                              setData={(p, v) =>
                                this.updateWarranty(v, i, 'billNumber')
                              }
                              disabled={e.get('status') !== A_DEM}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <MoneyInput
                              defaultValue={e.get('price', 0)}
                              value={e.get('price', 0)}
                              disabled={e.get('status') !== A_DEM}
                              setData={(p, v) =>
                                this.updateWarranty(v, i, 'price')
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {
                              data.find(
                                (s) => s._id === e.get('status', A_DEM)
                              )?.name
                            }
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              variant="contained"
                              size="small"
                              children={<Delete />}
                              color="secondary"
                              onClick={() => this.delWarranty(i)}
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      ))
                      : ''}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(withStyles(SupplieRow, styles));
