import {
  Business,
  Event,
  List,
  Money,
  Assignment,
  LocalAtm,
  Apartment
} from '@mui/icons-material';

import { todayToString } from '../../../utils/date';
import {
  permit
} from '@lba-dev/package.local-globals/restrictions';


export default [
  {
    name: 'Liste Interventions',
    icon: List,
    link: (user) => permit(user, { key: 'interDevisListAccess' }) ?
      '/intervention/list' :
      `/intervention/list/userInters/login-ajout=${user.login}=${user._id}`
  },
  {
    name: 'Liste Devis',
    icon: List,
    link: (user) => permit(user, { key: 'interDevisListAccess' }) ?
      '/devis/list' :
      `/devis/list/userDevis/login-ajout=${user.login}=${user._id}`

  },
  {
    name: 'Facture en attente',
    icon: List,
    link: '/intervention/list/factatt',
  },
  {
    name: 'Envois à 50%',
    icon: Money,
    count: true,
    link: () => '/intervention/list/expensivePercent',
  },
  {
    name: 'Intervention +2K',
    icon: LocalAtm,
    count: true,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/intervention/list/intChef'
        : `/intervention/list/intChef_${e._id}`,
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Assignment,
    childs: [
      {
        name: 'En attente (général)',
        count: false,
        icon: List,
        link: () => '/intervention/list/propositionAv',
      },
      {
        name: 'En attente',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionAv/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Validées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionVal/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionRef/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: () => 'Mes Interventions',
    icon: Event,
    childs: [
      {
        name: todayToString(new Date()),
        bold: true,
        count: true,
        icon: List,
        link: (e) => `/intervention/list/ajd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Envoyés',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/envAjd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A programmer',
        count: true,
        icon: List,
        link: (e) => `/intervention/list/aprog/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En cours',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/ecours/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier',
        icon: List,
        countMatch: 'avr',
        count: true,
        link: (e) =>
          permit(e)
            ? `/intervention/list/avrMaj/login-ajout=${e.login}=${e._id}`
            : `/intervention/list/avr/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Vérifiées',
        icon: List,
        count: true,
        link: (e) => `/intervention/list/vrf/login-verif=${e.login}=${e._id}`,
      },
      {
        name: 'Annulées',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/annInter/login-cancel=${e.login}=${e._id}`,
      },
      {
        name: 'Devis à faire',
        icon: List,
        count: true,
        link: (e) =>
          `/devis/list/fromSignalement/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier jaune (général)',
        icon: List,
        count: true,
        link: '/intervention/list/avrfJaune',
      },
      {
        name: 'A vérifier jaune',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/avrfJaune/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Aux contentieux',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/recouvrementlist/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Suivie prioritaire',
        icon: List,
        count: true,
        countMatch: 'suiviPCount',
        link: (e) =>
          `/intervention/list/suiviP/priorityFollow=${e.login}=${e._id}`,
      },
      {
        name: 'Suivie prioritaire supprimée',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/suiviPD/priorityFollowDeleted=${
            e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Mes Devis',
    icon: Business,
    childs: [
      {
        name: 'Tous les Devis',
        icon: List,
        link: (user) => permit(user, { key: 'interDevisListAccess' }) ?
          '/devis/list' :
          `/devis/list/userDevis/login-ajout=${user.login}=${user._id}`
      },
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        count: true,
        countMatch: 'counAjd',
        link: (e) => `/devis/list/ajd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A envoyer',
        icon: List,
        count: true,
        link: (e) => `/devis/list/env/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En attente',
        icon: List,
        count: true,
        link: (e) => `/devis/list/att/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Acceptés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/acc/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/refu/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: () => 'Interventions GrandCompte',
    icon: Apartment,
    childs: [
      {
        name: 'A programmer',
        count: true,
        icon: List,
        link: (e) =>
          permit(e, { key: 'gcInterFilters' })
            ? '/intervention/list/aproggc'
            : `/intervention/list/aproggc_${e._id}`,
      },
      {
        name: 'Envoyés',
        icon: List,
        count: true,
        link: (e) =>
          permit(e, { key: 'gcInterFilters' })
            ? '/intervention/list/envgc'
            : `/intervention/list/envgc_${e._id}`,
      },
      {
        name: 'A vérifier',
        icon: List,
        count: true,
        link: (e) =>
          permit(e, { key: 'gcInterFilters' })
            ? '/intervention/list/avrgc'
            : `/intervention/list/avrgc_${e._id}`,
      },
      {
        name: 'Vérifier',
        icon: List,
        count: true,
        link: (e) =>
          permit(e, { key: 'gcInterFilters' })
            ? '/intervention/list/vrfgc'
            : `/intervention/list/vrfgc_${e._id}`,
      },
    ],
  },
];
