import api from '../api';
import {
  LOGIN,
  LOGOUT
} from '../constants/ActionTypes';
import notifSystem from '../notifSystem';
import { stopSessionReplayRecording } from '../services/datadog';
import store from '../store';
import { closeDialog } from './dialog';

export function login(token, isRemotely) {
  return {
    type: LOGIN,
    token,
    decodedToken: JSON.parse(atob(token.split('.')[1])),
    isRemotely,
  };
}

export function logout(ws, user, withLogout = true) {
  store.dispatch(closeDialog());
  try {
    if (withLogout) {
      console.log('logout');
      api.users.custom('logout').post();
    }
    Object.entries(localStorage).forEach(([key]) =>
      !['firstCall'].includes(key) && localStorage.removeItem(key)
    );
    ws.logout(user);
    window.dispatchEvent(new Event('storage'));
    stopSessionReplayRecording();
    return {
      type: LOGOUT
    };
  } catch (e) {
    notifSystem.error(
      'Error Déconnexion'
    );
  }
  Object.entries(localStorage).forEach(([key]) =>
    !['firstCall'].includes(key) && localStorage.removeItem(key)
  );
  ws.logout(user);
  window.dispatchEvent(new Event('storage'));
  stopSessionReplayRecording();
  return {
    type: LOGOUT
  };
}
