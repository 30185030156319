import { combineReducers } from 'redux';

/* Reducers */
import auth from './auth';
import users from './users';
import combos from './combos';
import products from './products';
import counter from './counter';
import userId from './userId';
import annulations from './annulations';
import equipes from './equipes';
import notifications from './notifications';
import etatReseau from './etatReseau';
import points from './points';
import btobs from './btobs';
import grandCompte from './grandCompte';
import config from './config';
import dialog from './dialog';
import signals from './signals';
import confCand from './confCand';
import confProsp from './confProsp';
import raisonsArchiveSst from './raisonsArchiveSst';
import fournisseurs from './fournisseurs';
import connect from './connect';
import loading from './loading';
import metiersSteps from './metiersSteps';
import metiersDevis from './metiersDevis';
import questions from './questions';
import count from './count';
import chatPanel from './chatPanel';

const rootReducer = combineReducers({
  auth,
  combos,
  products,
  users,
  counter,
  userId,
  equipes,
  notifications,
  etatReseau,
  annulations,
  points,
  btobs,
  grandCompte,
  config,
  fournisseurs,
  dialog,
  signals,
  confCand,
  confProsp,
  raisonsArchiveSst,
  connect,
  loading,
  metiersSteps,
  metiersDevis,
  questions,
  chatPanel,
  count,
});

export default rootReducer;
