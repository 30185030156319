import React, { Component } from 'react';

import { moment } from '@lba-dev/package.local-globals/moment';
import { fr } from 'date-fns/locale';

import { DayPicker } from 'react-day-picker';
import TextField from '@mui/material/TextField';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import 'react-day-picker/dist/style.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.startDate,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      from: nextProps.startDate
    });
  }


  showDatePicker = (value) => {
    this.setState({ showPicker: value });
  }


  handleSelect = (rangeOrDate) => {
    const { handleRange, mode } = this.props;
    let update = { }
    if (mode === 'range') {
      update = {
        from: rangeOrDate?.from || null,
        to: rangeOrDate?.to || null
      };
    } else {
      update = { from: rangeOrDate };
    }
    this.setState(update, () => {
      if (mode === 'range' && handleRange) {
        handleRange(update);
      }
    });
  }

  handleFromDayChange = (from) => {
    this.setState({ from, showPicker: false });
    if (this.props.onChange) {
      this.props.onChange(
        moment(from),
        this.props.stateName,
      );
    }
  }

  handleFromKeyPress = (e) => {
    if (e.key === 'Enter'){
      this.props.onChange(moment(this.state.from), this.props.stateName);
    }
  }

  handleClickAway = () => {
    this.showDatePicker(false);
  }

  render() {
    const { from, to, showPicker } = this.state;
    const {
      label, before, disabled,
      beforeDate,
      disabledDays,
      mode = 'single',
      dayPickerProps = {},
      id,
      withInput,
      ...props
    } = this.props;
    const fromInputProps = {
      id: id ? id : label,
      name: 'from',
      label: label,
      onFocus: () => this.showDatePicker(true),
      onKeyPress: this.handleFromKeyPress,
      zindex: 1000,
      disabled: disabled || false,
      readOnly: disabled || false
    };
    const Picker = <DayPicker
      mode={mode}
      locale={fr}
      showOutsideDays
      selected={from && to ? { from, to } : { from }}
      disabled={
        disabledDays ||
        (before ? { } : { before: beforeDate || new Date() })
      }
      displayMonth={from}
      day={from}
      fixedWeeks
      onSelect={this.handleSelect}
      numberOfMonths={1}
      onDayClick={this.handleFromDayChange}
      modifiersStyles={{
        selected: {
          backgroundColor: '#4A90E2',
          color: '#F0F8FF',
        },
      }}
      {...dayPickerProps}
      {...props}
    />;
    return label || withInput ?
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div id="overlay-wrapper">
          <TextField
            {...fromInputProps}
            fullWidth
            inputProps={{
              readOnly: true
            }}
            value={from ? `${moment(from).format('DD/MM/YYYY')}` : ''}
          />
          {showPicker ?
            <div id="datepickeroverlay">{Picker}</div>
            : ''}
        </div>
      </ClickAwayListener> : Picker;
  }
}

export default DatePicker;
