/* eslint-disable max-lines */
import React from 'react';
import { S_PART } from '@lba-dev/package.local-globals/services';
import { I_APR, I_ENC, I_AVR } from '@lba-dev/package.local-globals/iStatus';
import { R_FOR, R_POR } from '@lba-dev/package.local-globals/remStatus';
import { moment } from '@lba-dev/package.local-globals/moment';
import {
  C_COMPTA,
  C_GLOBAL,
} from '@lba-dev/package.local-globals/commentTypes';
import {
  AS,
  PL,
  EL,
  CL,
  data as categorieData,
} from '@lba-dev/package.local-globals/categories';
import {
  D_MAR,
  D_NOT,
  D_PRE,
} from '@lba-dev/package.local-globals/deStatus';
import { AUTRE } from '@lba-dev/package.local-globals/attachmentsCategorie';
import { S_INE } from '@lba-dev/package.local-globals/typesConstants';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import smsUpdateInter from '@lba-dev/package.local-globals/smsUpdateInter';
import api from '../api';
import { TextDialog, DialogList } from '../components/Dialogs';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import {
  generateOfflineSmsInter,
  genInterestSmsInter,
} from '../constants/sms/smsInter';
import { generateSmsInter } from '@lba-dev/package.local-globals/smsConstants';
import notifSystem from '../notifSystem';
import CancelDialog from '../components/Dialogs/CancelDialog';
import PaymentDialog from '../components/Dialogs/PaymentDialog';
import SignalSST from '../components/Dialogs/SignalSST';
import { addSignalement, getSimpleSignalements } from './signalements';
import DialogInfoCompta from '../components/Dialogs/DialogInfoCompta';
import DialogEnvoiInter from '../components/Dialogs/DialogEnvoiInter';
import DialogSupport from '../components/Dialogs/DialogSupport';
import PriseDeCoteDialog
  from '../components/Dialogs/PriseDeCote/PriseDeCoteDialog';
import ReminderHistoryDialog
  from '../components/Dialogs/Relance/ReminderHistoryDialog';
import ReminderDialog from '../components/Dialogs/Relance/ReminderDialog';
import { reglementDialog } from './compta';
import { setDialog, setDialogContentProp, closeDialog } from './dialog';
import { uploadFile } from './dropbox';
import {
  DESC_REGEX
} from '@lba-dev/package.local-globals/regexps';
import { call } from '../utils/call';
import { cacheDisabled } from '../utils/function';
import { fromJS } from 'immutable';
import { sendVerification } from './verifications';
import aDemarcheAlerts from '../utils/aDemarcheAlerts';
import { Call } from '@mui/icons-material';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';
import store from '../store';
import { DINTER } from '@lba-dev/package.local-globals/docTypes';
import { getSpecificKeys } from '../components/Utils/manageData';
import {
  doubleCheckVerif,
  hidePriceArtisan,
} from '@lba-dev/package.local-globals/sendCheck';
import {
  data as progressData
} from '@lba-dev/package.local-globals/progressStatusSupInvoice';
import { CB, VIREM } from '@lba-dev/package.local-globals/paymentMethods';
import { S_SIG } from '@lba-dev/package.local-globals/signatureStatus';
import { M_REG } from '@lba-dev/package.local-globals/comptaCommentes';
import paths from '../components/InfoIntervention/paths';
import CustomSwitch from '../components/CustomInputs/CustomSwitch';
import { L_CLI } from '@lba-dev/package.local-globals/recStatus';
import { R_RES, R_PER } from '@lba-dev/package.local-globals/rStatus';
import { openChatPanel } from './chatPanel';
import { P_OS } from '@lba-dev/package.local-globals/nPages';
import { getByKey } from '@lba-dev/package.local-globals/aSecurity';

const MIN_PRICE = 3250;
const msgPrice = 'Veuillez ajouter le prix final (Min: 32.50 HT)';

export function cancel(props, inter, cb) {
  function cancelCallback(obj, send) {
    props.setDialog(null, false, '', null);
    if (obj) {
      const { message, typeCancel } = obj;
      api
        .one('interventions', inter.id)
        .custom('cancel')
        .post({ cancelMsg: message, send, typeCancel })
        .then(() => {
          notifSystem.success('Opération réussie', 'Intervention annulée');
        })
        .catch((e) => notifSystem.error(e.name, e.message));
      if (cb) {
        cb();
      }
    }
  }

  props.setDialog(
    CancelDialog,
    true,
    { artisanExist: inter.artisan },
    (obj, send) => cancelCallback(obj, send)
  );
}

export function priorityFollow(props, inter) {
  const cond =
    props.selected &&
    props.selected.priorityFollow &&
    props.selected.priorityFollow.includes(props.userId);
  const support = props.users.filter(
    (e) => e.act && e.supportIds && e.supportIds.includes(inter.login.ajout)
  );
  return store.dispatch(
    setDialog({
      name: 'ConfirmDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title: `Etes-vous sûr de vouloir ${cond ? 'supprimer' : 'ajouter'
        } un suivi prioritaire ?`,
      },
      actions: [
        {
          children: 'annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (data, close) => {
            api
              .one(
                'interventions',
                `${inter.id}/${cond ? 'deletePriority' : 'priority'}`
              )
              .post({ support: support.map((e) => e._id) })
              .then(() => {
                close();
                notifSystem.success(
                  'Opération réussie',
                  'L\'intervention est passée en suivi prioritaire'
                );
              })
              .catch(() =>
                notifSystem.error(
                  'Erreur', 'L\'action n\'a pas pu être réalisée')
              );
          },
        },
      ],
    })
  );
}

export function sendSmsBackArtisan(
  setDialog,
  { artisanId, message, interId, routeId },
  cb = (e) => e
) {
  setDialog(null, false, '', null);
  if (message !== null && artisanId) {
    api.artisans
      .custom('sendTokenBack')
      .post({
        artisanId,
        message,
        interId,
        routeId,
      })
      .then(() => {
        cb();
        notifSystem.success('Opération réussie', 'SMS envoyé');
      })
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e.message)
      );
  }
}

export function sendDemandeUpdate() {
  api.artisans
    .custom('sendDemandeUpdate')
    .post()
    .then(() => notifSystem.success('Opération réussie', 'SMS envoyés'))
    .catch((e) =>
      notifSystem.error(e.name, e.response ? e.response.data : e.message)
    );
}

export function sendUploadSms(
  { setDialog: oldSetDialog, selected, user },
  inter
) {
  const { sms } = smsUpdateInter({
    client: inter.client,
    id: inter.id,
    artisan: inter.currentArtisan || {},
    user,
  });
  if (inter.nbSMSMaj > 2) {
    store.dispatch(
      setDialog({
        name: 'VerficationProp',
        open: true,
        dialogProps: {
          title: '⚠️  Attention  ⚠️',
          middleAll: true,
          fullScreenOn: false,
        },
        contentProps: {
          message:
            'L\'artisan a déjà reçu ce sms plusieurs fois, ' +
            'veuillez le contacter par téléphone pour la mise à jour.',
        },
      })
    );
    return;
  }
  return oldSetDialog(IphoneDialog, true, sms, (data) =>
    sendSmsBackArtisan(
      oldSetDialog,
      {
        artisanId: selected.artisan,
        interId: inter.id,
        routeId: P_OS,
        message: data,
      },
      () => {
        api.interventions.patch(inter._id, {
          nbSMSMaj: (inter.nbSMSMaj || 0) + 1,
        });
      }
    )
  );
}

export const verif = ({ id, ...body }) =>
  new Promise((res, rej) =>
    api
      .one('interventions', id)
      .custom('verif')
      .post(body)
      .then(() =>
        res(notifSystem.success('Opération réussie', 'Intervention verifiée'))
      )
      .catch((e) => rej(notifSystem.error(e.name, e.message)))
  );

export const envoy = ({
  description,
  choice,
  tel,
  zipCode,
  name,
  firstname,
  source,
  sChoice,
  interId,
  isDevis,
  email,
  voie,
  ville,
  ...data
}) =>
  api.demandes
    .post({
      choix: choice,
      prenom: firstname,
      nom: name,
      tel: tel,
      sousChoix: sChoice,
      source: source,
      commentaire: (description || '').replace(/’/g, '\''),
      email: email,
      cp: zipCode,
      voie: (voie || ''),
      ville,
      ...data,
    })
    .then((e) => {
      if (interId) {
        api[isDevis ? 'devis' : 'interventions'].patch(interId, {
          demande: {
            id: e.body().data()._id,
            source: e.body().data().source,
          },
        });
      }
    });

export const createDemandeFromInter = (
  merge,
  { navigate, user },
  isDevis = true
) =>
  envoy({
    civilite: merge.getIn(paths.civility, ''),
    name: merge.getIn(paths.name, ''),
    firstname: merge.getIn(paths.firstname, ''),
    tel: merge.getIn(paths.tel1, ''),
    email: merge.getIn(paths.email1, ''),
    voie: `${merge.getIn(paths.number, '')} ${merge.getIn(paths.road, '')}`,
    zipCode: merge.getIn(paths.zipcode, ''),
    ville: merge.getIn(paths.city, ''),
    source: 'Call Center',
    sChoice: `DEMANDE ${isDevis ? 'DEVIS' : 'INTERVENTION'}`,
    description: merge.getIn(paths.desc, '').trim(),
    choice: categorieData.find((e) => e._id === merge.getIn(paths.categorie))
      .name,
  })
    .then(() => {
      navigate(
        `/intervention/list/ajd/login-ajout=${user.login}=${user._id}`
      );
      notifSystem.success('Opération réussie', 'La demande a bien été créée');
    })
    .catch((e) => notifSystem.error(e.name, e.message));

export const updateInter = (id, update) =>
  api
    .one('interventions', id)
    .patch(update)
    .then((e) => e.body().data())
    .catch((e) => notifSystem.error('Erreur system', e.message || e));

const completeVerifCheck = ({
  attachments,
  user,
  inter,
  additionnalInfos,
  price,
  productsSum = 0,
  tva,
  applyTva,
  paymentChoice,
}) => {
  const isGrandCompte = inter.billing.grandCompte !== '';
  const dropboxContainVerifiedFiles = inter.dropbox.some(
    (dropbox) => dropbox.categorie[0] === AUTRE
  );
  const withImage = inter.billing.withImageEnable;
  const rate = 1 + (tva / 100);
  const realPrice = applyTva ? Math.round(price / rate, 10) : price;

  const dialogErrors = [
    {
      error:
        isGrandCompte &&
        withImage === true &&
        !dropboxContainVerifiedFiles &&
        attachments.length === 0,
      message: 'Veuillez ajouter une image pour Grand Compte',
    },
    {
      error: productsSum !== price,
      message: 'Veuillez modifier des produits ou le prix final',
    },
    {
      error: realPrice !== 0 && realPrice < MIN_PRICE,
      message: msgPrice,
    },
    {
      error: !price,
      message: 'Le prix ne peut pas être inférieur ou égal à 0€',
    },
    {
      error:
        !permit(user) &&
        ((permit(user, { key: 'verifInterSupport' }) &&
          [PL, EL, CL].includes(inter.categorie)) ||
          (inter.infoDesc.desc && DESC_REGEX.test(inter.infoDesc.desc))) &&
        (additionnalInfos || '').trim().length < 15,
      message: '15 caractères minimum (Renseignements complémentaires)',
    },
    {
      title: 'Erreur Symabat',
      error:
        inter.demande &&
        inter.demande.pdfName === 'symabat' &&
        (!inter.demande.statusSignature ||
          inter.demande.statusSignature !== S_SIG),
      message: 'Le fichier symabat n\'a pas été signé',
    },
    {
      title: 'En attente de paiement',
      error:
        inter.onSitePayment &&
        [CB, VIREM].includes(paymentChoice) &&
        !inter.isRegle,
      message: 'Le paiement n\'est pas encore arrivé',
    },
  ];
  const error = dialogErrors.find((e) => e.error);
  if (error) {
    notifSystem.error(error.title || 'Erreur', error.message);
    return false;
  }
  return true;
};

export function openVerifDialog({ user }, inter, cb) {
  const tva = inter.products?.tva;
  const productsSum = inter.products?.list.reduce(
    (acc, value) => acc + (value.pu * (value.qt ? value.qt : 0) || 0),
    0
  );
  const artisan = inter.currentArtisan || {};
  const noPaymentReceived =
    inter.noPaymentReceived ||
    !doubleCheckVerif(inter, (artisan.remuneration || {}).status);
  const setLoading = setDialogContentProp('loading');
  const isNotSameDate = !moment(inter.date.intervention).isSame(
    new Date(),
    'day'
  );
  let sendMail = !(
    inter?.recovery?.status === L_CLI &&
    inter?.login?.recouvrement &&
    ![R_PER, R_RES].includes(inter?.recovery?.recStatus)
  );

  return store.dispatch(
    setDialog({
      name: 'CompleteVerif',
      open: true,
      dialogProps: {
        title: 'Vérification',
        middle: true,
        customHeader: !sendMail && permit(user, { key: 'recOnly' }) && (
          <CustomSwitch
            label={`Envoyer le${cb ? 's' : ''} mail${cb ? 's' : ''}`}
            path={['']}
            setData={(p, v) => {
              sendMail = v;
            }}
          />
        ),
      },
      contentProps: {
        paymentChoice: inter.paymentMethod,
        price: inter.finalPrice,
        productsSum,
        tva,
        showOnlyPrice: !inter.onSitePayment,
        noPaymentReceived: noPaymentReceived,
        date: inter.date,
        attachments: [],
        dateIntervention: isNotSameDate ? inter.date.intervention : null,
        callbackNoPayment: () =>
          updateInter(inter._id, {
            login: {
              noPaymentReceived: user._id,
            },
            ...(inter.status === I_ENC ? { status: I_AVR } : {}),
            noPaymentReceived: true,
          }),
      },
      actions: [
        {
          children: 'Vérifier',
          hideButton: ({ paymentReceived }) =>
            !noPaymentReceived && !paymentReceived,
          disabled: ({ loading }) => loading,
          color: 'primary',
          onClick: (
            {
              price,
              paymentChoice,
              additionnalInfos = '',
              applyTva,
              attachments,
              lateReason,
              dateIntervention,
              repairType,
            },
            close,
            dispatch
          ) => {
            if (
              completeVerifCheck({
                attachments,
                user,
                inter,
                additionnalInfos,
                price,
                productsSum,
                applyTva,
                tva,
                paymentChoice,
              })
            ) {
              const rate = 1 + (tva / 100);
              const realPrice = applyTva ? Math.round(price / rate, 10) : price;
              dispatch(setLoading(true));
              const verifFun = () =>
                Promise.all([
                  verif({
                    id: inter.id,
                    artisanId: inter.artisan,
                    price: realPrice,
                    paymentMethod: paymentChoice,
                    comment: additionnalInfos,
                    noPaymentReceived: false,
                    attachments,
                    lateReason,
                    sendMail,
                    repairType,
                    withImage: inter.billing.withImageEnable,
                  }),
                  isNotSameDate
                    ? api.interventions.patch(inter._id, {
                      date: { intervention: dateIntervention },
                    })
                    : Promise.resolve(),
                ])
                  .then(() => close())
                  .catch((e) => notifSystem.error(e.name, e.message))
                  .finally(() => dispatch(setLoading(false)));
              if (cb && sendMail) {
                cb({ ...inter, finalPrice: realPrice }, verifFun);
              } else {
                verifFun();
              }
            }
          },
        },
      ],
    })
  );
}

export function openInterDemande(data, isDevis, { source, sChoice }) {
  return setDialog({
    name: 'OnlineDemande',
    open: true,
    dialogProps: { title: 'Demande d\'intervention', middle: true },
    contentProps: {
      clientPhone: data.getIn(paths.tel1, ''),
      clientCivility: data.getIn(paths.civility, ''),
      clientName: data.getIn(paths.name, ''),
      clientFirstName: data.getIn(paths.firstname, ''),
      clientEmail: data.getIn(paths.email1, ''),
      clientVoie: `${data.getIn(paths.number, '')} ${data.getIn(
        paths.road,
        ''
      )}`,
      clientCity: data.getIn(paths.city, ''),
      clientZip: data.getIn(paths.zipcode, ''),
      clientCategorie: data.getIn(paths.categorie, ''),
    },
    actions: [
      {
        children: 'Envoyer',
        onClick: (
          {
            clientCategorie,
            clientName,
            clientFirstName,
            clientPhone,
            clientVoie = '',
            clientCity = '',
            clientZip,
            clientEmail,
            description,
            clientCivility,
            setLoading,
          },
          close
        ) => {
          if (
            !clientCategorie ||
            !clientName ||
            !clientFirstName ||
            !clientPhone ||
            !clientZip ||
            !clientEmail ||
            !clientCivility
          ) {
            return notifSystem.error(
              'Erreur',
              'Veuillez remplir tous les champs requis'
            );
          }
          setLoading(true);
          envoy({
            description,
            civilite: clientCivility,
            voie: clientVoie,
            ville: clientCity,
            zipCode: clientZip,
            tel: clientPhone,
            name: clientName,
            firstname: clientFirstName,
            email: clientEmail,
            choice: categorieData.find((e) => e._id === clientCategorie).name,
            sChoice,
            source,
            interId: data.get('_id'),
            isDevis,
          })
            .then(() => {
              notifSystem.success('Opération réussie', 'Demande envoyée');
              close();
            })
            .catch((e) => notifSystem.error(e.name, e.message))
            .finally(() => setLoading(false));
        },
      },
    ],
  });
}
export function sendInterestSMS(user, inter, message, notif = true) {
  message = message || genInterestSmsInter(inter, user);
  const artisan = inter.artisan || {};
  const dis = (inter.artisan.dis || 0).toFixed(0);
  message = message.replace('DIS', dis ? `${dis}km` : '')
    .replace('ARTISAN_NAME', artisan.firstname);
  if (message) {
    return api.propositions
      .custom(`send/${inter.id}`)
      .post({
        message,
        dis,
        artisanId: artisan._id
      })
      .then(() =>
        notif
          ? notifSystem.success(
            'Opération réussie',
            'SMS demande d\'inter envoyée'
          )
          : null
      )
      .catch((e) =>
        notif
          ? notifSystem.error(e.name, e.response ? e.response.data : e.message)
          : null
      );
  }
}

export function sendInterestSMSArray(artisans, inter, message, { user, cb }) {
  try {
    artisans.asyncForEach(async (e) => {
      await sendInterestSMS(user, { ...inter, artisan: e }, message, false);
    });
    if (cb) {
      cb();
    }
    notifSystem.success('Opération réussie', 'SMS demande d\'inter envoyée');
  } catch (e) {
    notifSystem.error(e.name, e.message);
  }
}

export const openAttachmentsDialog = (boundSetDialog) =>
  new Promise((res) => {
    const cb = (state, close) => {
      close();
      res(state.attachments);
    };

    boundSetDialog({
      name: 'MailPreviewDialog',
      open: true,
      hideClose: true,
      dialogProps: { title: 'Pièces jointes à fournir à l\'envoi' },
      contentProps: {
        attachments: [],
        displayBody: false,
      },
      actions: [
        {
          children: 'Continuer',
          onClick: cb,
        },
        {
          children: 'Fermer',
          color: 'secondary',
          onClick: cb,
        },
      ],
    });
  });

export const sendOrderCedeo = async (props, inter, cb) => {
  try {
    const artisan = await api.artisans
      .get(inter.artisan)
      .then((e) => e.body().data());
    const orderCedeoRequest = async (agence) => {
      if (typeof props.save === 'function') {
        inter = typeof props.save === 'function' ? await props.save() : inter;
      }
      store.dispatch(
        setDialog({
          name: 'LoadingDialog',
          open: true,
          hideClose: true,
          dialogProps: { title: 'Commande en cours auprès de l’agence' },
        })
      );
      const errorDialog = () => store.dispatch(
        setDialog({
          name: 'ConfirmDialog',
          open: true,
          hideClose: true,
          dialogProps: {
            title: '🚫 Erreur lors de la commande',
          },
          contentProps: {
            defaultText: {
              subtitle: 'La commande automatique n\'a pas pu être effectuée.' +
                ' L\'artisan doit réaliser la commande au comptoir',
            },
          },
          actions: [
            {
              children: 'Compris',
              color: 'primary',
              onClick: (_, close) => {
                close();
                cb(inter);
              }
            },
          ],
        })
      );
      api
        .one('interventions', inter._id)
        .custom('orderCedeo')
        .post({ agence })
        .then((res) => res.body().data())
        .then(({ intervention }) => {
          closeDialog();
          if (intervention.order) {
            return store.dispatch(
              setDialog({
                name: 'ConfirmDialog',
                open: true,
                hideClose: true,
                dialogProps: {
                  title:
                    '✅ Confirmation de commande' +
                    ` nᵒ${intervention.order.commandeId}`,
                },
                contentProps: {
                  defaultText: {
                    subtitle: `La commande est disponible dès ${moment(
                      intervention.order.availableFrom
                    )
                      .calendar({
                        sameDay: '[Aujourd’hui]',
                        nextDay: '[Demain]',
                        sameElse: 'DD/MM/YYYY',
                      })
                      .toLowerCase()}`,
                  },
                },
                actions: [
                  {
                    children: 'Compris',
                    color: 'primary',
                    onClick: (_, close) => {
                      close();
                      cb(intervention);
                    },
                  },
                ],
              })
            );
          }
          return errorDialog();
        }).catch(errorDialog);
    };
    if (
      inter.combination?.autoOrderProduct &&
      !inter.date.sendOrderCedeo &&
      !inter.date.firstEnvoi &&
      inter.products.list?.some((e) => e.orderable && e.qt)
    ) {
      return store.dispatch(
        setDialog({
          name: 'DisplayAgencies',
          dialogProps: {
            title: 'Je choisis mon agence',
            maxWidth: 'xs',
          },
          contentProps: {
            artisan,
            agence: null,
          },
          open: true,
          hideClose: true,
          actions: [
            {
              children: 'Passer commande',
              color: 'primary',
              disabled: ({ agence }) => !agence,
              onClick: ({ agence }, close) => {
                close();
                orderCedeoRequest(agence);
              },
            },
            {
              children: 'Ne pas commander',
              color: 'secondary',
              onClick: (data, close) => {
                close();
                cb(inter);
              },
            },
          ],
        })
      );
    }
    return cb(inter);
  } catch (error) {
    notifSystem.error(error.name, error.message);
  }
};

export async function send(props, inter, cb) {
  store.dispatch(
    setDialog({
      name: 'LoadingDialog',
      open: true,
      hideClose: true,
      dialogProps: { title: 'Verification en cours' },
    })
  );
  const artisan = await api.artisans
    .get(inter.artisan)
    .then((e) => e.body().data());
  async function smsCallback(message, { withMail }) {
    props.setDialog(null, false, '', null);
    if (message || withMail) {
      inter = typeof props.save === 'function' ? await props.save() : inter;
      return (
        Object.keys(inter || {}).length &&
          api
            .one('interventions', inter._id)
            .custom('send')
            .post({
              message: message.replace(/{id}/g, inter.id),
              user: props.user,
              artisan: inter.artisan,
              withMail,
              attachments: props.attachments
            })
            .then((res) => {
              notifSystem.success('Opération réussie', 'Intervention envoyée');
              if (cb) {
                res = res.body().data();
                cb(res ? res.resInter : null);

              }
              return store.dispatch(closeDialog());
            })
            .catch((e) => {
              if (e.message === 'Method Not Allowed') {
                notifSystem.error(
                  'Erreur',
                  'Vous ne disposez pas des droits pour envoyer l\'intervention'
                );
              } else {
                notifSystem.error(e.name, e.response ? e.response.data : e);
              }
            })
      );
    }
  }
  const sendWithMail = ({ sendSMS, withMail }, user) => {
    const iphonText = {
      text: generateSmsInter(inter, user || props.user),
      offlineText: generateOfflineSmsInter(
        inter, user || props.user, true, artisan
      ),
      withMail,
    };
    if (sendSMS) {
      return props.setDialog(
        IphoneDialog,
        true,
        iphonText,
        (message, data = {}) => smsCallback(message, data)
      );
    }
    return smsCallback('', { withMail });
  };
  if (sendVerification(fromJS(inter), props.user)) {
    store.dispatch(closeDialog());
    return false;
  }
  return api.interventions
    .custom('checkBeforeSend')
    .post({
      artisanId: artisan._id,
      isNew: !inter._id || inter.status === I_APR,
      authorisedArtisan: inter.login.authorisedArtisan === artisan._id,
      inter: {
        date: {
          intervention: inter.date.intervention,
        },
        onSitePayment: inter.onSitePayment,
      },
    })
    .then((res) => {
      store.dispatch(closeDialog());
      if (res.body().data().error) {
        return notifSystem.error('Erreurs', res.body().data().error);
      }
      const user =
        inter.login &&
        props.users.find((u) =>
          props.user.service !== S_PART
            ? u._id === inter.login.ajout || u.login === inter.login.ajout
            : props.user._id === u._id
        );
      const remunInter =
        inter.remunerationArtisan || artisan.remuneration || {};
      if (inter.categorie === AS || remunInter.status === R_FOR) {
        return props.setDialog(
          DialogEnvoiInter,
          true,
          { data: { inter, artisan, user: props.user } },
          (sendSMS, withMail) =>
            sendWithMail({ sendSMS, withMail }, user)
        );
      }
      return props.setDialog(
        IphoneDialog,
        true,
        {
          text: generateSmsInter(inter, user || props.user, true, artisan),
          offlineText: generateOfflineSmsInter(
            inter, user || props.user, true, artisan
          ),
        },
        smsCallback
      );
    })
    .catch((e) => {
      store.dispatch(closeDialog());
      notifSystem.error(e.name, e.message);
    });
}

export function comment(props) {
  function commentCallback(message, opinion) {
    props.setDialog(null, false, '', null);
    if (message) {
      api.interventions
        .custom('comments')
        .post({
          _id: props.selected._id,
          commentValue: message,
          opinion,
        })
        .then(() =>
          notifSystem.success('Opération réussie', 'Commentaire envoyé')
        )
        .catch((e) => notifSystem.error(e.name, e.message));
    }
  }
  props.setDialog(
    TextDialog,
    true,
    {
      type: 'comments',
      data: (props.selected.comments || []).filter((e) => e.type === C_GLOBAL),
      isInter: true,
    },
    commentCallback
  );
}

export function sendToMarket(props, inter) {
  api.interventions
    .patch(inter._id, { aDemarcher: D_MAR })
    .then(() =>
      notifSystem.success('Réussie', 'Intervention envoyé dans le market')
    )
    .catch((e) => notifSystem.error(e.name, e.message));
}

export function demarchage(collection, id, time) {
  return api
    .one(collection, id)
    .custom('demarchage')
    .post({
      date: new Date().toISOString(),
      end: new Date(time).toString(),
      duration: Math.ceil(
        (new Date(time).getTime() - new Date().getTime()) / 60000
      ),
    })
    .then((res) => {
      notifSystem.success('Réussie', 'Timer ajouté');
      return res.body().data();
    })
    .catch((err) => notifSystem.error(err.name, err.message));
}

export function schedule(inter, cb) {
  function setTimer({ hour }) {
    const time = moment().add(moment.duration(hour));
    let collection = !isNaN(inter.id) ? 'interventions' : 'savInterventions';
    demarchage(collection, inter.id, time);
    if (cb) {
      cb({ hour, time });
    }
    store.dispatch(setDialog({}));
  }
  store.dispatch(
    setDialog({
      name: 'TimerDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        hour: '01:30',
      },
      actions: [
        {
          children: 'annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Valider',
          color: 'primary',
          onClick: (data) => setTimer(data),
        },
      ],
    })
  );
}

const callAction = (id, tel) =>
  api.appels
    .custom('add')
    .post({ interventionId: id, number: tel })
    .then(() => call(tel))
    .catch((e) => notifSystem.error(e.name, e.message));

export async function appel(props, tel, { client, billing = {} } = {}) {
  try {
    if (client) {
      const lastCalls = await api.records
        .custom('getLastCalls')
        .get({
          clientTels: [1, 2, 3]
            .reduce(
              (acc, curr) =>
                acc.concat([client[`tel${curr}`], billing[`tel${curr}`]]),
              []
            )
            .filter(Boolean),
        })
        .then((res) => res.body().data());

      if (lastCalls.client.length || lastCalls.artisan.length) {
        return store.dispatch(
          setDialog({
            dialogProps: {
              middleAll: true,
              title: 'Derniers appels',
            },
            contentProps: { lastCalls },
            name: 'LastCallsDialog',
            open: true,
            actions: [
              {
                children: 'Appeler',
                color: 'primary',
                onClick: (_, close) => {
                  callAction(props.selected.id, tel);
                  return close();
                },
              },
            ],
          })
        );
      }
    }
    return callAction(props.selected.id, tel);
  } catch (error) {
    notifSystem.error(error.name, error.message);
  }
}

export function favoris(props, elem) {
  api
    .one('interventions', elem.id)
    .custom('favoris')
    .post()
    .then(() =>
      notifSystem.success(
        'Opération réussie',
        'L\'intervention a été mise en favoris'
      )
    )
    .catch((e) => notifSystem.error(e.name, e.message));
}

export function reactivated(props, inter) {
  api
    .all('interventions/reactivated')
    .post({ id: inter.id })
    .then(() => {
      notifSystem.success('Opération réussie', 'Intervention modifiée');
    })
    .catch((e) => notifSystem.error(e.name, e.message));
}

export const sendAwaitingBill =
  (id, attachments, isSAV = false, withHistory = false) =>
    api
      .one('interventions', id)
      .custom('sendBill')
      .post({
        attachments,
        isSAV,
        withHistory
      })
      .then((r) => {
        if (r && r.body().data() === 'Has been paid') {
          return notifSystem.warning(
            'Opération réussie',
            'La facture n\'a pas été envoyé (Intervention déjà réglée)'
          );
        }
        return notifSystem.success(
          'Opération réussie',
          'Facture en attente de règlement envoyée'
        );
      })
      .catch((e) => {
        notifSystem.error(
          e.name,
          e.message === 'Not Acceptable'
            ? 'Coordonnées de facturation incomplètes'
            : e.message
        );
        throw e;
      });

export function openAwaitingBillDialog(
  inter,
  cb,
  isSAV = false,
  withHistory = false
) {
  return store.dispatch(
    setDialog({
      name: 'MailPreviewDialog',
      open: true,
      dialogProps: {
        title: 'Facture en attente avec pièces jointes',
      },
      contentProps: {
        displayBody: false,
        attachments: [],
      },
      actions: [
        {
          children: 'Envoyer',
          onClick: ({ attachments }, close) =>
            sendAwaitingBill(inter.id, attachments, isSAV, withHistory)
              .then(() => {
                attachments.forEach((attachment) =>
                  uploadFile({
                    type: DINTER,
                    fileName: attachment.name,
                    file: attachment.data,
                    id: inter.id,
                    underscoreId: inter._id,
                    path: 'interventions',
                  })
                );
                if (cb) {
                  cb();
                }
                close();
              }),
          color: 'primary',
        },
      ],
    })
  );
}

export function signalement(props, inter) {
  function commentCallback(message) {
    props.setDialog(null, false, '', null);
    if (message) {
      addSignalement(message.comment, message._id, inter.id, inter.artisan);
    }
  }
  props.setDialog(
    SignalSST,
    true,
    inter.artisan ? {} : { signalType: S_INE },
    commentCallback
  );
}

export function reglement(props) {
  const data = { setDialog: props.setDialog, selected: props.selected };
  if (props.selected.artisan) {
    return api.artisans
      .get(props.selected.artisan)
      .then((res) =>
        reglementDialog({
          ...data,
          artisan: res.body().data(),
        })
      )
      .catch((e) => {
        if (Number(e.message) === 1) {
          notifSystem.error('Erreur', 'L\'artisan n\'a pas pu être récupéré');
        } else {
          notifSystem.error(e.name, e.response ? e.response.data : e);
        }
      });
  }
  return reglementDialog(data);
}

export function payment(props) {
  Promise.all([
    api.artisans.get(props.selected.artisan),
    api[isNaN(props.selected.id) ? 'savInterventions' : 'interventions'].get(
      props.selected._id
    ),
  ])
    .then((res) => res.map((s) => s.body().data()))
    .then(([artisan, intervention]) => {
      props.setDialog(
        PaymentDialog,
        true,
        { data: intervention, artisan },
        () => props.setDialog(null, false, {}, null)
      );
    })
    .catch((e) => {
      if (Number(e.message) === 1) {
        notifSystem.error('Erreur', 'L\'artisan n\'a pas pu être récupéré');
      } else {
        notifSystem.error(e.name, e.response ? e.response.data : e);
      }
    });
}

export function paiementAnnexes({ selected }) {
  return store.dispatch(
    setDialog({
      name: 'PaymentAnnexesDialog',
      open: true,
      dialogProps: { title: '', middle: true, middleAll: true, maxWidth: 'lg' },
      contentProps: {
        intervention: selected,
        dropbox: selected.dropbox,
        comments: selected.comments || [],
        id: selected.id,
        _id: selected._id,
      },
    })
  );
}

export function refuser({ setDialog }, inter) {
  return setDialog(
    DialogList,
    true,
    {
      title: 'Merci de préciser le motif du refus',
      data: [
        'Trop de décalages',
        'Refus de 1er décalage',
        'Mauvaises conditions de démarchage',
        'Démarchage non abouti',
      ],
    },
    (declineReason) => {
      setDialog(null, false, '', null);
      if (!declineReason) {
        return;
      }
      api
        .one('interventions', inter.id)
        .custom('refuse')
        .post({
          reason: declineReason,
        })
        .then(() =>
          notifSystem.success(
            'Opération réussie',
            'L\'intervention a été refusée'
          )
        )
        .catch((e) =>
          notifSystem.error(e.name, e.response ? e.response.data : e)
        );
    }
  );
}
export function showSMS(props, id, type, selectedNumber = null) {
  store.dispatch(openChatPanel(id, type, selectedNumber));
}

export const postComment = (
  _id,
  commentValue,
  additionnal = {},
  collection = 'interventions'
) =>
  api[collection].custom('comments').post({
    _id,
    commentValue,
    ...additionnal,
  });

export const comptaComments = (props, inter, comptaStatus) => {
  function comptaComCb(commentValue) {
    if (!commentValue) {
      return props.setDialog(null, false, '', null);
    }
    const user = props.users.find((e) => e._id === props.userId);
    if (user) {
      return postComment(inter._id, commentValue, {
        comptaStatus,
        type: C_COMPTA,
      })
        .then(() => {
          notifSystem.success('Message', 'Le commentaire a bien été ajouté');
          props.setDialog(null, false, '', null);
        })
        .catch((e) =>
          notifSystem.error(e.name, e.response ? e.response.data : e)
        );
    }
  }
  props.setDialog(
    TextDialog,
    true,
    {
      type: 'comments',
      data: (inter.comments || []).filter(
        (e) => e.comptaStatus === comptaStatus
      ),
      title: 'Commentaire au service comptabilité',
      isInter: true,
    },
    comptaComCb
  );
};

export function modifyTransactions(props, inter) {
  return store.dispatch(
    setDialog({
      name: 'DialogTransaction',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Mettre à jour les prélèvements',
        middle: true,
        middleAll: true,
        maxWidth: 'lg',
      },
      contentProps: {
        inter,
      },
      actions: [
        {
          children: 'Fermer',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
      ],
    })
  );
}

export const middlewareDialogCompta = async (
  props,
  inter,
  comptaStatus,
  cb
) => {
  const signals =
    comptaStatus === M_REG &&
    (await getSimpleSignalements({ 'intervention.id': inter.id }).then((e) =>
      e.body().map((e) => e.data())
    ));
  if (
    inter &&
    ((Array.isArray(inter.comments) &&
      inter.comments.filter((e) => e.comptaStatus === comptaStatus).length) ||
      (signals &&
        signals.length &&
        signals.some(
          (e) => e?.signal?.name === 'Modifier échéance (CB/Chq/caution)'
        )))
  ) {
    props.setDialog(
      DialogInfoCompta,
      true,
      {
        comments: inter.comments.filter((e) => e.comptaStatus === comptaStatus),
        signalements:
          signals &&
          signals.filter(
            (e) => e?.signal?.name === 'Modifier échéance (CB/Chq/caution)'
          ),
      },
      () => {
        props.setDialog(null, false, '', null);
        cb(props, inter);
      }
    );
  } else {
    cb(props, inter);
  }
};

export function openCheckDialogPart(inter, cb) {
  return setDialog({
    name: 'DialogCheckList',
    open: true,
    dialogProps: {
      title: 'Attention: Vous avez oublié de cocher certaines informations',
    },
    contentProps: { inter },
    actions: [
      {
        children: 'Oui',
        onClick: (d, close) => {
          close();
          if (cb) {
            cb(inter);
          }
        },
      },
    ],
  });
}

export const updatePriseDeCote = (inter, state) => {
  const date = new Date(state.date);
  return Promise.all([
    api.interventions.custom(`priseDeCote/${inter.id}`).patch({
      ...state,
      maxHour: inter?.infoDesc?.maxHour,
    }),
    api.interventions.patch(inter._id, {
      date: {
        intervention: date,
      },
      ...(inter?.infoDesc?.maxHour
        ? {
          infoDesc: {
            maxHour: moment(inter.infoDesc.maxHour)
              .set({
                year: state.date.getFullYear(),
                month: state.date.getMonth(),
                date: state.date.getDate(),
              })
              .toDate(),
          },
        }
        : {}),
    }),
  ]);
};

export function callPriseDeCoteDialog({ setDialog }, inter, cb) {
  return setDialog(
    PriseDeCoteDialog,
    true,
    {
      title: `Prise de côte pour l'intervention n°${inter.id}`,
    },
    {
      send: (state) =>
        updatePriseDeCote(inter, state).then(() => {
          if (typeof cb === 'function') {
            cb(state.measurePerformedPayment);
          }
          setDialog(null, false, null, null);
          return new Promise((res) => res());
        }),
      close: () => setDialog(null, false, null, null),
    }
  );
}

export const createReminder = (interId, comment, date) =>
  api.interventions.custom(`addReminder/${interId}`).post({
    comment: comment.trim(),
    date,
  });

export const callReminderDialog = ({ setDialog }, inter) => {
  const name = `${inter.client.civility || ''} ${inter.client.name || ''}`;
  setDialog(
    ReminderDialog,
    true,
    {
      title: `Résumé de la conversation avec ${name}`,
      googleCalendarTitle:
        `Relance concernant l'intervention n°${inter.id} (${name})`,
    },
    {
      close: () => setDialog(null, false, null, null),
      send: (comment, date) => createReminder(inter.id, comment, date),
    }
  );
};

export const callReminderHistoryDialog = ({ setDialog }, inter) => {
  setDialog(
    ReminderHistoryDialog,
    true,
    {
      reminders: inter.reminders || [],
      reminderDate: inter.date.reminder,
      name: `${inter.client.civility || ''} ${inter.client.name || ''}`,
    },
    {
      close: () => setDialog(null, false, null, null),
    }
  );
};

export const addToSupportActionList = (props, cb) => {
  props.setDialog(
    DialogSupport,
    true,
    { inter: props.selected },
    async (text) => {
      if (text) {
        await Promise.all([
          api.interventions.patch(props.selected._id, {
            login: {
              support: props.selected.login.ajout,
              supportAdd: props.user._id
            },
            date: { support: new Date() },
          }),
          text !== true ? postComment(props.selected._id, text) : null,
        ])
          .then(() => {
            notifSystem.success('Opération réussie', 'Intervention modifiée');
          })
          .catch((e) => notifSystem.error(e.name, e.message));
      }
      if (typeof cb === 'function') {
        cb();
      }
      props.setDialog(null, false, null, null);
    }
  );
};

export const getCalls = ({
  numbers, user, page = 0, direction, collections,
  display = 2
}) =>
  (!cacheDisabled(SHOW_ENR) || permit(user, { key: 'retrieveRecords' })) &&
  api.records
    .getAll({
      numbers: numbers,
      display,
      page: page,
      ...(direction ? { direction } : {}),
      collections,
    })
    .then((res) => res.body().map((e) => e.data()) || []);

export const interMapDispatchToProps = {
  setConnectedDialog: setDialog,
  openCheckDialogPart,
};

export const abPlusCb = (props, inter, send) => {
  inter.currentArtisan =
    (props.currentArtisan || {}).obj || inter.currentArtisan;
  api
    .custom('communication/abPlusCb')
    .post({
      telClient1: inter.client.tel1,
      telClient2: inter.client.tel2 || '',
      telClient3: inter.client.tel3 || '',
      numeroOS: inter.id.toString(),
      userId: props.userId,
      artisan: inter.artisan,
      send: send,
    })
    .then(() =>
      notifSystem.success(
        'Opération réussie',
        'L\'artisan a été mis en relation avec le client'
      )
    )
    .catch((e) =>
      notifSystem.error(e.name, e.response ? e.response.data : e.message)
    );
};

export const demarcherAction = (
  updateData,
  aDemarcherValue,
  userId,
  setDialog
) => {
  updateData('aDemarcher', aDemarcherValue ? D_NOT : D_PRE);
  updateData(['login', 'aDemarcher'], userId);
  updateData(['date', 'aDemarcher'], new Date());
  setDialog(null, false, null, null);
};

export const setADemarcher = (
  artisans,
  { idInter, userId, aDemarcher },
  updateData,
  setDialog
) => {
  const { idADemarcher } = getSpecificKeys({
    id: idInter,
  });
  api.appels
    .getAll({
      query: JSON.stringify({
        [idADemarcher]: idInter,
        artisanId: { $exists: true },
      }),
      field: JSON.stringify({ artisanId: 1 }),
    })
    .then((res) => res.body().map((e) => e.data().artisanId))
    .then((data) => {
      if (aDemarcher === D_NOT) {
        const check = aDemarcheAlerts(artisans, data);
        if (check.length) {
          return setDialog(
            DialogList,
            true,
            {
              title:
                'Attention : Certains partenaires se trouvant ' +
                'à moins de 30km de l\'intervention n\'ont pas été contacté.',
              data: check,
              confirmBox: 'Demarcher',
              Icon: () => <Call color="primary" />,
              elementCallback: (f) => f,
              color: 'primary',
            },
            (c) =>
              c
                ? demarcherAction(updateData, aDemarcher, userId, setDialog)
                : setDialog(null, false, null, null)
          );
        }
      }
      return demarcherAction(updateData, aDemarcher, userId, setDialog);
    });
};

export const openSendLinkDialog = (inter) =>
  store.dispatch(
    setDialog({
      name: 'SendLinkDialog',
      open: true,
      contentProps: {
        amount: 0,
        noSecure: false,
      },
      actions: [
        {
          children: 'Envoyer',
          onClick: (r, close) => {
            api.interventions
              .custom('sendCustomPayment')
              .post({
                id: inter.get('id'),
                amount: r.amount,
                noSecure: r.noSecure,
                collection: 'interventions',
              })
              .then(() =>
                notifSystem.success(
                  'Opération réussie',
                  'Demande de paiement envoyée avec succès'
                )
              )
              .catch((e) =>
                notifSystem.error(
                  e.name,
                  e.response ? e.response.data : e.message
                )
              );
            close();
          },
        },
      ],
    })
  );

export const addArtisanFromSMS = async (interId, artisanId, user, navigate) => {
  try {
    const intervention = await api.interventions
      .get(interId)
      .then((e) => e.body().data());
    if (!intervention) {
      return notifSystem.error(
        'Il y a eu une erreur',
        'Intervention non trouvé'
      );
    }
    const [lng, lat] =
      intervention?.client?.address?.location?.coordinates || [];
    const { obj: artisan, dis } = await api.artisans
      .getAll({
        lat,
        lng,
        radius: 300,
        limit: 1,
        query: JSON.stringify({ _id: artisanId }),
      })
      .then((res) => res.body().map((e) => e.data()))
      .then((e) => e[0]);
    if (!artisan) {
      return notifSystem.error('Il y a eu une erreur', 'Artisan non trouvé');
    }
    const remStatus = artisan.remuneration.status;
    const notSameArtisan = intervention.login.authorisedArtisan !== artisan._id;
    let update = {
      hidePrice: hidePriceArtisan(intervention, remStatus),
      noMiseEnRelation: !notSameArtisan,
      artisan: artisan._id,
      currentArtisan: {
        dis,
      },
      aDemarcher: D_NOT,
    };
    const error = getByKey({
      artisan,
      onSitePayment: intervention.onSitePayment,
      checkSend: true
    });
    if (error && error.message) {
      notifSystem.error('Erreur', error.message);
    }
    if (remStatus === R_POR) {
      update.remunerationArtisan = {
        labor: artisan.remuneration.pourcentage.labor || 0,
        deplacement: artisan.remuneration.pourcentage.deplacement || 0,
        status: R_POR,
      };
    } else if (remStatus === R_FOR) {
      update.remunerationArtisan = {
        status: R_FOR,
      };
    }
    return updateInter(interId, update).then((e) => {
      notifSystem.success(
        'Opération réussie',
        'L\'artisan a bien été séléctionnée.'
      );
      return navigate
        ? navigate(`/intervention/${e.id}`)
        : window.open(`/intervention/${e.id}`, '_blank');
    });
  } catch (e) {
    notifSystem.error('Erreur system', e.message || e);
  }
};

export const addInterToMajSupport = ({ selected, user }) => {
  const points =
    store.getState().counter[`interventions.countMajSupport.${user.login}`] ||
    0;
  if (points <= user.majSupport) {
    api.interventions.patch(selected._id, {
      login: { majSupport: selected.login.ajout },
      date: { majSupport: new Date() },
    });
    return notifSystem.success(
      'Opération réussie',
      'La demande a été transmise au support'
    );
  }
  return notifSystem.error('Erreur', 'Vous n\'avez plus de points disponible');
};


export const priseEnChargeSupport = ({ selected, user }) =>
  api.interventions.patch(selected._id, {
    login: { priseEnChargeSupport: user._id },
    date: { priseEnChargeSupport: new Date() },
  }).then(() => notifSystem.success(
    'Opération réussie',
    'L\'intervention a été prise en charge'
  )).catch(() =>
    notifSystem.error('Erreur',
      'L\'intervention n\'a pas pu être prise en charge'));

export const addFournitureFile = (attachment, interId) =>
  api.interventions
    .custom('addFileFourniture')
    .post({
      attachment,
      interId,
    })
    .then((res) => {
      notifSystem.success(
        'Fichier ajouté',
        'Veuillez enregistrer l\'inter avant de quitter'
      );
      return res.body().data();
    })
    .catch(() =>
      notifSystem.error('Erreur', 'Le fichier n\'a pas pu être ajouté')
    );

export const sendSMSFourniture = (supplie, artisanId, interId) => {
  api.interventions
    .custom('sendSMSFourniture')
    .post({
      supplieLink: supplie.get('attachmentArtisan'),
      interId,
      artisanId,
    })
    .then((res) => {
      notifSystem.success('Message', 'SMS envoyé');
      return res.body().data();
    })
    .catch(() => notifSystem.error('Erreur', 'Le SMS n\'a pas pu être envoyé'));
};

export const changeSupplieProgress = (elem) =>
  store.dispatch(
    setDialog({
      name: 'DialogCallBackButton',
      open: true,
      dialogProps: {
        title: 'changer l\'avancement',
        dividers: true,
      },
      contentProps: {
        buttons: progressData,
        callback: (newStatus) => {
          api.interventions
            .patch(elem._id, {
              supplieProgress: newStatus,
            })
            .then(() =>
              notifSystem.success(
                'Opération réussie',
                'Le status a bien changé'
              )
            )
            .catch(() => notifSystem.error('Erreur', 'Il y a eu une erreur'));
        },
      },
    })
  );

export const getInterFromDevis = (devisId) =>
  api.interventions
    .getAll({
      query: JSON.stringify({
        createdFrom: {
          _id: devisId,
          collection: 'devis',
        },
      }),
      field: JSON.stringify({ id: 1 }),
    })
    .then((r) => r.body().map((e) => e.data())[0]);
