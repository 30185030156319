import {
  NOT_CONFIRMED,
  USER,
  ADMIN
} from '@lba-dev/package.local-globals/permissions';
import { S_RH } from '@lba-dev/package.local-globals/services';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import componentLoader from '../../componentLoader';

const ListIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListIntervention" */
    '../../views/List/ListIntervention'
  )
);

const ListRelancesIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRelancesIntervention" */
    '../../views/List/ListRelancesIntervention'
  )
);

const ListRecouvrementIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRecouvrementIntervention" */
    '../../views/List/ListRecouvrementIntervention'
  )
);

const ListQualiteInterventions = componentLoader(() =>
  import(
    /* webpackChunkName: "ListQualiteInterventions" */
    '../../views/List/ListQualiteInterventions'
  )
);

const ListBtobIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListBtobIntervention" */
    '../../views/List/ListBtobIntervention'
  )
);


const RecouvrementBTOB = componentLoader(() =>
  import(
    /* webpackChunkName: "RecouvrementBTOB" */
    '../../views/Recouvrement/RecouvrementBTOB'
  )
);


const ListSupportIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListSupportIntervention" */
    '../../views/List/ListeSupport/Interventions'
  )
);

const AjoutIntervention = componentLoader(() => import(
  /* webpackChunkName: "AjoutIntervention" */
  '../../views/AjoutIntervention'
));


const ListRecouvrementArtisan = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRecouvrementArtisans" */
    '../../views/List/ListRecouvrementArtisans'
  )
);


const ListAides = componentLoader(() =>
  import(
    /* webpackChunkName: "ListAides" */
    '../../views/List/ListAides'
  )
);


const ListDomofinance = componentLoader(() =>
  import(
    /* webpackChunkName: "ListDomofinance" */
    '../../views/List/ListDomofinance'
  )
);


const ListAnn = componentLoader(() =>
  import(
    /* webpackChunkName: "ListAnn" */
    '../../views/List/ListAnn'
  )
);


const ListAvrMaj = componentLoader(() =>
  import(
    /* webpackChunkName: "ListAvrMaj" */
    '../../views/List/ListAvrMaj'
  )
);


export default [
  {
    path: '/intervention/recouvrementBTOB/:filter',
    component: RecouvrementBTOB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/relance/:filter/:query',
    component: ListRelancesIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/list/ann',
    component: ListAnn,
    restrictions: ({ service }) => ({
      [S_RH]: NOT_CONFIRMED,
    }[service] || ADMIN)
  },
  {
    path: '/intervention/list/avrMaj/:query',
    component: ListAvrMaj,
    restrictions: () => ADMIN,
  },
  {
    path: '/intervention/quality/:filter/:query',
    component: ListQualiteInterventions,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/quality/:filter',
    component: ListQualiteInterventions,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/recouvrementArtisan',
    component: ListRecouvrementArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/:filter/:query',
    component: ListRecouvrementIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/:filter',
    component: ListRecouvrementIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/btob/:filter/:query',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/btob/:filter',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/support/:filter/:query',
    component: ListSupportIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/support/:filter',
    component: ListSupportIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/demarchage/:filter',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/aide/:filter',
    component: ListAides,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/domo/:filter',
    component: ListDomofinance,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/list/:filter/:query',
    component: ListIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/list/:filter',
    component: ListIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/list',
    component: ListIntervention,
    restrictions: (user) =>
      permit(user, { key: 'interDevisListAccess' }) && user.auth,
    redirectLink: () => '/'
  },
  {
    path: '/intervention/interData/',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/:id/:query',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/:id',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
];
