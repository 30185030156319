import React, { Component } from 'react';

import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import api from '../../api';
import ws from '../../ws';
import { shallowEqual } from 'react-redux';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: 30
  }
};

const wait = timeout => new Promise(res => setTimeout(res, timeout));

export default function withAPISubscription(fetch, actions, {
  shouldReload = (props, prevProps) => !shallowEqual(props, prevProps),
  shouldReloadSocket = false,
  default: defaultData = [],
  customHandling = false,
  name = 'data',
  deverse = false,
  debugTimeout = process.env.NODE_ENV === 'development' ? 1000 : 0,
} = {}) {
  if (shouldReloadSocket === true) {
    shouldReloadSocket = shouldReload;
  }
  return WrappedComponent => withStyles(class extends Component {
    state = {
      loading: false,
      error: false,
      errorMessage: 'Une erreur est survenue, veuillez rechargez la page',
      data: defaultData
    }

    constructor(props) {
      super(props);
      this.formatSocketActions(actions, props);
    }

    componentDidMount() {
      this.reload();
      this.toggleSocketActions();
    }

    componentDidUpdate(prevProps) {
      if (shouldReload(this.props, prevProps)) {
        this.reload();
      }
      if (shouldReloadSocket && shouldReloadSocket(this.props, prevProps)) {
        this.formatSocketActions(actions, this.props);
        this.toggleSocketActions(true);
        this.toggleSocketActions();
      }
    }

    componentWillUnmount() {
      this.toggleSocketActions(true);
    }

    formatSocketActions = (actions, props) => {
      this.socketActions = actions && actions.length
        ? actions.map(({ action, func = f => f() }) => {
          if (typeof action === 'function') {
            action = action(props);
          }
          return {
            action,
            func: (...args) => func(
              (fetcher = fetch) => this.fetchFromAPI(
                fetcher,
                props,
                ...args
              ),
              props,
              ...args
            )
          };
        })
        : [];
    }

    toggleSocketActions = remove =>
      this.socketActions.forEach(({ action, func }) => {
        ws[remove ? 'removeEventListener' : 'on'](action, func);
      })

    fetchFromAPI = (fetcher, props, ...args) => {
      if (this.state.loading) {
        return;
      }
      this.promisifiedSetState({
        loading: true,
        error: false
      })
        .then(() => debugTimeout ? wait(debugTimeout) : null)
        .then(() => fetcher(api, props, ...args))
        .then(data => this.setState({ loading: false, data }))
        .catch((e) => {
          const update = {
            loading: false,
            error: true,
            errorMessage: 'Une erreur est survenue, veuillez rechargez la page',
          };
          if (e && e.response && e.response.statusCode === 404) {
            update.errorMessage = 'Aucun element trouvé';
          }
          this.setState(update);
        });
    }

    reload = () => this.fetchFromAPI(fetch, this.props)

    render() {
      const { loading, error, errorMessage, data } = this.state;
      const { classes, ...props } = this.props;
      const componentProps = {
        ...props,
        ...(deverse ? data : { [name]: data }),
        reload: this.reload
      };

      if (customHandling) {
        componentProps.error = error;
        componentProps.loading = loading;
      }
      return customHandling
        ? <WrappedComponent {...componentProps} />
        : (error && <Typography
          align="center"
          variant="h4"
          children={errorMessage}
        />) || (loading && <div
          className={classes.container}
          children={<CircularProgress size={80} />}
        />) || <WrappedComponent {...componentProps} />;
    }
  }, styles);
}
