import categories from '@lba-dev/package.local-globals/categories';
import status from '@lba-dev/package.local-globals/dStatus';
import {
  data as caldeoStatus
} from '@lba-dev/package.local-globals/caldeoStatus';

import { Types } from './Types';
import store from '../../store';

export const List = () => ({
  default: {
    dataFormat: {
      table: {
        OS: Types.Text,
        Telepro: Types.Text,
        'Cat.': Types.Rectangle,
        Ajout: Types.Text,
        Client: Types.Text,
        Ville: Types.Text,
        Description: Types.StepMetierName,
        Prix: Types.Money,
        Status: Types.Chip,
        Email: Types.Text,
      },
      tableData: [
        { class: 'th' },
        { class: '', list: store.getState().users, key: 'login' },
        { class: '', list: categories.data },
        { class: '' },
        { class: '' },
        { class: '' },
        { class: '' },
        { class: '' },
        { class: '', list: status.data },
        { class: '' },
      ],
      keys: [
        'id',
        'login.ajout',
        'categorie',
        'date.ajout',
        [
          'client.civility',
          'client.name',
          'client.firstname',
          'client.society',
        ],
        ['client.address.zipcode', 'client.address.city'],
        ['metiersSteps.name', 'metiersDevis.name'],
        'finalPrice',
        'status',
        'postmarkmail',
      ],
      checkType: [
        'number',
        'users',
        'categorie',
        'date',
        ['client.civility', 'client.name', 'client.firstname'],
        ['client.address.city', 'client.address.zipcode'],
        ['metiersSteps.name', 'metiersDevis.name'],
        'prix',
        'status',
        'postmarkmail',
      ],
    },
  },
  caldDevis: {
    dataFormat: {
      table: {
        OS: Types.Text,
        Telepro: Types.Text,
        'Cat.': Types.Rectangle,
        Ajout: Types.Text,
        Client: Types.Text,
        Ville: Types.Text,
        Prix: Types.Money,
        Status: Types.Chip,
        Signature: Types.Chip,
        Email: Types.Text,
      },
      tableData: [
        { class: 'th' },
        { class: '', list: store.getState().users, key: 'login' },
        { class: '', list: categories.data },
        { class: '' },
        { class: '' },
        { class: '' },
        { class: '' },
        { class: '', list: status.data },
        { class: '', list: caldeoStatus },
        { class: '' },
      ],
      keys: [
        'id',
        'login.ajout',
        'categorie',
        'date.ajout',
        [
          'client.civility',
          'client.name',
          'client.firstname',
          'client.society',
        ],
        ['client.address.zipcode', 'client.address.city'],
        'finalPrice',
        'status',
        'signature.status',
        'postmarkmail',
      ],
      checkType: [
        'number',
        'users',
        'number',
        'date',
        ['client.civility', 'client.name', 'client.firstname'],
        ['client.address.city', 'client.address.zipcode'],
        'prix',
        'status',
        'status',
        'postmarkmail',
      ],
    },
  }
});
