/* eslint-disable max-lines */
import api from '../api';
import notifSystem from '../notifSystem';
import { setDialog } from './dialog';
import store from '../store';
import { SAV_REGL, SAV_NREGL } from '@lba-dev/package.local-globals/natureSAV';
import { priseDeContact } from './priseDeContact';
import {
  defaultTextAttestation,
  getBonMail,
} from '@lba-dev/package.local-globals/listMails';

export const DeleteSAV = (props, inter) =>
  api.savInterventions
    .custom('delete')
    .delete({
      id: inter.id,
      query: {
        filter: props.filter || {},
        allSAVStatus: props.selected.statusSAV,
      },
    })
    .then(() => {
      notifSystem.success('Opération réussie', 'Informations sauvegardées');
    })
    .catch((e) => notifSystem.error(e.name, e.response ? e.response.data : e));

export const demandeAttestation = (inter, update) => {
  const defaultText = defaultTextAttestation(inter || {});
  return store.dispatch(
    setDialog({
      name: 'AttestationSavDialog',
      open: true,
      dialogProps: {
        maxWidth: 'md',
        fullWidth: true,
      },
      contentProps: {
        conclusions: '',
        observation: defaultText.observ,
        message: defaultText.body,
        title: defaultText.title,
        desc: defaultText.desc,
        objMail: defaultText.objMail,
        files: [],
        loading: false,
        destinataires:
          inter && inter.client
            ? [inter.client.email1, inter.client.email2].join(',')
            : '',
      },
      actions: [
        {
          children: 'Envoyer',
          color: 'primary',
          onClick: (data, close) => {
            if (
              !data.message ||
              !data.observation ||
              !data.title ||
              !data.conclusions ||
              !data.desc ||
              !data.destinataires
            ) {
              return notifSystem.error(
                'Erreur',
                'Merci de remplir tous les champs'
              );
            }
            close();
            return api
              .one('interventions', inter._id)
              .custom('sendAttestation')
              .post({ ...update, ...data })
              .then(() => {
                notifSystem.success(
                  'Opération réussie',
                  'Informations sauvegardées'
                );
              })
              .catch((e) =>
                notifSystem.error(e.name, e.response ? e.response.data : e)
              );
          },
        },
      ],
    })
  );
};
export const updateStatusSAV = (inter, update = {}) =>
  api.interventions
    .patch(inter._id, update)
    .then(() =>
      notifSystem.success('Opération réussie', 'Informations sauvegardées')
    )
    .catch((e) => notifSystem.error(e.name, e.response ? e.response.data : e));

const createSAV = ({
  inter,
  nature,
  close,
  withPriseContact = false,
  setLoading,
  user,
}) => {
  setLoading(true);
  api.savInterventions
    .post({
      id: inter.id,
      nature,
    })
    .then((e) => {
      close();
      if (withPriseContact) {
        priseDeContact(e.body().data(), user);
      }
    })
    .catch(() => notifSystem.error('Erreur', 'Le SAV n\'a pas pu être ajouté'))
    .finally(() => setLoading(false));
};

export const priseEnChargeSAV = (inter, { user }) => {
  store.dispatch(
    setDialog({
      name: 'PriseEnChargeDialogSAV',
      open: true,
      dialogProps: {
        title: 'Prise en charge',
        middle: true,
      },
      contentProps: {
        nature: inter.isRegle ? SAV_REGL : SAV_NREGL,
      },
      actions: [
        {
          children: 'Valider et prise de contact',
          color: 'success',
          onClick: ({ nature, setLoading }, close) =>
            createSAV({
              inter,
              nature,
              close,
              setLoading,
              withPriseContact: true,
              user,
            }),
        },
        {
          children: 'Valider',
          color: 'primary',
          onClick: ({ nature, setLoading }, close) =>
            createSAV({ inter, nature, close, setLoading, user }),
        },
      ],
    })
  );
};

export const downloadAndSignBonSAV = ({ price, artisan, inter, add, save }) =>
  setDialog({
    name: 'DialogBon',
    open: true,
    dialogProps: {
      maxWidth: 'md',
    },
    contentProps: {
      ...getBonMail(inter, artisan),
      price,
      artisanMail: artisan.email1,
      cb: (f) => f,
      sendMailArtisan: true
    },
    actions: [
      {
        children: 'Envoyer',
        color: 'primary',
        onClick: (
          {
            message,
            subject,
            subjectArtisan,
            messageArtisan,
            attachments,
            price,
            destinataire,
            fournisseur,
            artisanMail,
            cb,
            PDFComments = '',
            sendMailArtisan,
          },
          close
        ) => {
          if (
            message &&
            subject &&
            attachments &&
            subjectArtisan &&
            messageArtisan &&
            price &&
            destinataire &&
            fournisseur &&
            artisanMail
          ) {
            cb(true);
            api
              .one('interventions', inter._id)
              .custom('sendBon')
              .post({
                message,
                subject,
                ...(sendMailArtisan
                  ? { artisanMail, subjectArtisan, messageArtisan }
                  : {}),
                attachments,
                destinataire,
                comments: PDFComments,
                fournisseur,
              })
              .then((resp) => {
                const { link, attachmentArtisan } = resp.body().data() || {};
                add({
                  price,
                  pro: fournisseur,
                  attachment: link,
                  attachmentArtisan
                }, () => save());
                cb(false);
                close();
                notifSystem.success('Opération réussie', 'Email et bon envoyé');
              })
              .catch((e) =>
                notifSystem.error(e.name, e.response ? e.response.data : e)
              );
          } else {
            notifSystem.error('Erreur', 'Un des champs est manquant');
          }
        },
      },
    ],
  });

export const assignNotif = (sav, userSelected) =>
  api.savInterventions
    .custom(`sendNotifAssign/${sav.id}`)
    .post({ userSelected })
    .catch(() =>
      notifSystem.error('Erreur', 'La notification n\'a pas pû être envoyé')
    );
