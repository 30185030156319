/* eslint-disable max-lines */
import React from 'react';
import RecoveryIcon from '../components/Recouvrement/RecoveryIcon';
import SAVIcon from '../components/SAVComponents/SAVIcon';
import constants from '../constants/collections';

import { R_NO } from '@lba-dev/package.local-globals/recStatus';
import { O_STAR, O_TOP } from '@lba-dev/package.local-globals/aOptions';
import { CB, ESP } from '@lba-dev/package.local-globals/paymentMethods';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import blueGrey from '@mui/material/colors/blueGrey';
import Tooltip from '@mui/material/Tooltip';
import { data as formData } from '@lba-dev/package.local-globals/formProsp';
import Badge from '@mui/material/Badge';
import {
  AccountBalance,
  Call,
  CreditCard,
  CallEnd,
  Edit,
  Domain,
  RemoveRedEye,
  Textsms,
  MailOutline,
  AddAlert,
  AssignmentTurnedIn,
  Assignment,
  ArrowUpward,
  VerifiedUser,
  TouchApp,
  BusinessCenter,
  Sms,
  InstallMobile,
  EventAvailable,
  Visibility,
  RingVolume,
  Euro,
  Comment,
  Star,
  NotificationImportant,
  Phone,
  MonetizationOn,
  Brightness1,
  CheckCircle,
  Info,
  WorkOutline,
  WarningRounded,
  PanTool,
  ExpandCircleDown,
  Error,
  PhoneDisabled,
  Voicemail,
  PermDeviceInformation
} from '@mui/icons-material';
import Checkbox from '@mui/icons-material/CheckBox';

import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import { R_FOR, R_POR } from '@lba-dev/package.local-globals/remStatus';
import {
  L_PAYMULTI,
  L_PAYDIF,
} from '@lba-dev/package.local-globals/paymentCBActionTypes';
import {
  data as subStatusDevisData
} from '@lba-dev/package.local-globals/dSubStatusRecouvrement';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { D_AR } from '@lba-dev/package.local-globals/demandeStatus';
import { MIN_DURATION } from '@lba-dev/package.local-globals/minDuration';
import {
  getPointsDevisCommission,
  reasonsCommissionDevis,
} from '@lba-dev/package.local-globals/commissions';
import { I_ENC, I_AVR } from '@lba-dev/package.local-globals/iStatus';
import {
  REPONDEUR,
  isProblem,
  AUTRE,
  TOLISTEN,
  OK,
  RDVCONFIRMED,
  callTypes,
  ECHECMER
} from '@lba-dev/package.local-globals/callTypes';
import {
  T_SMS,
  T_APP,
  T_MAIL
} from '@lba-dev/package.local-globals/typeAnswers';
import { displayMetierInList } from '../actions/communMetiersActions';
import {
  P_NONE,
} from '@lba-dev/package.local-globals/isValidStatus';
import {
  data as propositionStatus
} from '@lba-dev/package.local-globals/propositionStatus';
import { openPropositionDialog } from '../actions/proposition';


const getSupportUser = (users, loginSupport) =>
  users
    .filter((u) => u.act && u.supportIds.includes(loginSupport))
    .map((u) => u.login)
    .join(' / ') || 'Support';

export const GenTooltipIcon = ({
  Icon,
  text,
  color,
  colorProps,
  fontSize = 20,
  colorNumber = 500,
  extraStyle = {},
  ...props
}) => (
  <Tooltip
    disableInteractive
    title={<span>{text || ''}</span>}
    children={
      <Icon
        {...props}
        color={colorProps}
        style={{ color: color[colorNumber], fontSize, ...extraStyle }}
      />
    }
  />
);
export const IconsDisplay = {
  recovery: (props) =>
    props.listen !== constants.recouvrements &&
    props.elem.recovery &&
    props.elem.recovery.status !== R_NO && (
      <RecoveryIcon recovery={props.elem.recovery || {}} key={'RecoveryIcon'} />
    ),
  priorityFollow: (props) =>
    props.elem.priorityFollow && (
      <GenTooltipIcon
        Icon={NotificationImportant}
        color={orange}
        text={'Suivi prioritaire'}
        key={'Suivi prioritaire'}
      />
    ),
  star: (props) =>
    (props.elem.favoris || (props.elem.option || []).includes(O_STAR)) && (
      <GenTooltipIcon Icon={Star} text={'Star'} color={amber} key={'Star'} />
    ),
  currentSAVStatus: (props) =>
    (props.elem?.currentSAVStatus || props.elem?.login?.sav) && (
      <SAVIcon
        statusSAV={props.elem.currentSAVStatus}
        userSAV={props.elem?.login?.sav}
        key={'SAVIcon'}
      />
    ),
  candidats: (props) =>
    constants.candidats === props.listen && [
      !!(props.elem.comments || []).length && (
        <GenTooltipIcon
          Icon={Comment}
          text={'Commentaires'}
          color={blue}
          key={'candidatComments'}
        />
      ),
      props.elem.currentArtisan?._id && (
        <GenTooltipIcon
          Icon={Visibility}
          text={'Voir l\'artisan'}
          color={red}
          onClick={() =>
            window.open(`/artisan/${props.elem.currentArtisan._id}`)
          }
          key={'candidatVisibility'}
        />
      ),
    ],
  topOfList: (props) =>
    (props.elem.favoris || (props.elem.option || []).includes(O_TOP)) && (
      <GenTooltipIcon
        Icon={ArrowUpward}
        color={blueGrey}
        text={'Tête-de-liste'}
        key={'Tête-de-liste'}
      />
    ),
  formData: (props) =>
    !!props.elem.aDemarcher &&
    props.elem.formRp && (
      <div>
        {' '}
        {Object.keys(props.elem.formRp).map((key) => {
          const myForm = formData.find((e) => e.field === key);
          return (
            !!props.elem.formRp[key] && (
              <GenTooltipIcon
                key={key}
                Icon={Badge}
                text={myForm.text}
                colorProps={'secondary'}
                color={red}
                badgeContent={props.elem.formRp[key]}
                fontSize={20}
                onClick={() =>
                  window.open(
                    `/prosp/list/reponse?interSearch=${props.elem._id}`
                  )
                }
                children={
                  <PanTool
                    style={{ fontSize: 20, color: myForm.color[500] }}
                    key={key}
                  />
                }
              />
            )
          );
        })}
      </div>
    ),
  sawForm: (props) =>
    !!props.elem.aDemarcher &&
    props.elem.seenByProsp && (
      <GenTooltipIcon
        key={'Vu par prospect'}
        Icon={Badge}
        text={'Vu par prospect'}
        color={red}
        colorProps={'secondary'}
        fontSize={20}
        badgeContent={props.elem.seenByProsp.length}
        onClick={() =>
          window.open(`/prosp/list/vue?searchKey=${props.elem._id}`)
        }
        children={
          <RemoveRedEye
            style={{ fontSize: 20 }}
            color="primary"
            key={'RemoveRedEyeIcon'}
          />
        }
      />
    ),
  supportIcon: (props) =>
    props.elem.login &&
    props.elem.login.support && (
      <GenTooltipIcon
        Icon={TouchApp}
        text={getSupportUser(props.users, props.elem.login.support)}
        color={blue}
        key={'TouchApp'}
      />
    ),
  caldeoIcon: (props) =>
    !props?.elem?.domofinance?.valid &&
    props?.elem?.isCaldeo && (
      <GenTooltipIcon
        Icon={Domain}
        text={'caldeo'}
        color={red}
        key={'caldeo'}
      />
    ),
  domofinanceIcon: (props) =>
    props?.elem?.domofinance?.valid && (
      <GenTooltipIcon
        Icon={AccountBalance}
        text={props?.elem?.isCaldeo ? 'Domofinance & caldeo' : 'Domofinance'}
        color={props?.elem?.isCaldeo ? red : blue}
        key={'domofinance'}
      />
    ),
  supportMajIcon: (props) =>
    props.elem.login &&
    [I_ENC, I_AVR].includes(props.elem.status) &&
    props.elem.login.majSupport && (
      <GenTooltipIcon
        Icon={BusinessCenter}
        text={'Support (mise à jour)'}
        color={blueGrey}
        key={'BusinessCenter'}
      />
    ),
  positiveRespSmsIcon: (props) =>
    props.elem.artisansStatus &&
    props.elem.artisansStatus.length &&
    props.elem.artisansStatus
      .find(e => e.isPositif && [T_SMS, T_MAIL].includes(e.typeAnswer)) && (
      <GenTooltipIcon
        Icon={Sms}
        text={'Réponse positive'}
        color={green}
        key={'SmsIcon'}
      />
    ),
  positiveRespAppIcon: (props) =>
    props.elem.artisansStatus &&
    props.elem.artisansStatus.length &&
    props.elem.artisansStatus
      .find(e => e.isPositif && e.typeAnswer === T_APP) && (
      <GenTooltipIcon
        Icon={InstallMobile}
        text={'Réponse positive (App)'}
        color={green}
        key={'InstallMobile'}
      />
    ),
};

export const cbPayment = (props) =>
  ({
    [CB]:
      !props.elem.transaction ||
      !props.elem.date ||
      ![L_PAYMULTI, L_PAYDIF].includes(props.elem.transaction.type) ? (
          <GenTooltipIcon
            Icon={CreditCard}
            colorNumber={500}
            text={
              props.elem.date.envoiLydia
                ? 'Paiement cb envoyé'
                : 'Paiment cb non envoyé'
            }
            color={props.elem.date.envoiLydia ? green : red}
            key={'Carte bancaire'}
          />
        ) : (
          {
            [L_PAYDIF]: (
              <GenTooltipIcon
                Icon={EventAvailable}
                colorNumber={900}
                text={'Paiement différé'}
                color={grey}
                key={'Paiement différé'}
              />
            ),
            [L_PAYMULTI]: (
              <GenTooltipIcon
                Icon={() => (
                  <Avatar className={props.classes.smallAvatar}>3x</Avatar>
                )}
                colorNumber={900}
                text={'Paiement en plusieurs fois'}
                color={grey}
                key={'Paiement en plusieurs fois'}
              />
            ),
          }[props.elem.transaction.type]
        ),
    [ESP]: (
      <GenTooltipIcon
        Icon={MonetizationOn}
        colorNumber={900}
        text={'Paiement en espèces'}
        color={grey}
        key={'Paiement en espèces'}
      />
    ),
  }[props.elem.paymentMethod]);

export const devisIcon = (props) => {
  let devisIconColor =
    props.elem.login && props.elem.login.artisan ? green : blue;
  let devisIconColorNumber =
    props.elem.login && props.elem.login.artisan ? 500 : 700;
  let devisIconTooltip =
    props.elem.login && props.elem.login.artisan
      ? 'Devis artisan'
      : 'Devis transféré';

  return (
    props.elem.login &&
    (props.elem.login.artisan || props.elem.date.devis) && (
      <GenTooltipIcon
        Icon={VerifiedUser}
        key={'devisIcon'}
        text={devisIconTooltip}
        color={devisIconColor}
        colorNumber={devisIconColorNumber}
      />
    )
  );
};

export const subStatusDevisIcon = (props) => {
  const subStatusDevis =
    props.elem &&
    props.elem.recovery &&
    props.elem.recovery.subStatusDevis &&
    subStatusDevisData
      .find((e) =>
        e.values.find(
          (value) => value._id === props.elem.recovery.subStatusDevis
        )
      )
      .values.find((elem) => elem._id === props.elem.recovery.subStatusDevis);

  let iconColor = subStatusDevis && subStatusDevis.color;
  let iconTooltip = subStatusDevis && subStatusDevis.name;

  return (
    props.elem.date &&
    props.elem.recovery &&
    props.elem.recovery.subStatusDevis &&
    props.elem.date.recouvrement && (
      <GenTooltipIcon
        Icon={Assignment}
        key={'devisIcon'}
        text={iconTooltip}
        color={iconColor}
        colorNumber={500}
      />
    )
  );
};

export const validInfosIcon = (props, infoArtisans, infoClients) =>
  props.elem &&
  props.elem.clientListInfo &&
  props.elem.artisanListInfo &&
  infoArtisans.length === props.elem.artisanListInfo.length &&
  infoClients.length === props.elem.clientListInfo.length && (
    <GenTooltipIcon
      Icon={AssignmentTurnedIn}
      text={'Information validée'}
      key={'Information validée'}
      color={green}
      colorNumber={500}
    />
  );

export const editIcon = (props) =>
  props.elem.measurePerformedPayment && (
    <GenTooltipIcon
      Icon={Edit}
      color={'primary'}
      text={'Prise de côte validée'}
      key={'Prise de côte validée'}
    />
  );

export const mailOutlineIcon = (props, i) =>
  'onSitePayment' in props.elem &&
  !props.elem.onSitePayment && (
    <div style={{ textAlign: 'right' }} key={props.tableData[i].key}>
      <MailOutline style={{ fontSize: 20 }} />
    </div>
  );

export const mailOutlinePaddingIcon = (props, i) =>
  'onSitePayment' in props.elem &&
  !props.elem.onSitePayment && (
    <div style={{ textAlign: 'right' }} key={props.tableData[i].key}>
      <MailOutline style={{ width: '0.8em', paddingLeft: 5 }} />
    </div>
  );

const getLabel = (listenable, isProb) => {
  const audio = listenable.reverse().find(e => e.type >= OK &&
      (!isProb || isProblem.includes(e.type)));
  const type = audio?.type >= OK ? audio?.type : TOLISTEN;
  const label = callTypes.find(e => e.id === type)?.label;
  if (type !== AUTRE) {
    return label;
  }
  return audio.panneComment;
};

const getCallIcon = (icon, callList, color = red, isPlob) => <GenTooltipIcon
  Icon={icon}
  text={getLabel(callList, isPlob)}
  fontSize={20}
  color={color}
/>

export const iconByType = (communcations = []) => {
  const listenable = communcations.filter((e) =>
    e.duration > MIN_DURATION
  );
  const lastListenabled = listenable[listenable.length - 1] || {};
  const icons = [
    {
      cond: lastListenabled.type === REPONDEUR,
      icon: getCallIcon(Voicemail, listenable, red)
    },
    {
      cond: lastListenabled.type === ECHECMER,
      icon: getCallIcon(PhoneDisabled, listenable, red)
    },
    {
      cond: lastListenabled.type === OK,
      icon: getCallIcon(CheckCircle, listenable, green)
    },
    {
      cond: lastListenabled.type === RDVCONFIRMED,
      icon: getCallIcon(ExpandCircleDown, listenable, green)
    },
    {
      cond: isProblem.includes(lastListenabled.type),
      icon: getCallIcon(WarningRounded, listenable,
        !lastListenabled.played ? red : orange, true)
    },
    {
      cond: lastListenabled.type === TOLISTEN ||
        (listenable.length && lastListenabled.type === undefined),
      icon: getCallIcon(Error, listenable, red, true)
    },
    {
      cond: !listenable.length,
      icon: ''
    },
  ];
  const newIcon = icons.find(e => e.cond);
  return newIcon ? newIcon.icon : '';
};

const BadgeIcon = ({ elem: { login, communcations = [], appel } }) => {
  const noAudio =
    !login.miseEnRelation &&
    !appel &&
    (!communcations ||
      (communcations && !communcations
        .some(e => e.duration > MIN_DURATION && e.type !== REPONDEUR)));
  if (!noAudio && communcations.every((s) => 'played' in s)) {
    return iconByType(communcations);
  }
  return '';
};

export const CallIcon = (props) => (
  <Grid container align="center">
    <Grid item xs={12}>
      <Badge badgeContent={<BadgeIcon elem={props.elem} />}>
        <GenTooltipIcon
          key={'GenTooltipIcon'}
          Icon={props.elem.login.miseEnRelation ? RingVolume : Phone}
          text={
            props.elem.login.miseEnRelation ? 'Mise en relation' : 'Appel'
          }
          className={props.classes.icon}
          color={
            props.elem.appel === true &&
            !props.elem.communcations
              .every(e => e.duration < MIN_DURATION || e.type === REPONDEUR)
              ? green
              : red
          }
        />
      </Badge>
    </Grid>
  </Grid>
);

export const SupplieProgressIcon = ({ elem = {}, classes, list = [] }) => {
  const item = list[elem.supplieProgress - 1];
  return item ? (
    <GenTooltipIcon
      key="supplieProgress"
      Icon={Brightness1}
      className={classes.icon}
      fontSize={25}
      color={item.color}
      text={item.name}
    />
  ) : null;
};

export const remunerationIcons = {
  [R_POR]: (remuneration) => (
    <GenTooltipIcon
      Icon={(props) => (
        <Typography {...props}>{remuneration?.pourcentage?.labor} %</Typography>
      )}
      text={'Porcentage'}
      color={blue}
      fontSize={15}
      key={'Porcentage'}
    />
  ),
  [R_FOR]: () => (
    <GenTooltipIcon
      Icon={Euro}
      text={'Forfait'}
      color={red}
      fontSize={15}
      key={'Forfait'}
    />
  ),
};

export const forfaitIcon = (props) =>
  props.elem.remunerationArtisan &&
  props.elem.remunerationArtisan.status === R_FOR &&
  remunerationIcons[R_FOR]();

export const demandesIcons = (props) => {
  const icons = [];
  if (props.elem.comments.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Comment}
        color={'primary'}
        text={'Commentaires'}
        key={'Commentaires'}
      />
    );
  }
  if (props.elem.messages.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Textsms}
        color={'primary'}
        text={'Conversation SMS'}
        key={'Conversation SMS'}
      />
    );
  }
  if (props.elem.called) {
    icons.push(
      <GenTooltipIcon
        Icon={Call}
        color={'primary'}
        text={'Client appelé'}
        key={'Client appelé'}
      />
    );
  }
  if (props.elem.groups && props.elem.groups.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Badge}
        badgeContent={props.elem.groups.length}
        color={'primary'}
        text={'Client appelé par des commerciaux'}
        key={'Client appelé par des commerciaux'}
        children={<CallEnd key={'CallEndIcon'} />}
      />
    );
  }
  if (
    props.elem.status === D_AR &&
    props.elem.login &&
    props.elem.login.reminder
  ) {
    icons.push(
      <GenTooltipIcon
        Icon={AddAlert}
        color={'primary'}
        text={'Rappel programmé'}
        key={'Rappel programmé'}
      />
    );
  }
  return icons;
};
const findPoint = (points, index) => points.find((e) => e._id === index);

export const SupportPoints = ({ elem }) => {
  const points = getPointsDevisCommission(elem);
  return [...Array(5)].map((_, i) => (
    <Tooltip disableInteractive
      key={`supportPoints-${i}`} title={reasonsCommissionDevis[i + 1]}>
      <Checkbox
        style={{ color: findPoint(points, i + 1) ? green[500] : red[500] }}
      />
    </Tooltip>
  ));
};

export const metiersStepsCounter = (props, collection) => {
  const value = props.elem.metiersSteps || props.elem.metiersDevis || {};
  const answers = Object.keys(value.answers || {}).length;
  const totalQuestions = value.totalQuestions || 0;
  const color = answers === totalQuestions ? green : red;
  return (
    <GenTooltipIcon
      Icon={Info}
      text={`${answers}/${totalQuestions}`}
      color={totalQuestions ? color : grey}
      key={'metiersStepsCounter'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        displayMetierInList(value, collection);
      }}
    />
  );
};

export const grandCompteIcon = (props) => <GenTooltipIcon
  Icon={WorkOutline}
  color={'primary'}
  text={props.elem.billing.companyName}
  key={'grandCompteIcon'}
/>;

export const UpdateProposition = ({ elem }) =>
  elem.proposition &&
  elem.proposition.isValid === P_NONE &&
  [I_AVR, I_ENC].includes(elem.status) &&
  <GenTooltipIcon
    Icon={PermDeviceInformation}
    color={propositionStatus.find(e =>
      e._id === elem.proposition.status)?.color}
    text={'Mise a jour de l\'artisan'}
    key={'UpdateProposition'}
    onClick={(e) => {
      openPropositionDialog(elem.id);
      e.stopPropagation();
    }}
  />;
